import { Component, OnInit } from '@angular/core';
import { LeadActivityService } from '../../services/lead-activity.service';
import * as moment from 'moment';
import { SaveAsExcelfileService } from '../../common-services/saveAsExcelFileService';
import { HttpErrorResponse } from '@angular/common/http';
import { LocalStorageService } from 'src/app/utility/localStorageService';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { LeadService } from '../../services/lead.services';
import { TblLead } from '../../models/leads';

@Component({
  selector: 'app-download-dialog',
  templateUrl: './download-dialog.component.html',
  styleUrls: ['./download-dialog.component.css']
})
export class DownloadDialogComponent implements OnInit {
  downloadFilter:DownloadFilter
  minDate:string
  maxDate:string
  source:string
  userType:string
  constructor(private leadActivityService: LeadActivityService,
    private excelService: SaveAsExcelfileService,
    private localStorage:LocalStorageService,
    public modalRef: BsModalRef,
    private notification:ToastrService,
    private _leadservices: LeadService,
  ) { }

  ngOnInit(): void {
    this.downloadFilter=new DownloadFilter()
    this.minDate = moment(new Date(this.localStorage.get("showMeLeadsFromDate"))).format("YYYY-MM-DD").toString()
    this.maxDate =moment( this.addDays(new Date(), Number(this.localStorage.get("showMeUpcomingEventsOf")))).format("YYYY-MM-DD").toString()
    this.downloadFilter.fromDate=this.minDate
    this.downloadFilter.toDate=this.maxDate 
    this.downloadFilter.includeDetails=true
    this.userType=this.localStorage.get("userType")
   
  }
  addDays(date: Date, days: number): Date {
    date.setDate(date.getDate() + days);
    return date;
  }
  downloadFile()
  {
    if(this.downloadFilter.fromDate==null || this.downloadFilter.fromDate==undefined)
    {
      this.notification.show("Please select from Date")
      return
    }
    let lastId = 0
    if(this.source=='Source')
    {
      this.leadActivityService.getRawLeadsBySource(this.localStorage.get("accessSource"),this.downloadFilter.fromDate,this.downloadFilter.toDate)
      .subscribe(
        resp => {
          let data=resp
          if(this.downloadFilter.includeDetails==false)
            data=data.filter((temp)=>temp.currentStatus=='')
          for (let i = 0; i < data.length; i++) {
            data[i].actionDate = moment(data[i].actionDate).format("DD-MM-YYYY")
            if(this.downloadFilter.includeDetails)
            data[i].nextActionDate = moment(data[i].nextActionDate).format("DD-MM-YYYY")

            if (Number(data[i].assignedNoIf) == lastId)
              data[i].assignedNoIf = ""
            if(data[i].assignedNoIf != "")
              lastId = Number(data[i].assignedNoIf)
          }
          let ordData = ["sourceCode", "name", "email", "mobileNo", "state", "city", "leadChannel", "leadSource", "leadCampaign", "course", "batch", "leadAccepted", "assignedNoIf", "actionDate", "currentStatus", "notes", "action", "nextActionDate"];
          this.excelService.exportAsExcelFile(data, ordData, 'source-wise-leads');
        },
        (error: HttpErrorResponse) => {

        });
    }
    else{
      let leads: TblLead[]=[];
      this._leadservices.getLeadsByStatus('0','All',moment(this.downloadFilter.fromDate).format('YYYY-MM-DD'),moment(this.downloadFilter.toDate).format('YYYY-MM-DD'),99999).subscribe(resp => {
       
        if (resp.length > 0) {
          leads = resp
          for (let i = 0; i < leads.length; i++) {
            leads[i].leadDate = moment(leads[i].leadDate).format("DD-MM-YYYY")
          }
          let ordData=[]
          this.excelService.exportAsExcelFile(leads, ordData, 'all-leads');
        }
      });
    }
    
    
  }

}
export class DownloadFilter {
  fromDate: string
  toDate: string
  includeDetails: boolean
}