import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
    providedIn: 'root'
  })
export class SaveAsExcelfileService {
  
  arrayBuffer:any;
constructor() { }
  public exportAsExcelFile(json: any[],columnOrder:any[], excelFileName: string): void {

    const reorderColumns = (data, order) => {
      return data.map(row => {
        let orderedRow = {};
        order.forEach(col => {
          orderedRow[col] = row[col];
        });
        return orderedRow;
      });
    };
    
    // Reorder the JSON data
    let orderedData:any
    if(columnOrder.length>0)
      orderedData = reorderColumns(json, columnOrder);
    else
      orderedData=json;
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(orderedData);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  Upload(file:File) {
    let fileReader = new FileReader();
      fileReader.onload = (e) => {
          this.arrayBuffer = fileReader.result;
          var data = new Uint8Array(this.arrayBuffer);
          var arr = new Array();
          for(var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
          var bstr = arr.join("");
          var workbook = XLSX.read(bstr, {type:"binary"});
          var first_sheet_name = workbook.SheetNames[0];
          var worksheet = workbook.Sheets[first_sheet_name];
          console.log(XLSX.utils.sheet_to_json(worksheet,{raw:true}));
      }
      fileReader.readAsArrayBuffer(file);
}
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
    FileSaver.saveAs(data, fileName + '_export_' + new  Date().getTime() + EXCEL_EXTENSION);
  }
}