<div class="container-fluid" id="container-wrapper" (click)="getPosition($event)" *ngIf="userType=='Admin' || userType=='Counsellor'">
    <div class="d-sm-flex align-items-center justify-content-between mb-4" style="border-bottom: 2px solid grey;">
        <h1 class="h3 mb-0 text-gray-800">Dashboard</h1>
    </div>
    <div class="row div-viewport">
        <div class="col-xl-4 col-md-4 mb-4">
            <a (click)="openFilterForm()" style="cursor: pointer;width: 50%;">

                <input id="inputdefault" placeholder="Click To Apply Filter" type="text" style="position: relative;
                    color: #aaa;
                    font-size: 16px; width: 100%;padding-left: 20px;" readonly>
                <i class="fa fa-filter" style=" position: absolute;
                    top: 10px;
                    left: 15px;"></i>
                <i class="fa fa-caret-down fa-lg pull-right" style=" position: absolute;
                    top: 10px;
                    right: 20px;"></i>
            </a>
        </div>

        <div class="col-xl-4 col-md-4 mb-4">

            <a (click)="openCounsellorForm()" style="cursor: pointer;width: 50%;">

                <input id="inputdefault" placeholder="Select Counsellor" type="text" style="position: relative;
                    color: #aaa;
                    font-size: 16px; width: 100%;padding-left: 20px;" readonly>
                <i class="fa fa-filter" style=" position: absolute;
                    top: 10px;
                    left: 15px;"></i>
                <i class="fa fa-caret-down fa-lg pull-right" style=" position: absolute;
                    top: 10px;
                    right: 20px;"></i>
            </a>

        </div>
        <div class="col-xl-4 col-md-4 mb-4">

            <a (click)="openRangeForm()" style="cursor: pointer;">
                <input id="inputdefault" placeholder="Date Range" type="text" style="position: relative; color: #aaa; font-size: 16px; width: 100%;" [value]="selectedDate" readonly>
                <i class="fa fa-calendar fa-lg pull-right" style="position: absolute; top: 10px; right: 20px;"></i>
            </a>

        </div>
    </div>







    <div class="row" style="overflow-y: scroll;height: 500px;">
        <div class="row">
            <div class="col-xl-2 col-sm-6 col-md-6 col-xs-6 mb-6 col-6" *ngIf="userType=='Admin'">
                <div class="card h-100">
                    <div class="card-body" style="background-color: rgb(224, 224, 81);color: white!important;">
                        <div class="row align-items-center">
                            <div class="col mr-2">
                                <div class="text-xs font-weight-bold text-uppercase mb-1">Total Application<i class="fa fa-arrow-down" aria-hidden="true" style="float: inline-end;"></i></div>
                                <div class="h5 mb-0 font-weight-bold text-gray-800">{{totalApplications}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-sm-6 col-md-6 col-xs-6 mb-6 col-6" *ngIf="userType=='Admin'">
                <div class="card h-100">
                    <div class="card-body" style="background-color: rgb(231, 62, 62);color: white!important;">
                        <div class="row align-items-center">
                            <div class="col mr-2">
                                <div class="text-xs font-weight-bold text-uppercase mb-1">Total Rejected Application<i class="fa fa-arrow-down" aria-hidden="true" style="float: inline-end;"></i></div>
                                <div class="h5 mb-0 font-weight-bold text-gray-800" style="color: white;">{{totalRejectedApllication}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Earnings (Annual) Card Example -->
            <div class="col-xl-2 col-sm-6 col-md-6 col-xs-6 mb-6 col-6" *ngIf="userType=='Admin'">
                <div class="card h-100">
                    <div class="card-body btn-primary">
                        <div class="row no-gutters align-items-center">
                            <div class="col mr-2">
                                <div class="text-xs font-weight-bold text-uppercase mb-1">Total Online Application<i class="fa fa-arrow-up" aria-hidden="true" style="float: inline-end;"></i></div>
                                <div class="h5 mb-0 font-weight-bold text-gray-800">{{totalOnlineApplication}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-sm-6 col-md-6 col-xs-6 mb-6 col-6" *ngIf="userType=='Admin'">
                <div class="card h-100">
                    <div class="card-body btn-success">
                        <div class="row no-gutters align-items-center">
                            <div class="col mr-2">
                                <div class="text-xs font-weight-bold text-uppercase mb-1">Total Offline Application<i class="fa fa-arrow-down" aria-hidden="true" style="float: inline-end;"></i></div>
                                <div class="h5 mb-0 font-weight-bold text-gray-800">{{totalOfflineApplication}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- New User Card Example -->
            <div class="col-xl-2 col-sm-6 col-md-6 col-xs-6 mb-6 col-6">
                <div class="card h-100">
                    <div class="card-body btn-info">
                        <div class="row no-gutters align-items-center">
                            <div class="col mr-2">
                                <div class="text-xs font-weight-bold text-uppercase mb-1">New Leads For Today<i class="fa fa-arrow-down" aria-hidden="true" style="float: inline-end;"></i></div>
                                <div class="h5 mb-0 font-weight-bold text-gray-800">{{newLeadsForToday}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div class="col-xl-2 col-sm-6 col-md-6 col-xs-6 mb-6 col-6" *ngIf="userType=='Admin'">
                <div class="card h-100">
                    <div class="card-body" style="background-color: rgb(86, 224, 127);color: white!important;">
                        <div class="row no-gutters align-items-center">
                            <div class="col mr-2">
                                <div class="text-xs font-weight-bold text-uppercase mb-1">Last Day Active Users <i class="fa fa-arrow-down" aria-hidden="true" style="float: inline-end;"></i></div>
                                <div class="h5 mb-0 font-weight-bold text-gray-800">{{lastDayActiveUsers}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-2 col-sm-6 col-md-6 col-xs-6 mb-6 col-6" *ngIf="this.userType=='Counsellor'">
                <div class="card h-100">
                    <div class="card-body" style="background-color: rgb(23, 143, 143);color: white!important;">
                        <div class="row no-gutters align-items-center">
                            <div class="col mr-2">
                                <div class="text-xs font-weight-bold text-uppercase mb-1">Total Verified Leads<i class="fa fa-arrow-down" aria-hidden="true" style="float: inline-end;"></i></div>
                                <div class="h5 mb-0 font-weight-bold text-gray-800">{{totalVerifiedLeads}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-2 col-sm-6 col-md-6 col-xs-6 mb-6 col-6">
                <div class="card h-100">
                    <div class="card-body btn-danger">
                        <div class="row no-gutters align-items-center">
                            <div class="col mr-2">
                                <div class="text-xs font-weight-bold text-uppercase mb-1">Total Missed Follow-Ups<i class="fa fa-arrow-down" aria-hidden="true" style="float: inline-end;"></i></div>
                                <div class="h5 mb-0 font-weight-bold text-gray-800">{{totalMissedFollowUps}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-sm-6 col-md-6 col-xs-6 mb-6 col-6">
                <div class="card h-100">
                    <div class="card-body btn-success">
                        <div class="row no-gutters align-items-center">
                            <div class="col mr-2">
                                <div class="text-xs font-weight-bold text-uppercase mb-1">Total WhatsApp<i class="fa fa-arrow-down" aria-hidden="true" style="float: inline-end;"></i></div>
                                <div class="h5 mb-0 font-weight-bold text-gray-800">{{whatsApp}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-sm-6 col-md-6 col-xs-6 mb-6 col-6">
                <div class="card h-100">
                    <div class="card-body btn-success">
                        <div class="row no-gutters align-items-center">
                            <div class="col mr-2">
                                <div class="text-xs font-weight-bold text-uppercase mb-1">E-Mail Sent<i class="fa fa-arrow-down" aria-hidden="true" style="float: inline-end;"></i></div>
                                <div class="h5 mb-0 font-weight-bold text-gray-800">{{eMailSent}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-sm-6 col-md-6 col-xs-6 mb-6 col-6">
                <div class="card h-100">
                    <div class="card-body btn-success">
                        <div class="row no-gutters align-items-center">
                            <div class="col mr-2">
                                <div class="text-xs font-weight-bold text-uppercase mb-1">SMS Sent<i class="fa fa-arrow-down" aria-hidden="true" style="float: inline-end;"></i></div>
                                <div class="h5 mb-0 font-weight-bold text-gray-800">{{smsSent}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-sm-6 col-md-6 col-xs-6 mb-6 col-6">
                <div class="card h-100">
                    <div class="card-body btn-success">
                        <div class="row no-gutters align-items-center">
                            <div class="col mr-2">
                                <div class="text-xs font-weight-bold text-uppercase mb-1">Calls<i class="fa fa-arrow-down" aria-hidden="true" style="float: inline-end;"></i></div>
                                <div class="h5 mb-0 font-weight-bold text-gray-800">{{call}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-sm-6 col-md-6 col-xs-6 mb-6 col-6">
                <div class="card h-100">
                    <div class="card-body btn-info">
                        <div class="row no-gutters align-items-center">
                            <div class="col mr-2">
                                <div class="text-xs font-weight-bold text-uppercase mb-1">Active Counsellors<i class="fa fa-arrow-down" aria-hidden="true" style="float: inline-end;"></i></div>
                                <div class="h5 mb-0 font-weight-bold text-gray-800">{{counsellors}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>



        <div class="row">


            <!--====================================================================== Area Chart -->
            <div class="col-xl-6 col-md-6 mb-6">
                <div class="card mb-4">
                    <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                        <h6 class="m-0 font-weight-bold text-primary">Status Wise Total Application</h6>

                    </div>
                    <div class="card-body">
                        <div class="chart-area" style="padding-left: 100px;">
                            <canvasjs-chart [options]="totalLeadFunnelByStatus" [styles]="{width: '90%', height: '260px'}" (chartInstance)="ChartInstanceForTotalLeadFunnelByStatus($event)"></canvasjs-chart>
                        </div>
                    </div>
                </div>
            </div>
            <!--====================================================================== Area Chart -->
            <!-- Pie Chart -->
            <div class="col-xl-6 col-md-6 mb-6">
                <div class="card mb-4">
                    <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                        <h6 class="m-0 font-weight-bold text-primary">Status Wise Total Accepted Leads</h6>
                    </div>
                    <div class="card-body">
                        <div class="chart-area" style="padding-left: 100px;">
                            <canvasjs-chart [options]="statusWiseTotalLeads" [styles]="{width: '90%', height: '260px'}" (chartInstance)="ChartInstanceForStatusWiseTotalLeads($event)"></canvasjs-chart>
                        </div>
                    </div>
                </div>
            </div>
            <!--====================================================================== Area Chart -->

            <div class="col-xl-6 col-md-6 mb-6">
                <div class="card mb-4">
                    <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                        <h6 class="m-0 font-weight-bold text-primary">Top Performing Lead Channel</h6>
                    </div>
                    <div class="card-body">
                        <div class="chart-area">
                            <canvasjs-chart [options]="topPerformingLeadChannel" [styles]="{width: '100%', height: '260px'}" (chartInstance)="ChartInstanceForTopPerformingLeadChannel($event)"></canvasjs-chart>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-md-6 mb-6">
                <div class="card mb-4">
                    <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                        <h6 class="m-0 font-weight-bold text-primary">Lead Count by Source</h6>

                    </div>
                    <div class="card-body">
                        <div class="chart-area">
                            <canvasjs-chart [options]="leadCountBySource" [styles]="{width: '100%', height: '260px'}" (chartInstance)="ChartInstanceForLeadCountBySource($event)"></canvasjs-chart>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-md-6 mb-6">
                <div class="card mb-4">
                    <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                        <h6 class="m-0 font-weight-bold text-primary">Top Reasons for Closed Leads</h6>
                    </div>
                    <div class="card-body">
                        <div class="chart-area">
                            <canvasjs-chart [options]="topReasonsForClosedLeads" [styles]="{width: '100%', height: '260px'}" (chartInstance)="ChartInstanceForTopReasonsForClosedLeads($event)"></canvasjs-chart>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-md-6 mb-6">
                <div class="card mb-4">
                    <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                        <h6 class="m-0 font-weight-bold text-primary">Lead Count by Counselor</h6>

                    </div>
                    <div class="card-body">
                        <div class="chart-area">
                            <canvasjs-chart [options]="leadCountByCounsellor" [styles]="{width: '100%', height: '260px'}" (chartInstance)="ChartInstanceForLeadCountByCounsellor($event)"></canvasjs-chart>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-12 col-md-12 mb-12">
                <div class="card mb-4">
                    <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                        <h6 class="m-0 font-weight-bold text-primary">First Lead Owner Analysis</h6>
                    </div>
                    <div class="card-body" style="width: 100%!important;">
                        <table *ngIf="datasAna">
                            <tbody id="myTable">
                                <tr *ngFor="let data of datasAna; let i=i ndex">
                                    <td *ngFor="let cell of data" style="min-width: 120px;">
                                        {{ cell }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<p>&nbsp;</p>
<p>&nbsp;</p>



<div id="filterForm" class="form-popup-popup" style="z-index: 999!important;width: 540px;left:12px;top: 65px; position: absolute;overflow: hidden;background-color: rgb(236, 236, 241);" [style.display]="filterFormCondition ?  'block':'none'">
    <div class="card-header d-flex flex-row align-items-center justify-content-between" style="background-color: rgb(172, 172, 177);max-height: 100px;color: white;">
        <h6 class="font-weight-bold" style="padding-left: 20px;">Create Filter</h6>
        <i class="fa fa-filter" style="position: absolute; top: 17px; left: 15px;"></i>
        <button type="button" class="btn btn-danger" style="float: right;" (click)="closeFilterForm()">X</button>
    </div>
    <div class="card-body" style="max-height: 350px!important;top: 100px!important;">
        <div class="row" style="height: 400px;border-bottom:1px solid rgb(224, 214, 214);" *ngIf="leadSubTypeSelected">
            <p *ngFor="let data of subTypeStatus;i as index" style="padding-right: 10px;"><a *ngIf="data.value!=0">{{data.name}}({{data.value}})&nbsp;<span style="cursor: pointer;"(click)="selectedFilterRemove(data.name)">X</span></a></p>
        </div>
        <div class="row" style="height: 300px;padding: 10px;">
            <div style="border-right:1px solid rgb(224, 214, 214);width: 180px;">
                <p *ngFor="let data of filterType;i as index" (click)="filterTypeClicked(data.name)"><i class="fa fa-square" *ngIf="data.value==0"></i><i class="fa fa-window-minimize" style="color: red;" *ngIf="data.value==1"></i>&nbsp;{{data.name}}</p>
            </div>
            <div class="col" *ngIf="!leadTypeSelected">Please select filters and click apply.</div>

            <div class="col" [style.display]="leadSourseDisp ? 'block' : 'none'" style="height: 300px;overflow-y: scroll;">
                <table style="border-collapse: collapse;border: none;">
                    <tr style="line-height: 4px!important;max-height: 10px;" *ngFor="let data of leadSource;i as index" (click)="filterSubTypeClicked($event, 'Lead Source',data.sourceName)">
                        <td style="border: none;"><input name="leadSourseDisp" type="checkbox" readonly value="{{data.sourceName}}">&nbsp;{{data.sourceName}}</td>
                    </tr>
                </table>

            </div>
            <div class="col" [style.display]="leadStatusDisp ? 'block' : 'none'" style="height: 300px;overflow-y: scroll;">
                <table style="border-collapse: collapse;border: none;">
                    <tr style="line-height: 4px!important;max-height: 10px;" *ngFor="let data of leadStatus;i as index" (click)="filterSubTypeClicked($event, 'Status',data.statusName)">
                        <td style="border: none;"><input name="leadStatusDisp" type="checkbox" readonly value="{{data.statusName}}">&nbsp;{{data.statusName}}</td>
                    </tr>
                </table>

            </div>
            <div class="col" [style.display]="sourceTypeDisp ? 'block' : 'none'" style="height: 300px;overflow-y: scroll;">
                <table style="border-collapse: collapse;border: none;">
                    <tr style="line-height: 4px!important;max-height: 10px;" *ngFor="let data of sourceType;i as index" (click)="filterSubTypeClicked($event, 'Source Type',data)">
                        <td style="border: none;"><input name="sourceType" type="checkbox" readonly value="{{data}}">&nbsp;{{data}}</td>
                    </tr>
                </table>

            </div>
            <div class="col" [style.display]="leadCourseDisp ? 'block' : 'none'" style="height: 300px;overflow-y: scroll;">
                <table style="border-collapse: collapse;border: none;">
                    <tr style="line-height: 4px!important;max-height: 10px;" *ngFor="let data of leadCourse;i as index" (click)="filterSubTypeClicked($event, 'Course',data.courseName)">
                        <td style="border: none;"><input name="leadCourseDisp" type="checkbox" readonly value="{{data.courseName}}">&nbsp;{{data.courseName}}</td>
                    </tr>
                </table>

            </div>
            <div class="col" [style.display]="followUpRequiredDisp ? 'block' : 'none'" style="height: 300px;overflow-y: scroll;">
                <table style="border-collapse: collapse;border: none;">
                    <tr style="line-height: 4px!important;max-height: 10px;" *ngFor="let data of followUpRequired;i as index" (click)="filterSubTypeClicked($event, 'FollowUp Required',data)">
                        <td style="border: none;"><input name="followUpRequiredDisp" type="checkbox" readonly value="{{data}}">&nbsp;{{data}}</td>
                    </tr>
                </table>
            </div>
            <div class="col" [style.display]="stateDisp ? 'block' : 'none'" style="height: 300px;overflow-y: scroll;">
                <table style="border-collapse: collapse;border: none;">
                    <tr style="line-height: 4px!important;max-height: 10px;" *ngFor="let data of state;i as index" (click)="filterSubTypeClicked($event, 'State',data)">
                        <td style="border: none;"><input name="stateDisp" type="checkbox" readonly value="{{data}}">&nbsp;{{data}}</td>
                    </tr>
                </table>
            </div>
            <div class="col" [style.display]="cityDisp ? 'block' : 'none'" style="height: 300px;overflow-y: scroll;">
                <table style="border-collapse: collapse;border: none;">
                    <tr style="line-height: 4px!important;max-height: 10px;" *ngFor="let data of city;i as index" (click)="filterSubTypeClicked($event, 'City',data)">
                        <td style="border: none;"><input name="cityDisp" type="checkbox" readonly value="{{data}}">&nbsp;{{data}}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
    <div class="card-footer" style="float: right;padding-right: 30px;background-color: rgb(236, 236, 241);">
        <button type="button" class="btn btn-danger block" (click)="filterResetClick()">Reset</button>
        <button type="button" class="btn btn-info block" style="float: right;" style="float: right;" (click)="filterApplyClick()">Apply</button>
    </div>
</div>


<div id="counsellorForm" class="form-popup-popup below" style="background-color: rgb(236, 236, 241);z-index: 999!important;width: 540px;left: 43%;top: 65px; position: absolute;width: 300px;" [style.display]="counsellorCondition ? 'block': 'none'">
    <div class="card-header d-flex flex-row align-items-center justify-content-between" style="background-color: rgb(172, 172, 177);color: white;">
        <h6 class="font-weight-bold">Filter By Counsellor</h6>
        <button type="button" class="btn btn-danger" style="float: right;" (click)="closeCounsellorForm()">X</button>
    </div>
    <div class="card-body" style="height: 200px!important;overflow-y: hidden;">

        <div *ngFor="let couns of userIdAndName; let i=i ndex">
            <input type="checkbox" name="counsellor" value="{{couns.userName}}"> {{couns.userName}}
        </div>

    </div>
    <div class="card-footer" style="float: right;padding-right: 30px;background-color: rgb(236, 236, 241);">

        <button type="button" class="btn btn-danger block" (click)="counsellorfilterResetClick()">Reset</button>
        <button type="button" class="btn btn-info block" style="float: right;" style="float: right;" (click)="counsellorFilterApplyClick()">Apply</button>
    </div>
</div>
<div id="dateRangeForm" class="form-popup-popup below" style="background-color: rgb(236, 236, 241);z-index: 999!important;top: 65px; position: absolute;width: 550px;" [style.display]="dateRangeCondition ? 'block': 'none'">
    <div class="card-header d-flex flex-row align-items-center justify-content-between" style="background-color: rgb(172, 172, 177);color: white;">
        <h6 class="font-weight-bold">Filter By Date</h6>
        <button type="button" class="btn btn-danger" style="float: right;" (click)="closeRangeForm()">X</button>
    </div>
    <div class="card-body" style="height: 350px!important;overflow-y: hidden;">
        <div class="row">
            <div class="col">
                <label class="radio-container">Creation Date
                    <input type="checkbox"name="rangeType"id="creationDate" checked="checked" (click)="rangeTypeClicked(1)">
                    <span class="radio-checkmark"></span>
                  </label>
            </div>

            <div class="col">
                <label class="radio-container">Next Follow Ups Date
                    <input type="checkbox"name="rangeType"id="followUpDate"(click)="rangeTypeClicked(2)">
                    <span class="radio-checkmark"></span>
                  </label>
            </div>
            <!-- <div class="col">
                <label class="radio-container">Modification Date
                    <input type="checkbox"name="rangeType"id="notificationDate"(click)="rangeTypeClicked(3)">
                    <span class="radio-checkmark"></span>
                  </label>
            </div> 
            <div class="col">
                <label class="radio-container">Re Enquire Date
                    <input type="checkbox"name="rangeType"id="reEnquireDate"(click)="rangeTypeClicked(4)">
                    <span class="radio-checkmark"></span>
                  </label>
            </div>-->

        </div>
        <div class="wrapper">
            <mat-card class="mat-elevation-z3 presets">
                <button mat-button *ngFor="let preset of presets" (click)="selectPreset(preset.range)">
              {{ preset.label }}
            </button>
            </mat-card>

            <mat-card class="calendar">
                <mat-calendar #calendar [(selected)]="selectedDateRange" (selectedChange)="rangeChanged($event)" [minDate]="minDate" [maxDate]="maxDate"></mat-calendar>
            </mat-card>
        </div>
    </div>
    <div class="card-footer" style="float: right;padding-right: 30px;background-color: rgb(236, 236, 241);">
        <button type="button" class="btn btn-danger block" (click)="dateFilterResetClick()">Reset</button>
        <button type="button" class="btn btn-info block" style="float: right;" style="float: right;" (click)="dateFilterApplyClick()">Apply</button>
    </div>
</div>