import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DateRange, DefaultMatCalendarRangeStrategy, MatCalendar, MatRangeDateSelectionModel } from '@angular/material/datepicker';
import { LeadService } from '../shared/services/lead.services';
import { ActionWiseLeadCount } from '../shared/models/ActionWiseLeadCount';
import { LocalStorageService } from '../utility/localStorageService';
import * as moment from 'moment';

@Component({
  selector: 'app-user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrls: ['./user-dashboard.component.css'],
  providers: [DefaultMatCalendarRangeStrategy, MatRangeDateSelectionModel]
})
export class UserDashboardComponent implements OnInit {


  presets = [
    {
      label: 'Today',
      range: {
        start: new Date(),
        end: new Date(),
      },
    },
    {
      label: 'Yesterday',
      range: {
        start: (() => {
          const date = new Date();
          date.setDate(date.getDate() - 1);
          return date;
        })(),
        end: (() => {
          const date = new Date();
          date.setDate(date.getDate() - 1);
          return date;
        })(),
      },
    },
    {
      label: 'Last 7 days',
      range: {
        start: (() => {
          const date = new Date();
          date.setDate(date.getDate() - 7);
          return date;
        })(),
        end: new Date(),
      },
    },
    {
      label: 'Last 30 days',
      range: {
        start: (() => {
          const date = new Date();
          date.setDate(date.getDate() - 30);
          return date;
        })(),
        end: new Date(),
      },
    }
    ,
    {
      label: 'Last 180 days',
      range: {
        start: (() => {
          const date = new Date();
          date.setDate(date.getDate() - 90);
          return date;
        })(),
        end: new Date(),
      },
    },
    {
      label: 'Last 1 Year',
      range: {
        start: (() => {
          const date = new Date();
          date.setDate(date.getDate() - 365);
          return date;
        })(),
        end: new Date(),
      },
    }
  ];



  leadCountBySource :any
  leadCountByStatus :any
  actionWiseLeadCount:ActionWiseLeadCount[]=[]
  @ViewChild('calendar') calendar: MatCalendar<Date> | undefined;
  selectedDateRange: DateRange<Date> | undefined;
  rangeOptionSelected = "Creation Date";

  maxDate: Date | undefined;
  minDate: Date | undefined;
  chartLeadCountBySourceChart: any
  statusWiseTotalLeadsChat:any
  userType: string;

  constructor(
    public datepipe: DatePipe,
    private readonly selectionModel: MatRangeDateSelectionModel<Date>,
    private readonly selectionStrategy: DefaultMatCalendarRangeStrategy<Date>,
    private leadService: LeadService,
    private localStorage:LocalStorageService
    
  ) { }
  selectedDate: string
  ngOnInit(): void {
    this.userType = this.localStorage.get("userType")
    this.minDate = new Date(this.localStorage.get("showMeLeadsFromDate"));
    this.maxDate = this.addDays(new Date(),Number(this.localStorage.get("showMeUpcomingEventsOf")));
    this.selectedDateRange = new DateRange<Date>(
      new Date(),
      new Date()
    );
    this.selectedDate = this.rangeOptionSelected + this.datepipe.transform(this.selectedDateRange.start, 'MMM dd, yyy') + " - " + this.datepipe.transform(this.selectedDateRange.end, 'MMM dd, yyy')
    this.getLeadCountBySource(this.localStorage.get("showMeLeadsFromDate"),moment(this.maxDate).format('YYYY-MM-DD'),this.localStorage.get("refNo"),this.rangeOptionSelected)
    this.getStatusWiseTotalLeads(this.localStorage.get("showMeLeadsFromDate"),moment(this.maxDate).format('YYYY-MM-DD'),this.localStorage.get("refNo"),this.rangeOptionSelected)
    this.getActionWiseLeadCount(this.localStorage.get("showMeLeadsFromDate"),moment(this.maxDate).format('YYYY-MM-DD'),this.localStorage.get("refNo"),this.rangeOptionSelected)
  }
  getActionWiseLeadCount(fromDate: string,toDate:string,counsellor:string,rangeType:string)
  {
    this.actionWiseLeadCount=[]
    this.leadService.getActionWiseLeadCount(fromDate,toDate,counsellor,rangeType).subscribe(resp => {
      if (resp.length > 0) {
        this.actionWiseLeadCount=resp
      }
    });
  }
  getLeadCountBySource(fromDate: string,toDate:string,counsellor:string,rangeType:string) {
    this.leadCountBySource = {
      animationEnabled: true,
  
      axisX: {
        labelAngle: -90
      },
      axisY: {
        title: "Leads"
      },
      toolTip: {
        shared: true
      },
      legend: {
        cursor: "pointer",
      },
      data: []
    }
    let data = {
      type: "column",
      showInLegend: false,
      dataPoints: [
        { label: "All", y: 0 }
      ]
    }
    this.leadService.getLeadCountBySourceAndRange(fromDate,toDate,counsellor,rangeType).subscribe(resp => {
      if (resp.length > 0) {
        data.dataPoints = [];
        resp.forEach(e => {
          data.dataPoints.push({ label: e.leadName.toString(), y: e.count })
        }
        )
        this.leadCountBySource.data.push(data);
        this.chartLeadCountBySourceChart.render();
      }
    });
  }
  ChartInstanceForLeadCountBySource(chart: object) {
    this.chartLeadCountBySourceChart = chart;
  }
  getStatusWiseTotalLeads(fromDate: string,toDate:string,counsellor:string,rangeType:string) {
    this.leadCountByStatus = {
      animationEnabled: true,
  
      axisX: {
        labelAngle: -90
      },
      axisY: {
        title: "Leads"
      },
      toolTip: {
        shared: true
      },
      legend: {
        cursor: "pointer",
      },
      data: []
    }
    let data = {
      type: "column",
      showInLegend: false,
      dataPoints: [
        { label: "Initiated", y: 13 }
      ]
    }
    this.leadService.getStatusWiseTotalLeadsByDateAndType(fromDate,toDate,counsellor,rangeType).subscribe(resp => {
      if (resp.length > 0) {
        data.dataPoints = [];
        resp.forEach(e => {
          data.dataPoints.push({ label: e.statusName.toString(),y: e.count })
        }
        )
        this.leadCountByStatus.data.push(data);
        this.statusWiseTotalLeadsChat.render();
      }
    });
  }
  ChartInstanceForStatusWiseTotalLeads(chart: object) {
    this.statusWiseTotalLeadsChat = chart
  }



  // Selects a preset date range.
  selectPreset(presetDateRange: { start: Date; end: Date }) {
    this.selectedDateRange = new DateRange<Date>(
      presetDateRange.start,
      presetDateRange.end
    );

    // Jump into month of presetDateRange.start
    if (presetDateRange.start && this.calendar)
      this.calendar._goToDateInView(presetDateRange.start, 'month');

    this.selectedDate = this.rangeOptionSelected + this.datepipe.transform(this.selectedDateRange.start, 'MMM dd, yyy') + " - " + this.datepipe.transform(this.selectedDateRange.end, 'MMM dd, yyy')

  }
  rangeChanged(selectedDate: Date) {
    const selection = this.selectionModel.selection,
      newSelection = this.selectionStrategy.selectionFinished(
        selectedDate,
        selection
      );

    this.selectionModel.updateSelection(newSelection, this);
    this.selectedDateRange = new DateRange<Date>(
      newSelection.start,
      newSelection.end
    );
  }
  rangeTypeClicked(id: number) {
    const r1 = document.getElementById("creationDate") as HTMLInputElement
    const r2 = document.getElementById("nextActionDate") as HTMLInputElement
    if (id == 1 && r1?.checked) {
      r2.checked = false;
      this.rangeOptionSelected = "Creation Date";
      this.selectedDate = this.rangeOptionSelected + this.datepipe.transform(this.selectedDateRange.start, 'MMM dd, yyy') + " - " + this.datepipe.transform(this.selectedDateRange.end, 'MMM dd, yyy')

    }
    if (id == 2 && r1?.checked) {
      r1.checked = false;
      this.rangeOptionSelected = "Next Follow-Up Date";
      this.selectedDate = this.rangeOptionSelected + this.datepipe.transform(this.selectedDateRange.start, 'MMM dd, yyy') + " - " + this.datepipe.transform(this.selectedDateRange.end, 'MMM dd, yyy')

    }
    
  }
  openRangeForm() {
    document.getElementById("dateRangeForm").style.display = "block";
  }

  closeRangeForm() {
    document.getElementById("dateRangeForm").style.display = "none";
  }
  addDays(date: Date, days: number): Date {
    date.setDate(date.getDate() + days);
    return date;
  }
  ApplyClicked()
  {
    const r1 = document.getElementById("creationDate") as HTMLInputElement
    const r2 = document.getElementById("nextActionDate") as HTMLInputElement

    this.selectedDate=this.selectedDate.replace(this.rangeOptionSelected,"")
    let fromDate:string=moment(this.selectedDate.split("-")[0]).format('YYYY-MM-DD');
    let toDate:string=moment(this.selectedDate.split("-")[1]).format('YYYY-MM-DD');

    this.getLeadCountBySource(fromDate,toDate,this.localStorage.get("refNo"),this.rangeOptionSelected)
    this.getStatusWiseTotalLeads(fromDate,toDate,this.localStorage.get("refNo"),this.rangeOptionSelected)
    this.getActionWiseLeadCount(fromDate,toDate,this.localStorage.get("refNo"),this.rangeOptionSelected)
    this.closeRangeForm();
  }
  ResetClicked()
  {
    this.selectedDateRange = new DateRange<Date>(
      new Date(),
      new Date()
    );
    this.selectedDate = this.rangeOptionSelected + this.datepipe.transform(this.selectedDateRange.start, 'MMM dd, yyy') + " - " + this.datepipe.transform(this.selectedDateRange.end, 'MMM dd, yyy')
    this.getLeadCountBySource(this.localStorage.get("showMeLeadsFromDate"),moment(this.maxDate).format('YYYY-MM-DD'),this.localStorage.get("refNo"),this.rangeOptionSelected)
    this.getStatusWiseTotalLeads(this.localStorage.get("showMeLeadsFromDate"),moment(this.maxDate).format('YYYY-MM-DD'),this.localStorage.get("refNo"),this.rangeOptionSelected)
    this.getActionWiseLeadCount(this.localStorage.get("showMeLeadsFromDate"),moment(this.maxDate).format('YYYY-MM-DD'),this.localStorage.get("refNo"),this.rangeOptionSelected)
    this.closeRangeForm();
  }
  stopPropagation(event){
    event.stopPropagation();
    }
  searchTable() {
    var term, table;
    term=document.getElementById("myInput") as unknown as HTMLInputElement
    // get term to search
    term = term.value.toLowerCase();
    table =document.getElementById("myTable") as HTMLTableElement
    // get table rows, handle as array.
    table = Array.from(table.rows);

    // filter non-matching rows; these are set to display='none'
    table.filter(function(el) {
        return el.textContent.toLowerCase().indexOf(term) ==
            -1
    }).map(x => x.style.display = "none");

    // filter matching rows; these are set to display =''
    table.filter(function(el) {
        return el.textContent.toLowerCase().indexOf(term) > -1
    }).map(x => x.style.display = "");
}
}
