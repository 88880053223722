window.onAndroidToken = function(token) {
    console.log("Android Token Received =" + token);
    document.getElementById("andToken").value = token;
    event = new Event("token");
    window.dispatchEvent(event, { 'token': token });
}
window.onPhoneCallReceived = function(phoneNo) {
    console.log("Call Received =" + phoneNo);
    document.getElementById("callFrom").value = phoneNo;
    event = new Event("call");
    window.dispatchEvent(event, { 'phoneNo': phoneNo });
}
exports.onPhoneInitiated = function(phoneNo) {
    if (typeof app !== 'undefined' && app !== null) {
        app.onPhoneInitiated(phoneNo);
    } else {
        alert('Not Applicable');
    }
}
exports.sendSMSRequest = function(phoneNo, message) {
    if (typeof app !== 'undefined' && app !== null) {
        app.onSendSMSRequest(phoneNo, message);
    } else {
        alert('Not Applicable');
    }
}
exports.sendWhatsAppRequest = function(phoneNo, message) {
    if (typeof app !== 'undefined' && app !== null) {
        app.onSendWhatsAppMessageRequest(phoneNo, message);
    } else {
        alert('Not Applicable');
    }
}

function searchAndGet(fulldata, searchData) {
    let startPos = fulldata.search("<" + searchData + ">") + searchData.length + 2;
    let lastPos = fulldata.search("</" + searchData + ">");
    return fulldata.substring(startPos, lastPos);
}

function SSEControl() {
    console.log(localStorage.getItem("deviceId"))
    console.log(localStorage.getItem("apiUrl"));
    console.log(localStorage.getItem("osType"))
    if (localStorage.getItem("apiUrl") != null && localStorage.getItem("email") != null && localStorage.getItem("osType") != null) {
        var source = new EventSource(localStorage.getItem("apiUrl") + '/enableSSE/' + localStorage.getItem("email") + '/' + localStorage.getItem("osType").substring(0, 3));
        source.addEventListener('message', function(e) {
            console.log(searchAndGet(e.data, "deviceId"));
            console.log(searchAndGet(e.data, "message"));
            console.log(searchAndGet(e.data, "deviceType"));
            console.log(searchAndGet(e.data, "refNo"));
            console.log(searchAndGet(e.data, "eMail"));
        }, false);
    }
}

exports.loginSuccess = function(userName) {
        if (typeof app !== 'undefined' && app !== null) {
            app.LoginSuccessFromWeb(userName);
        } else {
            alert('Not Applicable');
        }
    }
    // setTimeout(SSEControl, 1000);
    // setInterval(SSEControl, 600000);