import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from "src/environments/environment";
import { Observable } from "rxjs/internal/Observable";
import { TblCall } from "../models/call";
import { catchError } from 'rxjs/operators';
import { throwError } from "rxjs";
import { CommonHeader } from "./common-header";
import { TblLead } from "../models/leads";
import { ExceptionHandler } from "./errorhandler";
import { LocalStorageService } from "src/app/utility/localStorageService";
import { TblNote } from "../models/note";
import { NotesWIthStudentName } from "../models/noteswIthstudentname";
@Injectable({
  providedIn: "root",
})
export class NotesService {
 
  
  

  // The response body may contain clues as to what went wrong,
  
  private serviceRoot = `${environment.serviceRoot}`;
  commonHeader:CommonHeader=new CommonHeader() 
  private options=this.commonHeader.options;

  
  constructor(
    private http: HttpClient,
    private ehandler:ExceptionHandler,
        private localStorage:LocalStorageService
  ) { }
  getLeadTypes(): Observable<any[]> {
    const endPoint = `${this.serviceRoot}/getLeadTypes`;
    return this.http.get<any[]>(endPoint,  this.options).pipe(catchError(this.ehandler.errorHandler));
  }
  getAll(accountId: number,leadId : number, status:number): Observable<TblCall[]> {
    const endPoint = `${this.serviceRoot}/selectall?accountId=${accountId}&leadId=${leadId}&status=${status}`;
    return this.http.post<TblCall[]>(endPoint, '', this.options).pipe(catchError(this.errorHandler));
  }

   
  getLeadANotesByIdAndDate(refNo: number, onDate: string) {
      this.localStorage.set("method","Read")
      this.localStorage.set("operation","Get Course By Id")
      const endPoint = `${this.serviceRoot}/getLeadANotesByIdAndDate/${refNo}/${onDate}`;
      return this.http.get<NotesWIthStudentName[]>(endPoint,  this.options).pipe(catchError(this.ehandler.errorHandler));
    }
    getNewLeadsTillTodate(refNo: number, onDate: string) {
      this.localStorage.set("method","Read")
      this.localStorage.set("operation","Get Course By Id")
      const endPoint = `${this.serviceRoot}/getNewLeadsTillTodate/${refNo}/${onDate}`;
      return this.http.get<NotesWIthStudentName[]>(endPoint,  this.options).pipe(catchError(this.ehandler.errorHandler));
    }

  getById(accountId: number, callId:number): Observable<TblCall> {
    const endPoint = `${this.serviceRoot}/selectbyid?accountId=${accountId}&callId=${callId}`;
    return this.http.post<TblCall>(endPoint, '', this.options);
  }

  create(data:TblCall):Observable<any>{
    const endPoint = `${this.serviceRoot}/create`;
    return this.http.post<any>(endPoint, data,this.options);
  }

  update(data:TblCall):Observable<any>{
    const endPoint = `${this.serviceRoot}/update`;
    return this.http.post<any>(endPoint, data,this.options);
  }

  updateLeadViewedBy(id: any, name: string): Observable<any>{
    const endPoint = `${this.serviceRoot}/updateLeadViewedBy/${id}/${name}`;
    return this.http.get<any[]>(endPoint,  this.options).pipe(catchError(this.ehandler.errorHandler));

  }
  errorHandler(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }

}
