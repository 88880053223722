import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LocalStorageService } from 'src/app/utility/localStorageService';
import { ProfileDialogComponent } from '../dialogs/profile-dialog/profile-dialog.component';
import { SettingsDialogComponent } from '../dialogs/settings-dialog/settings-dialog.component';
import { ActivityLogDialogComponent } from '../dialogs/activity-log-dialog/activity-log-dialog.component';
import { ConfirmDialogComponent } from '../dialogs/confirm-dialog/confirm-dialog.component';
import { TblNote } from '../models/note';
import { NotesService } from '../services/calls.services';
import * as moment from 'moment';
import { NotesWIthStudentName } from '../models/noteswIthstudentname';
import { NotificationService } from 'src/app/notification.service';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.css'],

})
export class TopBarComponent implements OnInit {
  userType:string
  userName: string
  androidOS: boolean = false
  modalRef: BsModalRef;
  showSubmenu: boolean = false
  panelOpenState = false;
  showTopBar: boolean;
  inLoginPage: boolean;
  notInLogin: boolean;
  onDate:string
  cntNewLeads:number=0;
  cntFolloUps:number=0;
  followUpList:NotesWIthStudentName[]=[]
  newLeadsTillToday:NotesWIthStudentName[]=[]
  menuIconToggle: boolean=true;
  constructor(private localStorage: LocalStorageService,
    private router: Router,
    private modalService: BsModalService,
    private  notesService:NotesService,
    private noifyService:NotificationService
  ) { }

  ngOnInit(): void {
    this.userType= this.localStorage.get("userType")
    let refNo:number=0;
    if(this.localStorage.get("userType")!="Admin")
        refNo=Number(this.localStorage.get("refNo"));
    this.onDate=(moment(new Date())).format('YYYY-MM-DD').toString()
    this.userName = this.localStorage.get("userName")
    this.notesService.getNewLeadsTillTodate(refNo,this.onDate).subscribe(resp => {
      resp.forEach(e=>{
        if(e.viewedBy==null)
        {
          e.display=true
          this.newLeadsTillToday.push(e)
        }
        else if(!e.viewedBy.includes(this.localStorage.get("userName")))
        {
          e.display=true
          this.newLeadsTillToday.push(e)
        }
      });
      this.cntNewLeads= this.newLeadsTillToday.length
    });
    this.notesService.getLeadANotesByIdAndDate(refNo,this.onDate).subscribe(resp => {
      resp.forEach(e=>{
        if(e.viewedBy==null)
        {
          e.display=true
          this.followUpList.push(e)
        }
        else if(!e.viewedBy.includes(this.localStorage.get("userName")))
        {
          e.display=true
          this.followUpList.push(e)
        }
      });
      this.cntFolloUps=this.followUpList.length
    });

    setTimeout(() => {
      if(this.getOS()=="Android")
        this.androidOS=true
      else
      this.androidOS=false
    }, 100);
  }

  changeOfRoutes() {
    console.log(" I am here 3")
    if (window.location.href.includes("/login")) {
      this.showTopBar = false
      this.inLoginPage = true
      this.showTopBar = false

    }
    else {
      this.inLoginPage = false
      this.showTopBar = true
      this.showTopBar = true
    }
  
    if (this.localStorage.get("userName") == "null" || this.localStorage.get("refNo") == "null") {
      this.showTopBar = false
      this.router.navigateByUrl("login")
    }
  }
  Profile() {
    this.modalRef = this.modalService.show(ProfileDialogComponent, { class: 'modal-dialog modal-dialog-centered modal-sm' });
  }
  logOutClick()
  {
    let modalConfig: any = { animated: true, keyboard: true, backdrop: true, ignoreBackdropClick: false };
    const initialState = {mode:'Logout',  module: 'Logout', refNo:'0'};
    this.modalService.show(ConfirmDialogComponent, Object.assign({}, modalConfig, { class: 'modal-sm', initialState }))
    
  }
  
  settingsClick() {
    this.modalRef = this.modalService.show(SettingsDialogComponent, { class: 'modal-dialog modal-dialog-centered modal-sm' });

  }
  activityLog() {
    this.modalRef = this.modalService.show(ActivityLogDialogComponent, { class: 'modal-dialog modal-dialog-centered modal-lg' });
  }

  openFilterForm() {
    document.getElementById("myFilterForm").style.display = "block";
  }

  closeFilterForm() {
    document.getElementById("myFilterForm").style.display = "none";
  }
  getOS() {
    var uA = navigator.userAgent || navigator.vendor;
    if ((/iPad|iPhone|iPod/.test(uA)) || (uA.includes('Mac') && 'ontouchend' in document)) return 'iOS';

    var i, os = ['Windows', 'Android', 'Unix', 'Mac', 'Linux', 'BlackBerry'];
    for (i = 0; i < os.length; i++) if (new RegExp(os[i], 'i').test(uA)) return os[i];
  }
  changeIcon()
  {
    this.menuIconToggle = !this.menuIconToggle;
  }
  
  stopPropagation(event){
    event.stopPropagation();
    }
    clearLeadClick(id:any)
    {
     // alert(this.localStorage.get("userName"))
      this.notesService.updateLeadViewedBy(id,this.localStorage.get("userName")).subscribe(resp => {
       this.noifyService.showSuccess("Success","Lead Removed From List")
       this.newLeadsTillToday.forEach(e=>{
        if(e.refNo==id)
          e.display=false
       })

      });
    }
    clearFollowUpClick(id:any)
    {
     // alert(this.localStorage.get("userName"))
      this.notesService.updateLeadViewedBy(id,this.localStorage.get("userName")).subscribe(resp => {
       this.noifyService.showSuccess("Success","Lead Removed From List")
       this.followUpList.forEach(e=>{
        if(e.refNo==id)
          e.display=false
       })

      });
    }
}
