import { Component, HostListener, Inject, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { TblLead } from 'src/app/shared/models/leads';
import { LeadService } from 'src/app/shared/services/lead.services';

import { LocalStorageService } from '../utility/localStorageService';
import * as moment from 'moment';

import { LeadFilter } from '../shared/models/lead-filter';
import { LeadNotes } from '../shared/models/notes';
import { ViewLeadWiseActivityComponent } from '../shared/dialogs/view-lead-wise-activity/view-lead-wise-activity.component';
import { MatTableDataSource } from '@angular/material/table';
import { FirstLeadAnalysis } from '../shared/models/first-lead-analysis';
import { PageEvent } from '@angular/material/paginator';
import { DatePipe, formatDate } from '@angular/common';
import { SSEActionCall } from '../shared/models/sse-action';
import { RawLeadsFromThirdParty } from '../shared/services/raw-data-from-third-party';
import { PagerService } from '../shared/pagination';
@Component({
  selector: 'app-raw-list',
  templateUrl: './raw-list.component.html',
  styleUrls: ['./raw-list.component.css']
})
export class RawListComponent implements OnInit {
  sidePanelMode:boolean=false;

  model: TblLead;
  rawLeadsFromThirdParty: RawLeadsFromThirdParty[];
  leadNotes: LeadNotes

 
  modalRef: BsModalRef;
 
  boxTitle: string;
  leadFilter: LeadFilter
  edit: any;
  today: Date
  filteredData;
  androidOs: boolean = false
  currentDate: string
  listSource: string[]
  listLeadChannel: string[]
  listState: string[]
  listCity: string[]

  
  status: string = "All";
  rawLeadsFullData: RawLeadsFromThirdParty[];

  sSEActionCall:SSEActionCall;


  dataSource: MatTableDataSource<RawLeadsFromThirdParty>
  dataValue: FirstLeadAnalysis[] = [];

  displayedColumns: string[] = ['Student Details', 'Contact Details','Address' ,'Lead Status', 'Lead Details'];
  pageEvent: PageEvent;
  length: number;
  pageSize: number;
  rawLeadsSource: { name: string; value: number; }[];
  seqlistSource: any[];
  seqlistLeadChannel: any[];
  seqlistState: any[];
  seqlistCity: any[];
  pager: any = {};
  private allItems: any[];
  // paged items
  pagedItems: any[];
  userType: string;
  constructor(private _leadservices: LeadService,
    private modalService: BsModalService,
    private localStorage: LocalStorageService,
    private datePipe: DatePipe,
    private pagerService: PagerService,
    @Inject(LOCALE_ID) public locale: string,

  ) {
    this.dataSource = new MatTableDataSource;
  }

  ngOnInit(): void {
    this.userType = this.localStorage.get("userType")
    this.fromDate = this.localStorage.get("showMeLeadsFromDate")
   
    this.status = "All"
    
    this.currentDate = (moment(new Date())).format('YYYY-MM-DD')

    this.leadFilter = new LeadFilter()
    this.leadFilter.fromDate = (moment(new Date(this.fromDate))).format('YYYY-MM-DD')
    this.leadFilter.toDate = (moment(new Date())).format('YYYY-MM-DD')
    this.leadFilter.state = "All"
    this.leadFilter.city = "All"
    this.leadFilter.leadSource = "All"
    this.leadFilter.leadCampaign = "All"
    this.leadFilter.userRefNo = this.localStorage.get("refNo")

    this.leadNotes = new LeadNotes()


    this.model = new TblLead();

    if (this.localStorage.get("osType") == "Android") {
      this.androidOs = true
    }
    else {
      this.androidOs = false
    }
    this.rawLeadsSource = [{ name: 'All', value: 0 }]
    this.getAllRawLeads();
  }
  getLeadsBySource(leadSource: any) {
    this.rawLeadsFromThirdParty = this.rawLeadsFullData;
    if (leadSource != 'All') {
      this.rawLeadsFromThirdParty = this.rawLeadsFromThirdParty.filter((rawLeadsFromThirdParty) => rawLeadsFromThirdParty.leadSource==leadSource);
    }
    this.dataSource.data = this.rawLeadsFromThirdParty
    this.setPage(1)
  }

  getAllRawLeads() {
    this._leadservices.getRawLeadsByBatch('All').subscribe(resp => {
     
      if (resp.length > 0) {
        this.rawLeadsFromThirdParty = resp
        this.dataSource.data = this.rawLeadsFromThirdParty;
        let cnt = 0;
        
       
        this.rawLeadsFromThirdParty.forEach(e => {
         console.log(e)
          let notFound: Boolean = true
          // let dd="";
          // for(let i=0;i<10;i++)
          // {
          //   if(e.actionDate.toString().charAt(i)==",")
          //     dd=dd+"-"
          //   else
          //     dd=dd+e.actionDate.toString().charAt(i)
          // }
           e.actionDate=e.actionDate.replace('T',' ')
          cnt++;
          this.rawLeadsSource.forEach(f => {
            console.log(f)
            if (e.leadSource == f.name) {
              f.value++;
              notFound = false
            }
          })
          if (notFound) {
            this.rawLeadsSource.push({ name: e.leadSource, value: 1 })
          }
          
        })
        this.rawLeadsSource.forEach(f => {
          if (f.name == "All")
            f.value = cnt;
        });
        this.dataSource.data = this.rawLeadsFromThirdParty
        this.rawLeadsFullData = resp
        this.listSource = []
        this.listLeadChannel = []
        this.listCity = []
        this.listState = []
        this.seqlistSource = []
        this.seqlistLeadChannel = []
        this.seqlistState = []
        this.seqlistCity = []
        this.rawLeadsFromThirdParty.forEach(e => {
          let found = false

          for (let i = 0; i < this.listSource.length; i++) {
            if (e.leadSource == this.listSource[i])
              found = true
          }
          if (found == false) {

            this.listSource.push(e.leadSource)
            this.seqlistSource.push(e.leadSource)
          }
          found = false
          for (let i = 0; i < this.listLeadChannel.length; i++) {
            if (e.leadChannel == this.listLeadChannel[i])
              found = true
          }

          if (found == false) {
            this.listLeadChannel.push(e.leadChannel)
            this.seqlistLeadChannel.push(e.leadChannel)
          }

          found = false
          for (let i = 0; i < this.listCity.length; i++) {
            if (e.city == this.listCity[i])
              found = true
          }


          if (found == false) {
            this.listCity.push(e.city)
            this.seqlistCity.push(e.city)
          }


          found = false
          for (let i = 0; i < this.listState.length; i++) {
            if (e.state == this.listState[i])
              found = true
          }


          if (found == false) {
            this.listState.push(e.state)
            this.seqlistState.push(e.state)
          }
        })
        this.setPage(1)
      }

    });




  }
  fromDate: string
  setPage(page: number) {
 
    this.allItems = this.rawLeadsFromThirdParty
    this.pager = this.pagerService.getPager(this.allItems.length, page, 9);
    this.pagedItems = this.allItems.slice(this.pager.startIndex, this.pager.endIndex + 1);
   

  }
  filterLeads() {
    this.rawLeadsFromThirdParty = this.rawLeadsFullData;
    if (this.leadFilter.state != 'All') {
      this.rawLeadsFromThirdParty = this.rawLeadsFromThirdParty.filter((rawLeadsFromThirdParty) => rawLeadsFromThirdParty.state.includes(this.leadFilter.state));
    }
    if (this.leadFilter.city != 'All') {
      this.rawLeadsFromThirdParty = this.rawLeadsFromThirdParty.filter((rawLeadsFromThirdParty) => rawLeadsFromThirdParty.city.includes(this.leadFilter.city));
    }
    if (this.leadFilter.leadSource != 'All') {
      this.rawLeadsFromThirdParty = this.rawLeadsFromThirdParty.filter((rawLeadsFromThirdParty) => rawLeadsFromThirdParty.leadSource.includes(this.leadFilter.leadSource));
    }
    if (this.leadFilter.leadCampaign != 'All') {
      this.rawLeadsFromThirdParty = this.rawLeadsFromThirdParty.filter((rawLeadsFromThirdParty) => rawLeadsFromThirdParty.leadCampaign.includes(this.leadFilter.leadCampaign));
    }
    this.rawLeadsFromThirdParty = this.rawLeadsFromThirdParty.filter((rawLeadsFromThirdParty) => rawLeadsFromThirdParty.actionDate >= this.datePipe.transform(this.leadFilter.fromDate, 'dd-MM-yyyy') && rawLeadsFromThirdParty.actionDate <= this.datePipe.transform(this.leadFilter.toDate, 'dd-MM-yyyy'));
    this.dataSource.data = this.rawLeadsFromThirdParty
  }
  
  viewLeadWiseActivityLog(id, name) {
    this.modalRef = this.modalService.show(ViewLeadWiseActivityComponent, { class: 'modal-dialog modal-dialog-centered' });
    this.modalRef.content.studentId = id;
    this.modalRef.content.studentName = name;
  }


  
  openFilterForm() {
    document.getElementById("myFilterForm").style.display = "block";
  }

  closeFilterForm() {
    document.getElementById("myFilterForm").style.display = "none";
  }

  
  @HostListener('window:load', ['$event'])
  onPageLoad(event: Event) {
    setTimeout(() => {
      document.getElementById("dataArea").style.height = (window.innerHeight - 210) + 'px';
    }, 500);

  }
  ngAfterViewInit() {
    document.getElementById("dataArea").style.height = (window.innerHeight - 232) + 'px';
  }

  leftClick() {
    document.getElementById('pagecontainer').scrollLeft -= 20;
  }
  rightClick() {
    document.getElementById('pagecontainer').scrollLeft += 20;
  }

  stopPropagation(event){
    event.stopPropagation();
    }
  searchTable() {
    var term, table;
    term=document.getElementById("myInput") as unknown as HTMLInputElement
    // get term to search
    term = term.value.toLowerCase();
    table =document.getElementById("myTable") as HTMLTableElement
    // get table rows, handle as array.
    table = Array.from(table.rows);

    // filter non-matching rows; these are set to display='none'
    table.filter(function(el) {
        return el.textContent.toLowerCase().indexOf(term) ==
            -1
    }).map(x => x.style.display = "none");

    // filter matching rows; these are set to display =''
    table.filter(function(el) {
        return el.textContent.toLowerCase().indexOf(term) > -1
    }).map(x => x.style.display = "");
}
}
