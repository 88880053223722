<div class="container-fluid" id="container-wrapper" *ngIf="userType=='Admin' || userType=='Counsellor'">
    <div class="row" style="border-bottom: 2px solid grey;padding-bottom: 3px;">
        <h4 style="text-align: center;">Dashboard</h4>
    </div>
    <div class="row" style="padding-top: 10px;">
        <a style="cursor: pointer;display: inline-flex;font-size: 14px;width: 100%;" (click)="openRangeForm()">
            <input id="inputdefault" placeholder="Date Range" type="text" [value]="selectedDate" style=" position: relative;
                color: #aaa;width: 100%;" readonly>
            <i class="fa fa-calendar fa-lg" style="position: fixed;margin-top: 5px;right: 25px;"></i>
        </a>
    </div>
    <h5><b>Source Wise Lead Status</b></h5>

    <div class="row" style="border: 1px solid rgb(186, 185, 243);margin-top: 10px!important;padding: 5px;">
        <div class="chart-area " style="width: 100%!important;">
            <canvasjs-chart [options]="leadCountBySource" [styles]="{width: '100%', height:'100%'}" (chartInstance)="ChartInstanceForLeadCountBySource($event) "></canvasjs-chart>
        </div>
    </div>
    <h5><b>Status Wise Lead Status</b></h5>
    <div class="row" style="border: 1px solid rgb(186, 185, 243);margin-top: 10px!important;padding: 5px;">
        <div class="chart-area " style="width: 100%!important;">
            <canvasjs-chart [options]="leadCountByStatus" [styles]="{width: '100%', height:'100%'}" (chartInstance)="ChartInstanceForStatusWiseTotalLeads($event) "></canvasjs-chart>
        </div>
    </div>
    <h5><b>Communication Wise Lead Status</b></h5>
    <table class="table" style="width: 100%;">
        <thead>
            <tr>
                <th>#</th>
                <th>Communication Type</th>
                <th>Leads</th>
            </tr>
        </thead>
        <tbody id="myTable">
            <tr *ngFor="let item of actionWiseLeadCount">
                <td>1</td>
                <td>{{item.action}}</td>
                <td>{{item.count}}</td>

            </tr>

        </tbody>
    </table>
</div>


<div id="dateRangeForm" class="form-popup-popup" style="z-index: 9999999!important;width: 100%;top:60px;max-width: 400px; ">
    <div class="card-header d-flex flex-row align-items-center justify-content-between ">
        <h6 class="font-weight-bold text-primary ">Lead Filter</h6>
        <button type="button " class="btn btn-danger " style="float: right; " (click)="closeRangeForm() ">X</button>
    </div>
    <div class="card-body ">
        <div class="row">
            <div class="col">
                <label class="radio-container">Creation Date
                    <input type="checkbox" name="rangeType" id="creationDate"  checked="checked"  (click)="rangeTypeClicked(1)">
                    <span class="radio-checkmark"></span>
                  </label>
            </div>

            <div class="col">
                <label class="radio-container">Next Follow-Up Date
                    <input type="checkbox" name="rangeType" id="nextActionDate" (click)="rangeTypeClicked(2)">
                    <span class="radio-checkmark"></span>
                  </label>
            </div>


        </div>
        <div class="row">

            <button mat-button *ngFor="let preset of presets " (click)="selectPreset(preset.range) " class="col-sm-4 col-4 m-auto btn-default" style="text-align: left;font-size: smaller;border: 1px solid rgb(180, 160, 226);">
              {{ preset.label }}
            </button>

        </div>
        <div class="wrapper">
            <mat-card class="calendar ">
                <mat-calendar #calendar [(selected)]="selectedDateRange" (selectedChange)="rangeChanged($event)" [minDate]="minDate" [maxDate]="maxDate"></mat-calendar>
            </mat-card>
        </div>
    </div>
    <div class="card-footer" style="float: right;padding-right: 30px;">
        <button type="button" class="btn btn-primary block" (click)="ResetClicked()">Reset</button>
        <button type="button" class="btn btn-info block" (click)="ApplyClicked()">Apply</button>
    </div>
</div>