import { Component, OnInit } from '@angular/core';
import { LoaderService } from './loaderService';
import { Router,Event } from '@angular/router';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {

  loadingprogress: boolean = false
  progressValue: number = 0;
  stopProgress: boolean;
  progressColor: string;
  progressTimer: NodeJS.Timeout;
  width: number = 10;
  constructor(private loaderService: LoaderService, private router: Router) {
    this.loaderService.isLoading.subscribe((v) => {
      this.loadingprogress = v;
    });

    router.events.subscribe((event: Event) => {
      this.navigationObserver(event);
    });
  }

  ngOnInit() {
   
  }
  private loading(): void {
    // if (this.progressValue >= window.innerHeight || this.stopProgress) {
    //   // clearInterval(this.progressTimer);
    //   this.progressValue=0;
    // } else {
    //   this.progressValue++;
    // }
    // document.getElementById("divbar").style.width = this.progressValue + "px"
  }

  private navigationObserver(event: Event): void {

    // if (event instanceof NavigationStart) {
    //   this.progressTimer = setInterval(() => {
    //     this.loading();
    //   }, 100);
    // }
    // if (event instanceof NavigationEnd) {
    //   this.progressValue = window.innerWidth;
    //   this.stopProgress = true;
    // }
  }
}
