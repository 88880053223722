import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TblAssignLeadTo } from '../../models/assignleadto';
import { UserIdAndName } from '../../models/useridandname';
import { UserServices } from '../../services/userservices';
import { HttpErrorResponse } from '@angular/common/http';
import { LeadService } from '../../services/lead.services';
import { LocalStorageService } from 'src/app/utility/localStorageService';
import { NotificationService } from 'src/app/notification.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-assign-lead-to-other-dialog',
  templateUrl: './assign-lead-to-other-dialog.component.html',
  styleUrls: ['./assign-lead-to-other-dialog.component.css']
})
export class AssignLeadToOtherDialogComponent implements OnInit {
  studentName:string
  studentId:number
  counsellorId:number
  userId:number
  assignedTo:number
  assignedToCounsellor:string
  userIdAndName:UserIdAndName[]
  assignLeadTo:TblAssignLeadTo
  userType: string;
  
  constructor(public modalRef: BsModalRef,
    private userService:UserServices,
    private leadService: LeadService,
    private localStorage:LocalStorageService,
    private notifyService : NotificationService,
    private router:Router) { }

  ngOnInit(): void {
    this.userType = this.localStorage.get("userType")
    this.assignLeadTo=new TblAssignLeadTo();
    setTimeout(() => {
      this.userService.getUsersActive()
      .subscribe(
        resp=>{
          this.userIdAndName=resp;
        },
        (error: HttpErrorResponse) => {
         
        });
    }, 500);
  }
  updateLead()
  {
   this.assignLeadTo.byUserId =Number(this.localStorage.get("refNo"))
    this.assignLeadTo.counsellorName =this.assignedToCounsellor
    this.assignLeadTo.fromCounsellorId=this.userId
    this.assignLeadTo.studentId=this.studentId
    this.assignLeadTo.toCounsellorId-this.assignedTo

    this.assignLeadTo.onDate= (new Date()).getFullYear()+"-"+((new Date()).getMonth()+1)+"-"+(new Date()).getDate()
    this.leadService.assignLeadToOtherAndUpdate(this.assignLeadTo)
    .subscribe(
      resp=>{
        if(resp.status=="Success"){
          this.notifyService.showSuccess(resp.descriptio, resp.status)
          window.location.reload()
        }
      },
      (error: HttpErrorResponse) => {
       
      });
  }
  onCounsellorChange(event:any)
  {
    this.assignedToCounsellor=event.target.options[event.target.options.selectedIndex].text;
    this.assignedTo=event.target.value.split(":")[1]
  }
}
