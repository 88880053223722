<div style="height: 500px;">
    <div class="card-header d-flex flex-row align-items-center justify-content-between">
        <h6 class="font-weight-bold text-primary">Follow-Ups : {{studentName}}</h6>
        <button type="button" class="btn btn-danger" style="float: right;" (click)="modalRef.hide()">X</button>
    </div>
    <div class="card-body">
        <table class="table table-sm">
            <thead>
                <tr class="thead-dark">
                    <th>On Date</th>
                    <th>Status</th>
                    <th>Action</th>
                    <th>Note</th>
                    <th>Next Action</th>

                </tr>
            </thead>
            <tbody style="height: 400px!important;overflow: scroll;" id="myTable">
                <tr *ngFor="let item of notes;let i=index;">
                    <td>{{item.onDate}}</td>
                    <td>{{item.status}}</td>
                    <td>{{item.action}}</td>
                    <td>{{item.note}}</td>
                    <td>{{item.nextActionDate}}</td>
                </tr>
            </tbody>
        </table>
    </div>

</div>