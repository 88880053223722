import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TblUserWiseTransaction } from '../shared/models/userwisetransactions';
import { CommonService } from '../shared/services/common.services';
import * as moment from 'moment';
import { LocalStorageService } from '../utility/localStorageService';
@Component({
  selector: 'app-useractivity',
  templateUrl: './useractivity.component.html',
  styleUrls: ['./useractivity.component.css']
})
export class UseractivityComponent implements OnInit {
  onDate:string
  loginForm!:FormGroup;
  TblUserActivity:TblUserWiseTransaction[]=[]
  userType: string;
  constructor(private fb:FormBuilder,private commonService:CommonService,
    private localStorage:LocalStorageService
  ) { }

  ngOnInit(): void {
    this.userType = this.localStorage.get("userType")
    this.TblUserActivity=[]
    this.loginForm = this.fb.group({
      fromDate: [new Date(), Validators.required],
      toDate:[new Date(),[Validators.required]]
    });
    
     this.loginForm.get('fromDate').setValue(this.onDate);
     this.loginForm.get('toDate').setValue(new Date());
  }
  
  viewClick()
  {
    let momentFromoDate = new Date(this.loginForm.get('fromDate').value);
    let formattedFromDate = moment(momentFromoDate).format("YYYY-MM-DD 00:00:00");

    let momenttoDate = new Date(this.loginForm.get('toDate').value);
    let formattedToDate = moment(momenttoDate).format("YYYY-MM-DD 23:59:59");

    this.commonService.getUserWiseTransactionByUserIDAndDate(1,formattedFromDate,formattedToDate).subscribe(resp => {
      if (resp.length > 0) {
        this.TblUserActivity=resp;
        this.TblUserActivity.forEach(element => {
            element.requestDateTime= moment(element.requestDateTime).format("DD-MM-YYYY");
        });
      }
    });
  }
  stopPropagation(event){
    event.stopPropagation();
    }
  searchTable() {
    var term, table;
    term=document.getElementById("myInput") as unknown as HTMLInputElement
    // get term to search
    term = term.value.toLowerCase();
    table =document.getElementById("myTable") as HTMLTableElement
    // get table rows, handle as array.
    table = Array.from(table.rows);

    // filter non-matching rows; these are set to display='none'
    table.filter(function(el) {
        return el.textContent.toLowerCase().indexOf(term) ==
            -1
    }).map(x => x.style.display = "none");

    // filter matching rows; these are set to display =''
    table.filter(function(el) {
        return el.textContent.toLowerCase().indexOf(term) > -1
    }).map(x => x.style.display = "");
}
}
