<div class="container-fluid" id="container-wrapper" *ngIf="userType=='Admin'">
    <div class="d-sm-flex align-items-center justify-content-between mb-4" style="border-bottom:2px solid grey;">
        <div class="col">
            <h1 class="h3 mb-0 text-gray-800">Lead Conditions</h1>
        </div>
        <div class="col" style="float: right;right:-10px;max-width: 280px;">
            <button class="float"><i class="fas fa-plus" (click)="sidePanel(0,false)"></i></button>
        </div>

    </div>
    <div class="row" id="dataArea" #dataArea style="position: fixed;overflow-y: scroll !important;overflow-x: hidden;height: 570px;width: 100%;">
        <div class="div-viewport">
            <div [ngClass]="sidePanelMode == true ? 'grid9' : 'grid12'">
                <table class="table table-sm table-hover " style="padding-right: 20px!important;">
                    <tr style="background-color: blue;color: white;">
                        <th>#</th>
                        <th>Condition Name</th>
                        <th>If Source</th>
                        <th>Then assign to</th>
                        <th>Assign Method</th>
                        <th>Leads at a Time</th>


                        <th></th>
                    </tr>
                    <tbody id="myTable">
                        <tr *ngFor="let item of members | async;let counter=index;">
                            <td>{{counter+1}}</td>
                            <td>{{item.conditionName}}</td>
                            <td>{{item.source}}</td>
                            <td>{{item.userName}}</td>
                            <td>{{item.assignLead}}</td>
                            <td>{{item.leadsAtATime}}</td>
                            <th style="max-width: 20px!important;">
                                <li class="nav-item dropdown no-arrow mx-1" style="right: 12px;margin-top: -10px;">
                                    <a class="nav-link" href="#" id="alertsDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-right shadow " aria-labelledby="alertsDropdown">
                                        <a class="dropdown-item d-flex align-items-center" (click)="sidePanel(item.refNo,true)">
                                            <div class="mr-3">
                                                <div class="icon-circle bg-success">
                                                    <i class="fas fa-edit text-white"></i>

                                                </div>
                                            </div>
                                            <div>
                                                Edit Lead Condition
                                            </div>
                                        </a>
                                        <a class="dropdown-item d-flex align-items-center" (click)="deleteData(item.refNo)">
                                            <div class="mr-3">
                                                <div class="icon-circle bg-warning">
                                                    <i class="fas fa-male text-white"></i>
                                                </div>
                                            </div>
                                            <div>
                                                Delete Lead Condition
                                            </div>
                                        </a>
                                    </div>
                                </li>
                            </th>
                        </tr>
                        <tr>
                            <th colspan="6" *ngIf="(members | async)?.length==0">No data found !</th>
                        </tr>
                    </tbody>
                </table>
            </div>


            <div [ngClass]="sidePanelMode ==true ? 'grid3' : 'grid0'">
                <h3 style="text-align: center;background-color: blue;color: white;margin-top: 0px;">{{boxTitle}}<span style="float: inline-end;padding-bottom: 10px!important;cursor: pointer;"><a (click)="close_clicked();"><i class="fas fa-times"></i></a></span></h3>
                <table>
                    <tr>
                        <th style="padding-left: 10px !important;">Condition Name : </th>
                        <th>
                            <input type="text" [(ngModel)]="model.conditionName" name="conditionName" />
                        </th>
                    </tr>
                    <tr>
                        <th style="padding-left: 10px !important;color: black;">If Source : </th>
                        <th>
                            <select [(ngModel)]="model.leadSourceId" name="leadSourceId">
                  <option value="0" disabled>Select</option>
                  <option *ngFor="let item of sources| async;let counter=index;" value="{{item.refNo}}">{{item.sourceName}}</option>
    
                </select>
                        </th>

                    </tr>
                    <tr>
                        <th style="padding-left: 10px !important;">Then assign to : </th>
                        <th>
                            <select [(ngModel)]="model.userId" name="userId">
                  <option value="0" disabled>Select</option>
                  <option *ngFor="let item of users| async;let counter=index;" value="{{item.refNo}}">{{item.userName}}</option>
    
                </select>
                        </th>

                    </tr>
                    <tr>
                        <th style="padding-left: 10px !important;">Assign Method : </th>
                        <th>
                            <select [(ngModel)]="model.assignLead" name="assignLead" (change)="assignLeadTypeChanged($event)">
              <option value="0" disabled>Select</option>
              <option value="Randomly">Randomly</option>
                 <option value="Sequentially">Sequentially</option>
            </select>
                        </th>
                    </tr>
                    <tr>
                        <th style="padding-left: 10px !important;">Leads at time : </th>
                        <th>
                            <input type="number" [(ngModel)]="model.leadsAtATime" name="leadsAtATime" disabled="disabled" />
                        </th>
                    </tr>




                </table>
                <button class="btn btn-sm btn-primary" (click)="create_edit();" style="float: inline-end;">
              <i class="fas fa-plus"></i> Save
            </button>
            </div>
        </div>
    </div>

</div>