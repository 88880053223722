import { Component, OnInit } from '@angular/core';
import { CommunicationSetupComponent } from 'src/app/masters/communication-setup/communication-setup.component';
import { CommunicationSetupService } from '../../services/communicationsetup.service';
import { ApiResponse } from '../../models/api-response';
import { Observable } from 'rxjs';
import { ChangePassword } from '../../models/change-password';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationService } from 'src/app/notification.service';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-forgot-password-dialog',
  templateUrl: './forgot-password-dialog.component.html',
  styleUrls: ['./forgot-password-dialog.component.css']
})
export class ForgotPasswordDialogComponent implements OnInit {
  otpsent:boolean=false
  changePassword:ChangePassword
  apiResponse: Observable<ApiResponse>;
  constructor(private communicationService:CommunicationSetupService,
    private notifyService : NotificationService,
    public modalRef: BsModalRef) { }

  ngOnInit(): void {
    
    this.changePassword=new ChangePassword();
  }
  close()
  {

  }
  sendOTPByEMailId()
  {
    this.communicationService.sendOTPByEMailId(this.changePassword.email,"Common")
      .subscribe(
        resp=>{
          if(resp.status=="Success")
          {
              this.otpsent=true
              this.notifyService.showSuccess("Otp sent to your email","OTP Sent")
          }
          else
          {
            this.otpsent=false
          }
        },
        (error: HttpErrorResponse) => {
          
        });
    
  }
  updatePassword()
  {
    if(this.changePassword.newPassword.length<6)
      {
        this.notifyService.showError("Password Can't be less than 6 character","Error")
        return;
      }
      if(this.changePassword.newPassword!=this.changePassword.passwordAgain)
      {
        this.notifyService.showError("Password mismatched","Error")
        return;
      }
    this.communicationService.updatePassword(1, this.changePassword.newPassword,this.changePassword.otp)
      .subscribe(
        resp=>{
          if(resp.status=="Success")
          {
              this.notifyService.showSuccess("Password Updated Successfully","Password Update")
          }
          else
          {
            this.notifyService.showError(resp.description,"Error")
          }
        },
        (error: HttpErrorResponse) => {
          
        });
    
  }
}
