<ul class="navbar-nav sidebar sidebar-light accordion" id="accordionSidebar" style="color: black;">
    <hr class="sidebar-divider my-0">
    <li class="nav-item active">
        <a class="nav-link" href="dashboard">
            <i class="fas fa-fw fa-tachometer-alt"></i>
            <span>Dashboard</span></a>
    </li>
    <li class="nav-item">
        <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseForm" aria-expanded="true" aria-controls="collapseForm">
            <i class="fab fa-fw fa-wpforms"></i>
            <span>Admin</span>
        </a>
        <div id="collapseForm" class="collapse" aria-labelledby="headingForm" data-parent="#accordionSidebar" *ngIf="userType=='Admin'">
            <div class="bg-white py-2 collapse-inner rounded" style="text-align: left;">

                <a class="collapse-item" href="/lead-source">Lead Sources</a>
                <a class="collapse-item" href="/users">Users</a>
                <a class="collapse-item" href="/courses">Courses</a>

                <a class="collapse-item" href="/lead-channel">Lead Channel</a>
                <a class="collapse-item" href="/lead-status">Lead Status</a>
                <a class="collapse-item" href="/lead-sub-status">Lead Sub-Status</a>
                <a class="collapse-item" href="/lead-campign">Lead Campign</a>
                <a class="collapse-item" href="/lead-medium">Lead Medium</a>
                <!-- <a class="collapse-item" href="/communication-setup">Communication Setup</a> -->
                <a class="collapse-item" href="/lead-upload">Lead Import/Upload</a>
                <a class="collapse-item" href="/lead-distribution">Lead Distribution</a>
                <a class="collapse-item" href="/raw-list">Raw/API Leads</a>
                <!-- <a class="collapse-item" href="/email-template">EMail Template</a> -->
                <!-- <a class="collapse-item" href="/sms-template">SMS Template</a> -->
                <!-- <a class="collapse-item" href="/whatsapp-template">WhatsApp Template</a> -->
            </div>
        </div>
    </li>
    <li class="nav-item">
        <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseBootstrap" aria-expanded="true" aria-controls="collapseBootstrap">
            <i class="far fa-fw fa-window-maximize"></i>
            <span>Lead Management</span>
        </a>
        <div id="collapseBootstrap" class="collapse" aria-labelledby="headingBootstrap" data-parent="#accordionSidebar">
            <div class="bg-white py-2 collapse-inner rounded" style="text-align: left;">
                <a class="collapse-item" href="/leads" *ngIf="userType!='Third Party'">All Leads</a>
                <a class="collapse-item" href="/my-uploads" *ngIf="userType!='Third Party'">My Uploads</a>
                <a class="collapse-item" href="/upcoming-follow-ups" *ngIf="userType!='Third Party'">Upcoming Follow Ups</a>
                <a class="collapse-item" href="/failed-follow-ups" *ngIf="userType!='Third Party'">Failed Follow Ups</a>
                <a class="collapse-item" href="/source-wise-lead-status" *ngIf="userType=='Third Party' || userType=='Admin'">Source Wise Lead Status</a>
            </div>
        </div>
    </li>

    <li class="nav-item">
        <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseTable" aria-expanded="true" aria-controls="collapseTable">
            <i class="fas fa-fw fa-table"></i>
            <span>Reports</span>
        </a>
        <div id="collapseTable" class="collapse" aria-labelledby="headingTable" data-parent="#accordionSidebar">
            <div class="bg-white py-2 collapse-inner rounded">
                <a class="collapse-item">App Users</a>
                <a class="collapse-item">User Wise Activity</a>
            </div>
        </div>
    </li>
    <li class="nav-item">
        <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#AdvanceSettings" aria-expanded="true" aria-controls="AdvanceSettings">
            <i class="fas fa-fw fa-tachometer-alt"></i>
            <span>Advance Settings</span>
        </a>
        <div id="AdvanceSettings" class="collapse" aria-labelledby="headingTable" data-parent="#accordionSidebar" *ngIf="userType=='Admin'">
            <div class="bg-white py-2 collapse-inner rounded">
                <a class="collapse-item" href="/lead-conditions">Lead Conditions</a>
                <a class="collapse-item" href="/lead-conditions">Backup</a>
                <a class="collapse-item" href="/lead-conditions">Restore</a>
            </div>
        </div>
    </li>
    <li class="nav-item">
        <a class="nav-link">
            <i class="fab fa-android"></i>
            <span>Android App</span>
        </a>
    </li>



</ul>