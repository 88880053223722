import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'src/app/utility/localStorageService';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.css']
})
export class SideMenuComponent implements OnInit {
  userType:string
  constructor(private localStorage:LocalStorageService) { }

  ngOnInit(): void {
   this.userType= this.localStorage.get("userType")

  }

}
