
import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationService } from 'src/app/notification.service';
import { TemplateService } from 'src/app/shared/services/template-service';
import { TblSMSTemplate } from 'src/app/shared/models/sms-template';
import { LocalStorageService } from 'src/app/utility/localStorageService';

@Component({
  selector: 'app-sms-template',
  templateUrl: './sms-template.component.html',
  styleUrls: ['./sms-template.component.css']
})
export class SmsTemplateComponent implements OnInit {

  sidePanelMode: boolean = false;
  isEditing: boolean;
  SMSTemplate: TblSMSTemplate;
  SMSTemplates: Observable<TblSMSTemplate[]>;
  modalRef: BsModalRef;
  editDataAvbl: boolean;
  boxTitle: string;
  edit: any;
  startDate: any
  userType: string;
  constructor(private templateService: TemplateService,
    private modalService: BsModalService,
    private spinnerService: AppComponent,
    private localStorage:LocalStorageService,
    private notifyService: NotificationService) {
  }

  ngOnInit(): void {
    this.userType = this.localStorage.get("userType")
    this.SMSTemplate = new TblSMSTemplate();
    this.getAllData();
    this.startDate = new Date()
  }

  getAllData() {
    this.SMSTemplates = this.templateService.getSMSTemplate();
  }
  sidePanel(id, isEditing) {
    this.sidePanelMode = true
    this.edit = isEditing
    if (isEditing) {
      this.boxTitle = "Edit Template";

      this.templateService.getSMSTemplateById(id)
        .subscribe(
          resp => {
            this.SMSTemplate = resp;
          },
          (error: HttpErrorResponse) => {
            this.editDataAvbl = false;
          });
    }
    else {
      this.boxTitle = "New Template";
      this.SMSTemplate = new TblSMSTemplate();
    }
  }


  //Editing Data
  validateForm(): boolean {

    if (!this.SMSTemplate.templateName) {
      this.notifyService.showError("Can't Be Blank!", "Error");
      return false;
    }
    else {
      return true;
    }
  }

  create_edit() {

    if (!this.validateForm()) {
      return;
    }
    if (this.edit) {
      this.templateService.saveAndUpdateSMSTemplate(this.SMSTemplate).subscribe(resp => {
        if (resp.refNo > 0) {
          this.notifyService.showSuccess("Updated Successfully!", "Success");
          this.sidePanelMode = false
          this.getAllData()
        }
        else {
          this.notifyService.showError(resp.message, "Error")
        }
      });
    }
    else {
      this.templateService.saveAndUpdateSMSTemplate(this.SMSTemplate).subscribe(resp => {
        if (resp.refNo > 0) {
          this.notifyService.showSuccess("Saved Successfully!", "Success");
          this.sidePanelMode = false
          this.getAllData()
        }
        else {
          this.notifyService.showError(resp.message, "Error")
        }
      }, (err) => {
        this.notifyService.showError(err, "Error")
      });
    }
  }
  close_clicked() {
    this.sidePanelMode = false
    this.getAllData();
  }
}
