import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { TblSMSTemplate } from '../../models/sms-template';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TemplateService } from '../../services/template-service';
import { LocalStorageService } from 'src/app/utility/localStorageService';
import { NotificationService } from 'src/app/notification.service';
import { TblLeadStatus } from '../../models/leadstatus';
import { LeadStatusService } from '../../services/leadStatus.services';
import { FCMCommunicationService } from '../../services/FCMCommunicationService';
import { FCMCommunication } from '../../models/fcm-communication';
import { LeadNotes } from '../../models/notes';
import * as moment from 'moment';
import { LeadService } from '../../services/lead.services';

@Component({
  selector: 'app-call-dialog',
  templateUrl: './call-dialog.component.html',
  styleUrls: ['./call-dialog.component.css']
})
export class CallDialogComponent implements OnInit {
  mobileNo: string
  refNo: number
  studentName: string
  callData: CallData
  leadStatus: string
  tblLeadStatus: TblLeadStatus[]
  fCMCommunication: FCMCommunication;
  leadNotes: LeadNotes;
  userType: string;
  constructor(public modalRef: BsModalRef,
    public leadService: LeadStatusService,
    public localStorage: LocalStorageService,
    public notification: NotificationService,
    private _leadservices: LeadService,
    private fcmCommunicationService: FCMCommunicationService
  ) { }

  ngOnInit(): void {
    this.userType = this.localStorage.get("userType")
    this.tblLeadStatus = []
    this.callData = new CallData();
    setTimeout(() => {
      this.callData.toName = this.studentName
      this.callData.toNumber = this.mobileNo
      this.callData.refNo = this.refNo
      this.callData.leadStatus = this.leadStatus
      this.callData.message="Phone call initaied to "+this.studentName
    }, 100);
    this.getSMSTemplate()
  }
  getSMSTemplate() {
    this.leadService.getAllLeadStatus()
      .subscribe(
        resp => {
          this.tblLeadStatus = resp;
        },
        (error: HttpErrorResponse) => {

        });
  }

  initiateCall() {
    if(this.localStorage.get("getOs")!="Android")
    {
    this.fCMCommunication = new FCMCommunication()
    this.fCMCommunication.userId = this.localStorage.get("refNo")
    this.fCMCommunication.messageType = "Call"
    this.fCMCommunication.messageTitle = "Phone Call to " + name
    this.fCMCommunication.messageBody = this.callData.toNumber
    alert(this.fCMCommunication.userId)

    this.fcmCommunicationService.sendFCMMessageByUserId(this.fCMCommunication).subscribe(resp => {
      if (resp.status == "Success") {
        this.notification.showSuccess("Phone Call Initiated!", "Success");
        this.SaveNote();
      }
      else {
        this.notification.showError(resp.description, "Error")
      }
    }, (err) => {
      this.notification.showError(err, "Error")
    });
  }
  else
  {
    this.SaveNote();
  }
  }
  SaveNote() {
    this.leadNotes = new LeadNotes()
    this.leadNotes.studentId = this.callData.refNo
    this.leadNotes.name = this.callData.toName
    this.leadNotes.action = "Call"
    this.leadNotes.onDate = (moment(new Date())).format('YYYY-MM-DD')
    this.leadNotes.nextActionDate = (moment(new Date())).format('YYYY-MM-DD')
    this.leadNotes.status = this.callData.leadStatus
    this.leadNotes.note = this.callData.message
    this.leadNotes.counsellorId = this.localStorage.get("byUser")
    this._leadservices.saveLeadNotes(this.leadNotes).subscribe(resp => {
      if (resp.refNo > 0) {
        if(this.localStorage.get("getOs")=="Android")
          window.location.href = 'tel:' + this.callData.toNumber;
      }
      else {
        this.notification.showError("Something Wrong", "Error")
      }
    }, (err) => {
      this.notification.showError(err, "Error")
    });

  }
}
export class CallData {
  message: string
  refNo: number
  toNumber: string
  toName: string
  leadStatus: string
}