import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { TblTeam } from 'src/app/shared/models/team';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AppComponent } from 'src/app/app.component';
import { environment } from 'src/environments/environment';
import { ViewEditTeamsComponent } from '../view-edit-teams/view-edit-teams.component';
import { TeamsService } from 'src/app/shared/services/teams.services';

@Component({
  selector: 'app-teams-index',
  templateUrl: './teams-index.component.html',
  styleUrls: ['./teams-index.component.css']
})
export class TeamsIndexComponent implements OnInit {
  sources: Observable<TblTeam[]>;
  modalRef: BsModalRef;
  constructor(private _teamServices : TeamsService,
    private modalService: BsModalService ,
    private spinnerService:AppComponent) {
  }

  ngOnInit(): void {
    this.getAllData();
  }

  getAllData(){
    this.spinnerService.spinnerStatus(true);
    this.sources= this._teamServices.getAll(environment.accountId);
    this.spinnerService.spinnerStatus(false);
  }

  openAddNewBox(){
    this.modalRef = this.modalService.show(ViewEditTeamsComponent);
  }

  editBox(id){
    const initialState = {
      isEditing: true ,
      team_id:id
    };
    this.modalRef = this.modalService.show(ViewEditTeamsComponent,{initialState});
  }

  stopPropagation(event){
    event.stopPropagation();
    }
  searchTable() {
    var term, table;
    term=document.getElementById("myInput") as unknown as HTMLInputElement
    // get term to search
    term = term.value.toLowerCase();
    table =document.getElementById("myTable") as HTMLTableElement
    // get table rows, handle as array.
    table = Array.from(table.rows);

    // filter non-matching rows; these are set to display='none'
    table.filter(function(el) {
        return el.textContent.toLowerCase().indexOf(term) ==
            -1
    }).map(x => x.style.display = "none");

    // filter matching rows; these are set to display =''
    table.filter(function(el) {
        return el.textContent.toLowerCase().indexOf(term) > -1
    }).map(x => x.style.display = "");
}
}
