<div class="container-fluid" id="container-wrapper" *ngIf="userType=='Admin'">
    <div class="d-sm-flex align-items-center justify-content-between mb-4" style="border-bottom:2px solid grey;">
        <h1 class="h3 mb-0 text-gray-800">Users</h1>
    </div>
    <div class="row div-viewport">
        <div [ngClass]="sidePanelMode == true ? 'grid9' : 'grid12'">
            <table class="table table-sm  table-hover " style="padding-right: 20px!important;">
                <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Mobile No</th>
                    <th>E-Mail/User ID</th>
                    <th>User Type</th>
                    <th>Access Source</th>
                    <th>Valid Till</th>
                    <th>Status</th>
                    <th></th>
                </tr>
                <tbody id="myTable">
                    <tr *ngFor="let item of users | async;let counter=index;">
                        <td>{{counter+1}}</td>
                        <td>{{item.userName}}</td>
                        <td>{{item.mobileNo}}</td>
                        <td>{{item.emailId}}</td>
                        <td>{{item.userType}}</td>
                        <td>{{item.accessSource}}</td>
                        <td>{{item.tillDate}}</td>
                        <td>{{item.currentStatus}}</td>
                        <th style="max-width: 20px!important;">
                            <li class="nav-item dropdown no-arrow mx-1" style="right: 12px;" *ngIf="item.emailId!='babuli.sahubbsr@gmail.com'">
                                <a class="nav-link" href="#" id="alertsDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                                </a>

                                <div class="dropdown-menu dropdown-menu-right shadow " aria-labelledby="alertsDropdown">
                                    <a class="dropdown-item d-flex align-items-center" (click)="sidePanel(item.refNo,true)">
                                        <div class="mr-3">
                                            <div class="icon-circle bg-success">
                                                <i class="fas fa-edit text-white"></i>

                                            </div>
                                        </div>
                                        <div>
                                            Edit User
                                        </div>
                                    </a>
                                    <a class="dropdown-item d-flex align-items-center" (click)="deleteClick(item.refNo)">
                                        <div class="mr-3">
                                            <div class="icon-circle bg-warning">
                                                <i class="fas fa-male text-white"></i>
                                            </div>
                                        </div>
                                        <div>
                                            Delete User
                                        </div>
                                    </a>
                                </div>
                            </li>
                        </th>
                    </tr>
                    <tr>
                        <th colspan="8" *ngIf="(users | async)?.length==0">No data found !</th>
                    </tr>
                </tbody>
            </table>
        </div>
        <div [ngClass]="sidePanelMode ==true ? 'grid3' : 'grid0'">
            <h3 style="text-align: center;background-color: blue;color: white;margin-top: 0px;">{{boxTitle}}<span style="float: inline-end;padding-bottom: 10px!important;cursor: pointer;"><a (click)="close_clicked();"><i class="fas fa-times"></i></a></span></h3>
            <table>
                <tr>
                    <th style="padding-left: 10px !important;">Name : </th>
                    <th>
                        <input type="text" [(ngModel)]="model.userName" name="userName" />
                    </th>
                </tr>

                <tr>
                    <th style="padding-left: 10px !important;">Mobile No : </th>
                    <th>
                        <input type="text" [(ngModel)]="model.mobileNo" name="mobileNo" />
                    </th>
                </tr>
                <tr>
                    <th style="padding-left: 10px !important;">Email/User Id : </th>
                    <th>
                        <input type="email" [(ngModel)]="model.emailId" name="emailId" />
                    </th>
                </tr>
                <tr>
                    <th style="padding-left: 10px !important;">Password : </th>
                    <th>
                        <input type="password" [(ngModel)]="model.password" name="password" />
                    </th>
                </tr>
                <tr>
                    <th style="padding-left: 10px !important;">User Type : </th>
                    <th>
                        <select [(ngModel)]="model.userType" name="userType">
                            <option value="0">Select</option>
                            <option value="Admin">Admin</option>
                            <option value="Counsellor">Counsellor</option>
                            <option value="Third Party">Third Party</option>
                          </select>
                    </th>
                </tr>

                <tr>
                    <th style="padding-left: 10px !important;">Valid Till Date : </th>
                    <th>
                        <input type="date" placeholder="Valid Till Date" [(ngModel)]="model.tillDate" name="tillDate">
                    </th>
                </tr>
                <tr>
                    <th style="padding-left: 10px !important;">Show Me Leads Before : </th>
                    <th>
                        <input type="text" size="3" placeholder="Show me Leads Before " [(ngModel)]="model.showMeUpcomingEventsOf" name="showMeUpcomingEventsOf">Days
                    </th>
                </tr>
                <tr>
                    <th style="padding-left: 10px !important;">Status</th>
                    <th>
                        <select [(ngModel)]="model.currentStatus" name="currentStatus">
              <option value="0"  disabled>Select</option>
              <option value="Active">Active</option>
              <option value="In active">In active</option>
            </select>
                    </th>
                </tr>
                <tr>
                    <th style="padding-left: 10px !important;">Access Source</th>
                    <th>
                        <select [(ngModel)]="model.accessSource" name="accessSource">
              <option value="0" disabled>Select</option>
              <option value="All">All</option>
              <option *ngFor="let item of leadSources| async;let counter=index;" value="{{item.sourceName}}">{{item.sourceName}}</option>

            </select>
                    </th>
                </tr>
            </table>
            <button class="btn btn-sm btn-primary" (click)="create_edit();" style="float: inline-end;">
              <i class="fas fa-plus"></i> Save
            </button>
        </div>
    </div>
    <button class="float"><i class="fas fa-plus" (click)="sidePanel(0,false)"></i></button>
</div>