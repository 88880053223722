<div>
    <div class="card-header d-flex flex-row align-items-center justify-content-between" style="background-color: rgb(83, 204, 204);">
        <h6 class="font-weight-bold text-primary">Lead Details</h6>
        <button type="button" class="btn btn-danger" style="float: right;" (click)="modalRef.hide()">X</button>
    </div>
    <div class="card-body" style="height: 480px!important;overflow-y: scroll;">
        <form>
            <div class="form-group row" style="display: none;">
                <label class="col-sm-12" for="inputEmail3">Ref No</label>
                <div class="col-sm-12">
                    <input type="text" class="form-control" name="refNo" value="{{refNo}}">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-12" for="inputEmail3">Student Name</label>
                <div class="col-sm-12">
                    <input type="text" class="form-control" name="studentName" [(ngModel)]="model.studentName">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-12" for="inputEmail3">Address</label>
                <div class="col-sm-12">
                    <input type="text" class="form-control" name="address" [(ngModel)]="model.address">
                </div>
            </div>
            <div class="form-group row" style="margin-bottom: -5px;">
                <label class="col" for="inputEmail3">City Name</label>
                <label class="col" for="inputEmail3">State Name</label>
            </div>
            <div class="form-group row">
                <div class="col">
                    <input type="text" class="form-control" name="cityName" [(ngModel)]="model.cityName">
                </div>
                <div class="col">
                    <input type="text" class="form-control" name="stateName" [(ngModel)]="model.stateName">
                </div>
            </div>
            <div class="form-group row" style="margin-bottom: -5px;">
                <label class="col" for="inputEmail3">Mobile No</label>
                <label class="col" for="inputEmail3">Alternate No</label>
            </div>
            <div class="form-group row">
                <div class="col">
                    <input type="text" class="form-control" name="mobileNo" [(ngModel)]="model.mobileNo">
                </div>
                <div class="col">
                    <input type="text" class="form-control" name="alternateNo" [(ngModel)]="model.alternateNo">
                </div>
            </div>
            <div class="form-group row" style="margin-bottom: -5px;">
                <label class="col" for="inputEmail3">EMail</label>
                <label class="col" for="inputEmail3">Source</label>
            </div>
            <div class="form-group row">
                <div class="col">
                    <input type="text" class="form-control" name="emailId" [(ngModel)]="model.emailId">
                </div>
                <div class="col">
                    <select [(ngModel)]="model.source" name="source" class="form-control">
                        <option *ngFor="let stat of leadSourcs| async" [ngValue]="stat.sourceName">{{stat.sourceName}}</option>
                    </select>
                </div>
            </div>
            <div class="form-group row" style="margin-bottom: -5px;">
                <label class="col" for="inputEmail3">Status</label>
                <label class="col" for="inputEmail3">Sub-Status </label>

            </div>
            <div class="form-group row">
                <div class="col">
                    <select [(ngModel)]="model.status" name="status" class="form-control">
                        <option *ngFor="let stat of leadStatus| async" [ngValue]="stat.statusName">{{stat.statusName}}</option>
          
                      </select>
                </div>
                <div class="col">
                    <select [(ngModel)]="model.subStatus" name="subStatus" class="form-control">
                        <option *ngFor="let stat of leadSubStatus| async" [ngValue]="stat.subStatusName">{{stat.subStatusName}}</option>
                    </select>
                </div>

            </div>

            <div class="form-group row" style="display: none;">
                <label class="col" for="inputEmail3">Course Name</label>
            </div>
            <div class="form-group row" style="display: none;">
                <div class="col">
                    <input type="text" class="form-control" name="courseName" [(ngModel)]="model.courseName">
                </div>
                <div class="col">

                </div>
            </div>






            <div class="form-group row" style="display: none;">
                <label class="col-sm-12" for="inputEmail3">User Name</label>
                <div class="col-sm-12">
                    <input type="text" class="form-control" name="assignToCounsellor" [(ngModel)]="model.assignToCounsellor">
                </div>
            </div>
        </form>
    </div>

    <div class="card-footer">
        <button type="button" class="btn btn-primary block" (click)="saveAndUpdateLead()">Save Lead</button>
    </div>
</div>