import { Component, OnInit } from '@angular/core';
import { ChangePassword } from '../../models/change-password';
import { NotificationService } from 'src/app/notification.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TblUser } from '../../models/user';
import { HttpErrorResponse } from '@angular/common/http';
import { UserServices } from '../../services/userservices';
import { LocalStorageService } from 'src/app/utility/localStorageService';

@Component({
  selector: 'app-profile-dialog',
  templateUrl: './profile-dialog.component.html',
  styleUrls: ['./profile-dialog.component.css']
})
export class ProfileDialogComponent implements OnInit {
  user: TblUser;
  userType: string;

  constructor(
    private localStorage:LocalStorageService,
    private userservices : UserServices,
    private notifyService : NotificationService,
    public modalRef: BsModalRef) { }

  ngOnInit(): void {
    this.userType = this.localStorage.get("userType")
    this.user=new TblUser();
    this.userservices.getUserByUserId(Number(this.localStorage.get("refNo")))
      .subscribe(
        resp=>{
          this.user=resp;
        },
        (error: HttpErrorResponse) => {
          
        });
  }
  
  updateProfile()
  {
    this.userservices.saveAndUpdate(this.user)
      .subscribe(
        resp=>{
          if(resp.userName==this.user.userName)
          {
              this.notifyService.showSuccess("Profile Updated Successfully","Update")
          }
        },
        (error: HttpErrorResponse) => {
          
        });
  }
}
