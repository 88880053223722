import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UseeActivityStatus } from '../shared/models/useractivitystatus';
import { UserActivityService } from '../shared/services/UserActivityService';
import { LocalStorageService } from '../utility/localStorageService';
import { BsModalRef, BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { ForgotPasswordDialogComponent } from '../shared/dialogs/forgot-password-dialog/forgot-password-dialog.component';
import { NotificationService } from '../notification.service';
import { HttpErrorResponse } from '@angular/common/http';
import { UserLogin } from '../shared/models/user-login';
import { environment } from 'src/environments/environment';
import {loginSuccess} from '../../jsFunctions.js'
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  userLogin: UserLogin;
  submitted: boolean = false
  loginForm!: FormGroup;
  showUserIdError: boolean = false;
  showPasswordError: boolean = false
  useractivitystatus: UseeActivityStatus;
  isRememberChecked: boolean = false

  modalRef: BsModalRef;
  userType: string;


  constructor(private fb: FormBuilder, private router: Router,
    private userActivityService: UserActivityService,
    private localStorage: LocalStorageService,
    private modalService: BsModalService,
    private notifyService: NotificationService,
  ) { }

  ngOnInit(): void {
    this.userType = this.localStorage.get("userType")
    this.userActivityService.getCountries()
      .subscribe(
        resp => {
          console.log(resp)
        });


    this.loginForm = this.fb.group({
      remember: [false, Validators.nullValidator],
      userName: ['', [Validators.required, Validators.email]],
      passWord: ['', [Validators.required, Validators.minLength(6)]]
    });
    if (this.localStorage.get("email") != "NA" && this.localStorage.get("password") != "NA") {
      this.loginForm.get('userName').setValue(this.localStorage.get("email"));
      this.loginForm.get('passWord').setValue(this.localStorage.get("password"));
      this.loginForm.get('remember').setValue(true)
      if (this.localStorage.get("logout") == "false") {
        this.localStorage.set("logout", "false")
        this.onSubmit();
      }

    }

  }

  get loginData() {
    return this.loginForm.controls;
  }
  onChange(event: Event): void {
    this.isRememberChecked = event.target['checked'];
  }
  onSubmit() {

    this.userLogin = new UserLogin()
    this.submitted = true;

    if (this.loginForm.valid) {

      this.userLogin.userName = this.loginForm.get('userName')?.value;
      this.userLogin.password = this.loginForm.get('passWord')?.value;

      this.userActivityService.getUserAllowedOrNotByEmailIdAndPassword(this.userLogin)
        .subscribe(
          resp => {
            if (resp == null) {
              this.notifyService.showError("Wrong User Name/Password", "Error")
              return;
            }
            this.useractivitystatus = resp

            this.localStorage.set("userName", this.useractivitystatus.userName);
            this.localStorage.set("refNo", this.useractivitystatus.refNo);
            this.localStorage.set("userType", this.useractivitystatus.userType);
            this.localStorage.set("showMeUpcomingEventsOf", this.useractivitystatus.showMeUpcomingEventsOf.toString());
            this.localStorage.set("showMeLeadsFromDate", this.useractivitystatus.showMeLeadsFromDate);
            this.localStorage.set("sendWhatsAppFrom", this.useractivitystatus.sendWhatsAppFrom);
            this.localStorage.set("sendSMSFrom", this.useractivitystatus.sendSMSFrom);
            this.localStorage.set("sendEMailFrom", this.useractivitystatus.sendEMailFrom);
            this.localStorage.set("accessSource", this.useractivitystatus.accessSource);

            if (this.loginForm.get('remember').value) {
              this.localStorage.set("email", this.userLogin.userName);
              this.localStorage.set("password", this.userLogin.password);
            }
            else {
              this.localStorage.set("email", "NA");
              this.localStorage.set("password", "NA");
            }
            if (this.useractivitystatus.userType == "Admin" || this.useractivitystatus.userType == "Counsellor")
              this.router.navigateByUrl("/dashboard")
            // else if (this.useractivitystatus.userType == "Counsellor")
            //   this.router.navigateByUrl("user-dashboard")
            else
              this.router.navigateByUrl("/source-wise-lead-status")
              
            this.clear();
            if(this.getOS()=="Android")
              loginSuccess(this.userLogin.userName)
          },
          (error: HttpErrorResponse) => {

          });
    }
    else {
      this.notifyService.showError("Please Enter User Name and Valid Password", "Error")
    }
  }

  clear() {
    this.loginForm.patchValue({
      userName: '',
      passWord: ''
    });
  }
  openModal() {
    const initialState = {
      data: "Test Data"
    };
    this.modalRef = this.modalService.show(ForgotPasswordDialogComponent, { class: 'modal-dialog modal-dialog-centered modal-sm' });
  }
  closeNotesForm() {
    document.getElementById("myFilterForm").style.display = "none";
  }
  openFilterForm() {
    document.getElementById("myFilterForm").style.display = "block";
  }
  downloadFile(url: string) {
    var url = url + "?" + Math.random();
    window.location.href = url;
    this.closeNotesForm();
  }
  getOS() {
    var uA = navigator.userAgent || navigator.vendor;
    if ((/iPad|iPhone|iPod/.test(uA)) || (uA.includes('Mac') && 'ontouchend' in document)) return 'iOS';

    var i, os = ['Windows', 'Android', 'Unix', 'Mac', 'Linux', 'BlackBerry'];
    for (i = 0; i < os.length; i++) if (new RegExp(os[i], 'i').test(uA)) return os[i];
  }
}