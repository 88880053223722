<div>
    <div class="card-header d-flex flex-row align-items-center justify-content-between">
        <h6 class="font-weight-bold text-primary">Activity Log : {{userName}}</h6>
        <button type="button" class="btn btn-danger" style="float: right;" (click)="modalRef.hide()">X</button>
    </div>
    <div class="card-body">
        <div style="height: 300px;overflow-y: scroll;overflow-x: scroll;">
            <table class="table table-strped">
                <tr>
                    <th style="width: 20%;">Date Time</th>
                    <th style="width: 10%;">Method</th>
                    <th style="width: 10%;">Status</th>
                    <th style="width: 25%;">Action</th>
                </tr>
                <tbody id="myTable">
                    <tr *ngFor="let data of tblUserWiseTransaction">
                        <td style="width: 20%;">{{data.requestDateTime }}</td>
                        <td style="width: 10%;">{{data.method}}</td>
                        <td style="width: 10%;">{{data.status}}</td>
                        <td style="width: 25%;">{{data.operation}}</td>

                    </tr>
                </tbody>
            </table>
        </div>

    </div>

</div>