import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationService } from 'src/app/notification.service';
import { DatePipe } from '@angular/common';
import { TblLeadCampaign } from 'src/app/shared/models/leadcampaign';
import { LeadCampaignService } from 'src/app/shared/services/leadcampaign.services';
import { ConfirmDialogComponent } from 'src/app/shared/dialogs/confirm-dialog/confirm-dialog.component';
import { LocalStorageService } from 'src/app/utility/localStorageService';

@Component({
  selector: 'app-Campaign',
  templateUrl: './lead-campaign.component.html',
  styleUrls: ['./lead-campaign.component.css']
})
export class LeadCampaignComponent implements OnInit {
  sidePanelMode:boolean=false;
  isEditing:boolean;
  model: TblLeadCampaign;
  members: Observable<TblLeadCampaign[]>;
  modalRef: BsModalRef;
  editDataAvbl: boolean;
  boxTitle: string;
  edit: any;
  startDate:any
  userType: string;
  constructor(private _leadCampaignervice : LeadCampaignService,
    private modalService: BsModalService,
    private localStorage:LocalStorageService,
    private spinnerService: AppComponent ,private notifyService : NotificationService) {
  }

  ngOnInit(): void {
    this.userType = this.localStorage.get("userType")
    this.model=new TblLeadCampaign();
    this.getAllData();
    this.startDate=new Date()
  }
  ngAfterViewInit() {
    //this.dt1.datetimeFormat = {"yyyy-mm-dd"};
  }
  getAllData(){
    this.members= this._leadCampaignervice.getAllLeadCampaign();
  }
  deleteClick(id:any)
  {
    let modalConfig: any = { animated: true, keyboard: true, backdrop: true, ignoreBackdropClick: false };
    const initialState = {mode:'Delete',  module: 'Lead Campaign', refNo:id};
    this.modalService.show(ConfirmDialogComponent, Object.assign({}, modalConfig, { class: 'modal-sm', initialState }))
  }
  sidePanel(id,isEditing){
    this.sidePanelMode=true
    this.edit=isEditing
    if(isEditing)
    {
      this.boxTitle="Edit Lead Campaign";
      
       this._leadCampaignervice.getLeadCampaignById(id)
      .subscribe(
        resp=>{
          this.model=resp;
        },
        (error: HttpErrorResponse) => {
          this.editDataAvbl=false;
        });
      }
    else{
      this.boxTitle="New Lead Campaign";
      this.model=new TblLeadCampaign();
    }
  }


  //Editing Data
  validateForm():boolean{
    
    if(!this.model.campaignName){
      this.notifyService.showError("Campaign Name can not be empty!", "Error");
      return false;
    }
    else {
      return true;
    }
  }

  create_edit(){

    if(!this.validateForm()){
      return;
    }
    if(this.edit){
           this._leadCampaignervice.saveAndUpdateLeadCampaign(this.model).subscribe(resp=>{
        if(resp.refNo>0){
          this.notifyService.showSuccess("Updated Successfully!", "Success");
          this.sidePanelMode=false
          this.getAllData()
        }
        else{
          this.notifyService.showError(resp.message, "Error")
        }
      });
    }
    else{
      this._leadCampaignervice.saveAndUpdateLeadCampaign(this.model).subscribe(resp=>{
        if(resp.refNo>0){
          this.notifyService.showSuccess("Saved Successfully!", "Success");
          this.sidePanelMode=false
          this.getAllData()
        }
        else{
          this.notifyService.showError(resp.message, "Error")
        }
      },(err) => {
        this.notifyService.showError(err, "Error")
      });
    }
  }
  close_clicked()
  {
    this.sidePanelMode=false
    this.getAllData();
  }
  stopPropagation(event){
    event.stopPropagation();
    }
  searchTable() {
    var term, table;
    term=document.getElementById("myInput") as unknown as HTMLInputElement
    // get term to search
    term = term.value.toLowerCase();
    table =document.getElementById("myTable") as HTMLTableElement
    // get table rows, handle as array.
    table = Array.from(table.rows);

    // filter non-matching rows; these are set to display='none'
    table.filter(function(el) {
        return el.textContent.toLowerCase().indexOf(term) ==
            -1
    }).map(x => x.style.display = "none");

    // filter matching rows; these are set to display =''
    table.filter(function(el) {
        return el.textContent.toLowerCase().indexOf(term) > -1
    }).map(x => x.style.display = "");
}
}
