<div class="container-fluid" id="container-wrapper" style="background-color: white;" *ngIf="userType=='Admin'">
    <div style="border-bottom:2px solid grey;width: 100%;">
        <div class="row" style="margin-top: -10px;">
            <div class="col">
                <h1 class="h3 mb-0 text-gray-800">Lead Distribution</h1>
            </div>
            <div class="col" style="text-align: right;padding-top: 10px;">
                <button mat-icon-button matTooltip="New Leads" [matMenuTriggerFor]="search">
                    <mat-icon [ngStyle]="{'color':'#27682'}">search</mat-icon>
                </button>
                <mat-menu #search [overlapTrigger]="false" yPosition="below" style=" padding-top: 0px !important; padding-bottom: 0px !important; line-height:35px; height:35px;">

                    <form (click)="stopPropagation($event)" style="min-height: 40px;">
                        <input id='myInput' (keyup)='searchTable()' type='text' style="width:200px;height: 20px;">
                    </form>
                    <p style="padding-left: 10px;">Enter Any Text To Serach</p>
                </mat-menu>
            </div>
        </div>

    </div>











    <div class="row" id="dataArea" #dataArea style="margin-top: 10px!important;overflow-y: scroll !important;overflow-x: hidden;height: 570px;">
        <div class="div-viewport">
            <div [ngClass]="sidePanelMode == true ? 'grid9' : 'grid12'">
                <table class="table table-sm" style="padding-right: 20px!important;">
                    <tr class="thead-dark">
                        <th>#</th>
                        <th>Student Name</th>
                        <th>Mobile No</th>
                        <th>E-Mail</th>
                        <th>Source</th>
                        <th>Status</th>
                        <th>Sub Status</th>
                        <th>Assigned To</th>
                        <th>Assign</th>
                    </tr>
                    <tbody id="myTable">
                        <tr *ngFor="let item of pagedItems;let counter=index;" style="vertical-align: top;">
                            <td>{{counter+1}}</td>
                            <td>{{item.studentName}}</td>
                            <td>{{item.mobileNo}}</td>
                            <td>{{item.email}}</td>
                            <td>{{item.source}}</td>

                            <td>{{item.status}}</td>
                            <td>{{item.substatus}}</td>
                            <td>{{item.counsellor}}</td>
                            <th style="max-width: 20px!important;">
                                <li class="nav-item dropdown no-arrow mx-1" style="right: 12px;margin-top: -10px;">
                                    <a class="nav-link" href="#" id="alertsDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-right shadow " aria-labelledby="alertsDropdown">
                                        <a class="dropdown-item d-flex align-items-center" (click)="sidePanel(item.refNo,true)">
                                            <div class="mr-3">
                                                <div class="icon-circle bg-success">
                                                    <i class="fas fa-edit text-white"></i>

                                                </div>
                                            </div>
                                            <div>
                                                Assign Lead
                                            </div>
                                        </a>

                                    </div>
                                </li>
                            </th>
                        </tr>
                        <tr>
                            <th colspan="4" *ngIf="(members | async)?.length==0">No data found !</th>
                        </tr>
                    </tbody>
                </table>
                <div style="position: absolute;bottom: 30px;right: 20px;">
                    <ul *ngIf="pager.pages && pager.pages.length" class="pagination">
                        <li [ngClass]="{disabled:pager.currentPage === 1}">
                            <a (click)="setPage(1)">First</a>
                        </li>
                        <li [ngClass]="{disabled:pager.currentPage === 1}">
                            <a (click)="setPage(pager.currentPage - 1)">Prev</a>
                        </li>
                        <li *ngFor="let page of pager.pages" [ngClass]="{active:pager.currentPage === page}">
                            <a (click)="setPage(page)">{{page}}</a>
                        </li>
                        <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                            <a (click)="setPage(pager.currentPage + 1)">Next</a>
                        </li>
                        <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                            <a (click)="setPage(pager.totalPages)">Last</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div [ngClass]="sidePanelMode ==true ? 'grid3' : 'grid0'">
                <h3 style="text-align: center;background-color: blue;color: white;margin-top: 0px;">{{boxTitle}}<span style="float: inline-end;padding-bottom: 10px!important;cursor: pointer;"><a (click)="close_clicked();"><i class="fas fa-times"></i></a></span></h3>
                <table>
                    <tr>
                        <th style="padding-left: 10px !important;">Assign Lead To : </th>
                        <th>

                            <select [(ngModel)]="assignedData.refNo" name="refNo">
                                <option value="0" disabled selected>Select any</option>
                                <option  *ngFor="let item of counsellors" value="{{item.refNo}}" > {{item.userName}}</option>
                                
                            </select>
                        </th>
                        <th>

                        </th>
                    </tr>


                </table>
                <button class="btn btn-sm btn-primary" (click)="create_edit();" style="float: inline-end;">
                <i class="fas fa-plus"></i> Save
              </button>
            </div>
        </div>
    </div>

</div>