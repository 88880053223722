export class LeadNotes
{
    refNo:number
    name:string
    studentId:number
    onDate:string
    action:string
    status:string
    note:string
    counsellorId:string
    nextActionDate:string
    scheduleTime:string
}