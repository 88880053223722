import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from "src/environments/environment";
import { Observable } from "rxjs/internal/Observable";
import { ExceptionHandler } from "./errorhandler";
import { catchError } from "rxjs/operators";
import { TblLead } from "../models/leads";
import { LeadFilter } from "../models/lead-filter";
import { LeadNotes } from "../models/notes";
import { TblAssignLeadTo } from "../models/assignleadto";
import { CommonHeader } from "./common-header";
import { LocalStorageService } from "src/app/utility/localStorageService";
import { RawLeadsFromThirdParty } from "./raw-data-from-third-party";
import { LeadFollowUps } from "../models/LeadFollowUps";
import { DashboardStatusCountPage } from "../models/dashboardpagecount";
import { LeadCountBySource } from "../models/LeadCountBySource";
import { TopPerformingLeadCountChannel } from "../models/TopPerformingLeadCountChannel";
import { StatusWiseLeadCount } from "../models/StatusWiseLeadCount";
import { CounsellorWiseTotalLeads } from "../models/CounsellorWiseTotalLeads";
import { UserAndLeadWiseLeads } from "../models/UserAndLeadWiseLeads";
import { ActionWiseLeadCount } from "../models/ActionWiseLeadCount";
import { CommunicationCount } from "../models/CommunicationCount";
import { DashboardAllData } from "../models/DashboardAllData";

@Injectable({
  providedIn: "root",
})
export class LeadService {
  getDashBoardAllDataByDateRangeAndUser(fromDate: string, toDate: string, userId: string): Observable<DashboardAllData[]> {
    this.localStorage.set("method","Read")
    this.localStorage.set("operation","Get First Lead Owner Analysis")
    const endPoint = `${this.serviceRoot}/getDashBoardAllDataByDateRangeAndUser/${fromDate}/${toDate}/${userId}`;
    return this.http.get<DashboardAllData[]>(endPoint, this.options)
    .pipe(catchError(this.ehandler.errorHandler));
  }
  getEMailSentCallsAndWhatsApps(fromDate: string) : Observable<CommunicationCount> {
    this.localStorage.set("method","Read")
    this.localStorage.set("operation","Get First Lead Owner Analysis")
    const endPoint = `${this.serviceRoot}/getEMailSentCallsAndWhatsApps/${fromDate}`;
    return this.http.get<CommunicationCount>(endPoint, this.options)
    .pipe(catchError(this.ehandler.errorHandler));
  }
  
  
  
  
  
  
  
  
  private serviceRoot = `${environment.serviceRoot}`;
  commonHeader:CommonHeader=new CommonHeader() 
  private options=this.commonHeader.options;
  constructor(
    private http: HttpClient,
    private ehandler:ExceptionHandler,
    private localStorage:LocalStorageService
  ) { 
  }

  getActionWiseLeadCount(fromDate: string,toDate:string,counsellor:string,rangeType:string) : Observable<ActionWiseLeadCount[]> {
    this.localStorage.set("method","Read")
    this.localStorage.set("operation","Get First Lead Owner Analysis")
    const endPoint = `${this.serviceRoot}/getActionWiseLeadCount/${fromDate}/${toDate}/${counsellor}/${rangeType}`;
    return this.http.get<ActionWiseLeadCount[]>(endPoint, this.options)
    .pipe(catchError(this.ehandler.errorHandler));
  }
  
  getFirstLeadOwnerAnalysis(fromDate: string,toDate:string) : Observable<UserAndLeadWiseLeads[]> {
    this.localStorage.set("method","Read")
    this.localStorage.set("operation","Get First Lead Owner Analysis")
    const endPoint = `${this.serviceRoot}/getFirstLeadOwnerAnalysis/${fromDate}/${toDate}`;
    return this.http.get<UserAndLeadWiseLeads[]>(endPoint, this.options)
    .pipe(catchError(this.ehandler.errorHandler));
  }
  getTopReasonsForClosedLeads(fromDate: string) : Observable<StatusWiseLeadCount[]> {
    this.localStorage.set("method","Read")
    this.localStorage.set("operation","Get Top Reasons For Closed Leads")
    const endPoint = `${this.serviceRoot}/getTopReasonsForClosedLeads/${fromDate}`;
    return this.http.get<StatusWiseLeadCount[]>(endPoint, this.options)
    .pipe(catchError(this.ehandler.errorHandler));
  }
  getCounsellorWiseTotalLeads(fromDate: string) : Observable<CounsellorWiseTotalLeads[]> {
    this.localStorage.set("method","Read")
    this.localStorage.set("operation","Get Lead Count By Counsellor")
    const endPoint = `${this.serviceRoot}/getCounsellorWiseTotalLeads/${fromDate}`;
    return this.http.get<CounsellorWiseTotalLeads[]>(endPoint, this.options)
    .pipe(catchError(this.ehandler.errorHandler));
  }
  
  
  getStatusWiseTotalLeads(fromDate: string,toDate:string,counsellor:string,rangeType:string) : Observable<StatusWiseLeadCount[]> {
    this.localStorage.set("method","Read")
    this.localStorage.set("operation","Get Status Wise Total Leads")
    const endPoint = `${this.serviceRoot}/getStatusWiseTotalLeads/${fromDate}/${toDate}/${counsellor}/${rangeType}`;
    return this.http.get<StatusWiseLeadCount[]>(endPoint, this.options)
    .pipe(catchError(this.ehandler.errorHandler));
  }
  getTotalLeadFunnelByStatus(fromDate: string) : Observable<StatusWiseLeadCount[]> {
    this.localStorage.set("method","Read")
    this.localStorage.set("operation","Get Total Lead Funnel By Status")
    const endPoint = `${this.serviceRoot}/getTotalLeadFunnelByStatus/${fromDate}`;
    return this.http.get<StatusWiseLeadCount[]>(endPoint, this.options)
    .pipe(catchError(this.ehandler.errorHandler));
  }
  getTopPerformingLeadCountChannel(fromDate: string): Observable<TopPerformingLeadCountChannel[]> {
    this.localStorage.set("method","Read")
    this.localStorage.set("operation","Get Total Lead Funnel By Status")
    const endPoint = `${this.serviceRoot}/getTopPerformingLeadCountChannel/${fromDate}`;
    return this.http.get<TopPerformingLeadCountChannel[]>(endPoint, this.options)
    .pipe(catchError(this.ehandler.errorHandler));
  }
  getLeadCountBySource(fromDate: string,toDate:string,refNo:string,rangeType:string) : Observable<LeadCountBySource[]> {
    this.localStorage.set("method","Read")
    this.localStorage.set("operation","Get Dashboard Status Count Page")
    const endPoint = `${this.serviceRoot}/getLeadCountBySource/${fromDate}/${toDate}/${refNo}/${rangeType}`;
    return this.http.get<LeadCountBySource[]>(endPoint, this.options)
    .pipe(catchError(this.ehandler.errorHandler));
  }
  getDashboardStatusCountPage(fromDate:string): Observable<DashboardStatusCountPage> {
    this.localStorage.set("method","Read")
    this.localStorage.set("operation","Get Dashboard Status Count Page")
    const endPoint = `${this.serviceRoot}/getDashboardStatusCountPage/${fromDate}`;
    return this.http.get<DashboardStatusCountPage>(endPoint, this.options)
    .pipe(catchError(this.ehandler.errorHandler));
  }
  getAllLeadsByuserId(userId:number,fromDate:string): Observable<TblLead[]> {
    this.localStorage.set("method","Read")
    this.localStorage.set("operation","Get All Leads By User Id")
    const endPoint = `${this.serviceRoot}/getAllLeadsByuserId/${userId}/${fromDate}`;
    return this.http.get<TblLead[]>(endPoint, this.options)
    .pipe(catchError(this.ehandler.errorHandler));
  }
  getAllLeadsWithFollowUpsByuserId(userId:number,fromDate:string,toDate:string): Observable<LeadFollowUps[]> {
    this.localStorage.set("method","Read")
    this.localStorage.set("operation","Get All Leads By User Id with Date Range")
    const endPoint = `${this.serviceRoot}/getAllLeadsWithFollowUpsByuserId/${userId}/${fromDate}/${toDate}`;
    return this.http.get<LeadFollowUps[]>(endPoint, this.options)
    .pipe(catchError(this.ehandler.errorHandler));
  }
  getLeadsById(id:number): Observable<TblLead> {
    this.localStorage.set("method","Read")
    this.localStorage.set("operation","Get All Leads By Id")
    const endPoint = `${this.serviceRoot}/getLeadsById/${id}`;
    return this.http.get<TblLead>(endPoint,  this.options).pipe(catchError(this.ehandler.errorHandler));
  }

  saveAndUpdateLeads(data:TblLead):Observable<any>{
    this.localStorage.set("method","Save/Update")
    this.localStorage.set("operation","Save/Update Leads")
    const endPoint = `${this.serviceRoot}/saveAndUpdateLeads`;
    console.log("Data="+JSON.stringify(data))
    return this.http.post<any>(endPoint, data,this.options).pipe(catchError(this.ehandler.errorHandler));
  }
  saveAllLeads(data:TblLead[]):Observable<any>{
    this.localStorage.set("method","Save/Update")
    this.localStorage.set("operation","Save/Update All Leads")
    const endPoint = `${this.serviceRoot}/saveAllLeads`;
    console.log("Data="+JSON.stringify(data))
    return this.http.post<any>(endPoint, data,this.options).pipe(catchError(this.ehandler.errorHandler));
  }
  getLeadsByStatus(id:string,status:string,fromDate:string,toDate:string,noOfRecPerPage:number): Observable<TblLead[]> {
    this.localStorage.set("method","Read")
    this.localStorage.set("operation","Get All Leads By Status")
    const endPoint = `${this.serviceRoot}/getLeadsByStatus/${id}/${status}/${fromDate}/${toDate}/${noOfRecPerPage}`;
    return this.http.get<TblLead[]>(endPoint,  this.options).pipe(catchError(this.ehandler.errorHandler));
  }

  getAllFilteredLeads(fromDate:string,toDate:string,status:string[],subStatus:string[],state:string[],city:string[],refNo:number): Observable<TblLead[]> {
    this.localStorage.set("method","Read")
    this.localStorage.set("operation","Get All Leads By Filter Applied")
    const endPoint = `${this.serviceRoot}/getAllFilteredLeads/${fromDate}/${toDate}/${status}/${subStatus}/${state}/${city}/${refNo}`;
    return this.http.get<TblLead[]>(endPoint,  this.options).pipe(catchError(this.ehandler.errorHandler));
  }

  getPhoneNoExistOrNot(phoneNo: string) {
    this.localStorage.set("method","Read")
    this.localStorage.set("operation","Get Phone No Exists Or Not")
    const endPoint = `${this.serviceRoot}/getPhoneNoExistOrNot/${phoneNo}`;
    return this.http.get<any>(endPoint,  this.options).pipe(catchError(this.ehandler.errorHandler));
  }
  saveLeadNotes(leadNotes: LeadNotes) :Observable<LeadNotes>{
    this.localStorage.set("method","Save/Update")
    this.localStorage.set("operation","Save/Update Lead Notes")
    const endPoint = `${this.serviceRoot}/saveLeadNotes`;
    console.log("Data="+JSON.stringify(leadNotes))
    return this.http.post<LeadNotes>(endPoint, leadNotes,this.options).pipe(catchError(this.ehandler.errorHandler));
  }

  assignLeadToOtherAndUpdate(assignLeadTo: TblAssignLeadTo):Observable<any>{
    this.localStorage.set("method","Save/Update")
    this.localStorage.set("operation","Assign Lead To Other")
    const endPoint = `${this.serviceRoot}/assignLeadToOtherAndUpdate`;
    console.log("Data="+JSON.stringify(assignLeadTo))
    return this.http.post<any>(endPoint, assignLeadTo,this.options).pipe(catchError(this.ehandler.errorHandler));
  }

  getRawLeadsByBatch(accessSource:string) {
    this.localStorage.set("method","Read")
    this.localStorage.set("operation","Get Raw Leads")
    const endPoint = `${this.serviceRoot}/getRawLeadsByBatch/${accessSource}`;
    return this.http.get<RawLeadsFromThirdParty[]>(endPoint,  this.options).pipe(catchError(this.ehandler.errorHandler));
  }

  ////////////////////////////////////////////////User Dash Board

  getStatusWiseTotalLeadsByDateAndType(fromDate: string,toDate:string,counsellor:string,rangeType:string) : Observable<StatusWiseLeadCount[]> {
    this.localStorage.set("method","Read")
    this.localStorage.set("operation","Get Status Wise Total Leads")
    const endPoint = `${this.serviceRoot}/getStatusWiseTotalLeadsByDateAndType/${fromDate}/${toDate}/${counsellor}/${rangeType}`;
    return this.http.get<StatusWiseLeadCount[]>(endPoint, this.options)
    .pipe(catchError(this.ehandler.errorHandler));
  }
  getLeadCountBySourceAndRange(fromDate: string,toDate:string,refNo:string,rangeType:string) : Observable<LeadCountBySource[]> {
    this.localStorage.set("method","Read")
    this.localStorage.set("operation","Get Dashboard Status Count Page")
    const endPoint = `${this.serviceRoot}/getLeadCountBySourceAndRange/${fromDate}/${toDate}/${refNo}/${rangeType}`;
    return this.http.get<LeadCountBySource[]>(endPoint, this.options)
    .pipe(catchError(this.ehandler.errorHandler));
  }
}
