<div class="bottompane" *ngIf="!androidOS">
    <h6 style="padding-top: 15px;position: fixed;min-height: 50px;left: 0;bottom: -10px;width: 100%;font-size: large;
  text-align: center;z-index: 999999;" class="standard-color">@IMIS</h6>

</div>
<div class="bottompane" *ngIf="androidOS">
    <div class="footer" style="max-height: 50px;">
        <h6 style="position: fixed;min-height: 50px;
    left: 0;
    bottom: -10px;
    width: 100%;
    background-color:#fcf9f9;
    text-align: center;font-size: 7px;">

            <mat-toolbar style="padding: 0px;">

                <div style="width: 20%;padding-left: 5px;color: black;" (click)="navButtonClick('dashboard')">
                    <div class="outer-div">
                        <mat-icon>dashboard</mat-icon>
                        <span style="font-size: xx-small;">Dashboard</span>
                    </div>
                </div>
                <div style="width: 20%;padding-left: 5px;color: black;" (click)="navButtonClick('leads')">
                    <div class="outer-div">
                        <mat-icon>leaderboard</mat-icon>
                        <span style="font-size: xx-small;">Leads</span>
                    </div>
                </div>
                <div style="width: 20%;padding-left: 5px;color: black;" (click)="navButtonClick('upcoming-follow-ups')">
                    <div class="outer-div">
                        <mat-icon>work_outline</mat-icon>
                        <span style="font-size: xx-small;">Follow-Up</span>
                    </div>
                </div>


                <div style="width: 20%;padding-left: 5px;color: black;" (click)="navButtonClick('failed-follow-ups')">
                    <div class="outer-div">
                        <mat-icon>art_track</mat-icon>
                        <span style="font-size: xx-small;">Failed leads</span>
                    </div>
                </div>
                <div style="width: 20%;padding-left: 5px;color: black;" (click)="navButtonClick('my-uploads')">
                    <div class="outer-div">
                        <mat-icon>add_to_queue</mat-icon>
                        <span style="font-size: xx-small;">Upload Leads</span>
                    </div>
                </div>
                <!-- <div style="width: 20%;padding-left: 5px;color: black;">
                    <div class="outer-div" [matMenuTriggerFor]="userAccountMenu">
                        <mat-icon>menu</mat-icon>
                        <span style="font-size: xx-small;">Menu</span>
                    </div>
                    <mat-menu #userAccountMenu [overlapTrigger]="false" yPosition="above">
                        <button mat-menu-item (click)="Profile();">
                        <mat-icon>person</mat-icon><span>My Profile</span>
                    </button>
                        <button mat-menu-item (click)="settingsClick()">
                        <mat-icon>settings</mat-icon><span>Settings</span>
                    </button>
                        <button mat-menu-item (click)="activityLog()">
                        <mat-icon>help</mat-icon><span>Activity Log</span>
                    </button>
                        <mat-divider></mat-divider>
                        <button mat-menu-item (click)="logOutClick()">
                        <mat-icon>exit_to_app</mat-icon>Logout
                    </button>
                    </mat-menu>
                </div> -->
            </mat-toolbar>

        </h6>
    </div>
</div>