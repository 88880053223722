<div class="container-fluid" id="container-wrapper" *ngIf="userType=='Admin'">
    <div class="d-sm-flex align-items-center justify-content-between mb-4" style="border-bottom:2px solid grey;">
        <h1 class="h3 mb-0 text-gray-800">WhatsApp Template</h1>
    </div>
    <div class="row" id="dataArea" #dataArea style="margin-top: 10px!important;overflow-y: scroll !important;overflow-x: hidden;height: 570px;">
        <div class="div-viewport">
            <div [ngClass]="sidePanelMode == true ? 'grid9' : 'grid12'">
                <table class="table table-sm table-hover " style="padding-right: 20px!important;">
                    <tr style="background-color: blue;color: white;">
                        <th>#</th>
                        <th>Template ID</th>
                        <th>Template Name</th>
                        <th>Template Details</th>
                        <th>File Allowed</th>
                        <th>Active</th>
                        <th style="width: 20px;">&nbsp;</th>
                    </tr>
                    <tr *ngFor="let item of whatsAppTemplates | async;let counter=index;">
                        <td>{{counter+1}}</td>
                        <td>{{item.templateId}}</td>
                        <td>{{item.templateName}}</td>
                        <td>{{item.templateDetails}}</td>
                        <td>{{item.fileAllowed}}</td>
                        <td>{{item.active}}</td>
                        <th style="max-width: 20px!important;">
                            <li class="nav-item dropdown no-arrow mx-1" style="right: 12px;cursor: pointer;">
                                <a class="nav-link " id="alertsDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                                </a>
                                <div class="dropdown-menu dropdown-menu-right shadow " aria-labelledby="alertsDropdown">
                                    <a class="dropdown-item d-flex align-items-center" (click)="sidePanel(item.refNo,true)">
                                        <div class="mr-3">
                                            <div class="icon-circle bg-success">
                                                <i class="fas fa-edit text-white"></i>

                                            </div>
                                        </div>
                                        <div>
                                            Edit Template
                                        </div>
                                    </a>

                                </div>
                            </li>
                        </th>
                    </tr>
                    <tr>
                        <th colspan="4" *ngIf="(whatsAppTemplates | async)?.length==0">No data found !</th>
                    </tr>
                </table>
            </div>


            <div [ngClass]="sidePanelMode ==true ? 'grid3' : 'grid0'">
                <h3 style="text-align: center;background-color: blue;color: white;margin-top: 0px;">{{boxTitle}}<span style="float: inline-end;padding-bottom: 10px!important;cursor: pointer;"><a (click)="close_clicked();"><i class="fas fa-times"></i></a></span></h3>
                <table>
                    <tr>
                        <th style="padding-left: 10px !important;">Template Id : </th>
                        <th>
                            <input type="text" [(ngModel)]="whatsAppTemplate.templateId" name="templateId" />
                        </th>
                    </tr>

                    <tr>
                        <th style="padding-left: 10px !important;">Template Name: </th>
                        <th>
                            <input type="text" [(ngModel)]="whatsAppTemplate.templateName" name="templateName" />
                        </th>
                    </tr>

                    <tr>
                        <th style="padding-left: 10px !important;">Template Details : </th>
                        <th>
                            <input type="text" [(ngModel)]="whatsAppTemplate.templateDetails" name="templateDetails" />
                        </th>
                    </tr>
                    <tr>
                        <th style="padding-left: 10px !important;">File Allowed : </th>
                        <th>
                            <select class="form-control" [(ngModel)]="whatsAppTemplate.fileAllowed" name="fileAllowed" placeholder="select">
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                        </th>
                    </tr>
                    <tr>
                        <th style="padding-left: 10px !important;">Active : </th>
                        <th>
                            <select class="form-control" [(ngModel)]="whatsAppTemplate.active" name="active" placeholder="select">
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                        </th>
                    </tr>
                </table>
                <button class="btn btn-sm btn-primary" (click)="create_edit();" style="float: inline-end;">
                <i class="fas fa-plus"></i> Save
              </button>
            </div>
        </div>
    </div>
    <button class="float"><i class="fas fa-plus" (click)="sidePanel(0,false)"></i></button>
</div>