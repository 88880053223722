import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { CommonHeader } from "./common-header";
import { HttpClient } from "@angular/common/http";
import { ExceptionHandler } from "./errorhandler";
import { LocalStorageService } from "src/app/utility/localStorageService";
import { FCMCommunication } from "../models/fcm-communication";
import { ApiResponse } from "../models/api-response";
import { catchError, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class FCMCommunicationService {
   
     private serviceRoot = `${environment.serviceRoot}`;
      commonHeader:CommonHeader=new CommonHeader() 
      private options=this.commonHeader.options;
      constructor(
        private http: HttpClient,
        private ehandler:ExceptionHandler,
        private localStorage:LocalStorageService
      ) { }
      sendFCMMessageByUserId(data:FCMCommunication): Observable<ApiResponse> {
        this.localStorage.set("method","Send")
        this.localStorage.set("operation","Send Notification Message")
        const endPoint = `${this.serviceRoot}/sendFCMMessageByUserId`;
        console.log("End Point=="+endPoint)
        return this.http.post<any>(endPoint, data,this.options).pipe(catchError(this.ehandler.errorHandler));
      }
}