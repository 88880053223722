<div class="container-fluid" id="container-wrapper" *ngIf="userType=='Admin'">
    <div class="d-sm-flex align-items-center justify-content-between mb-4" style="border-bottom:2px solid grey;">
        <h1 class="h3 mb-0 text-gray-800">Lead Medium</h1>
    </div>
    <div class="row" id="dataArea" #dataArea style="margin-top: 10px!important;overflow-y: scroll !important;overflow-x: hidden;height: 570px;">
        <div class="div-viewport">
            <div [ngClass]="sidePanelMode == true ? 'grid9' : 'grid12'">
                <table class="table table-sm table-hover " style="padding-right: 20px!important;">
                    <tr style="background-color: blue;color: white;">
                        <th>#</th>
                        <th>Medium Name</th>
                        <th>Medium Type</th>
                    </tr>
                    <tbody id="myTable">
                        <tr *ngFor="let item of members | async;let counter=index;">
                            <td>{{counter+1}}</td>
                            <td>{{item.leadMediumName}}</td>
                            <td>{{item.leadMediumType}}</td>
                            <th style="max-width: 20px!important;">
                                <li class="nav-item dropdown no-arrow mx-1" style="right: 12px;">
                                    <a class="nav-link" href="#" id="alertsDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-right shadow " aria-labelledby="alertsDropdown">
                                        <a class="dropdown-item d-flex align-items-center" (click)="sidePanel(item.refNo,true)">
                                            <div class="mr-3">
                                                <div class="icon-circle bg-success">
                                                    <i class="fas fa-edit text-white"></i>

                                                </div>
                                            </div>
                                            <div>
                                                Edit Lead Medium
                                            </div>
                                        </a>
                                        <a class="dropdown-item d-flex align-items-center" (click)="deleteClick(item.refNo)">
                                            <div class="mr-3">
                                                <div class="icon-circle bg-warning">
                                                    <i class="fas fa-male text-white"></i>
                                                </div>
                                            </div>
                                            <div>
                                                Delete Lead Medium
                                            </div>
                                        </a>
                                    </div>
                                </li>
                            </th>
                        </tr>
                        <tr>
                            <th colspan="4" *ngIf="(members | async)?.length==0">No data found !</th>
                        </tr>
                    </tbody>
                </table>
            </div>


            <div [ngClass]="sidePanelMode ==true ? 'grid3' : 'grid0'">
                <h3 style="text-align: center;background-color: blue;color: white;margin-top: 0px;">{{boxTitle}}<span style="float: inline-end;padding-bottom: 10px!important;cursor: pointer;"><a (click)="close_clicked();"><i class="fas fa-times"></i></a></span></h3>
                <table>
                    <tr>
                        <th style="padding-left: 10px !important;">Lead Medium Name : </th>
                        <th>
                            <input type="text" [(ngModel)]="model.leadMediumName" name="leadMediumName" />
                        </th>
                    </tr>

                    <tr>
                        <th style="padding-left: 10px !important;">Lead Medium Type : </th>
                        <th>
                            <select [(ngModel)]="model.leadMediumType" name="leadMediumType">
                <option value="0">Select</option>
                <option value="Digital">Digital</option>
                <option value="Print">Print</option>
                <option value="Faculty">Faculty</option>
              </select>
                        </th>
                    </tr>

                </table>
                <button class="btn btn-sm btn-primary" (click)="create_edit();" style="float: inline-end;">
                <i class="fas fa-plus"></i> Save
              </button>
            </div>
        </div>
    </div>
    <button class="float"><i class="fas fa-plus" (click)="sidePanel(0,false)"></i></button>
</div>