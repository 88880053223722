<div>
    <div class="card-header d-flex flex-row align-items-center justify-content-between">
        <h6 class="font-weight-bold text-primary">Download Data</h6>
        <button type="button" class="btn btn-danger" style="float: right;" (click)="modalRef.hide()">X</button>
    </div>
    <div class="card-body">
        <div class="form-group row">
            <div class="col-sm-12">
                <mat-form-field>
                    <input matInput type="date" name="fromDate" [(ngModel)]="downloadFilter.fromDate" placeholder="From Date" [min]="minDate">
                </mat-form-field>
            </div>
            <div class="col-sm-12">
                <mat-form-field>
                    <input matInput type="date" name="toDate" [(ngModel)]="downloadFilter.toDate" placeholder="To Date" [min]="maxDate">
                </mat-form-field>
            </div>
            <div class="col-sm-12" *ngIf="source=='Source'">
                <mat-form-field>
                    <mat-select class="field-full-width" placeholder="Include Communications" [(ngModel)]="downloadFilter.includeDetails">
                        <mat-option [value]="true" selected>Yes</mat-option>
                        <mat-option [value]="false">No</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

    </div>
    <div class="card-footer">
        <button type="button" class="btn btn-primary block" (click)="downloadFile()">Download Data</button>
    </div>
</div>