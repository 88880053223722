<body>
    <div class="main">
        <h3 style="color: white;text-align: center;margin-top: 50px;">Lead Mangement System</h3>
        <div class="login" style="margin-top: 250px;">
            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                <label for="chk" aria-hidden="true" style="font-size: 30px!important;">Login</label>
                <input class="inputs" style="margin-top: -30px;" type="email" name="email" placeholder="User Name/E-Mail" id="userName" autocomplete="off" formControlName="userName">
                <input class="inputs" type="password" name="pswd" placeholder="Password" id="passWord" formControlName="passWord">
                <div class="row" style="font-size: small;">
                    <input style="margin-left: 89px;cursor: pointer;margin-top: -5px;width: 16px;height: 16px;" type="checkbox" name="remember" id="remember" #remember (change)="onChange($event)" checked="isRememberChecked" formControlName="remember">                    &nbsp;&nbsp;Remember me
                </div>

                <button type="submit">Login</button>

                <p align="center" style="margin-top: 20px;cursor: pointer;"><a (click)="openModal();">Forgot Password?</a></p>
            </form>
            <h5 style="margin-top: -10px;text-align: right;padding-right: 10px;cursor: pointer;font-size: x-small;"><a (click)="openFilterForm()">Download App</a></h5>
        </div>
    </div>


    <div id="myFilterForm" class="form-popup-popup-center" style="z-index: 999;text-align: center;vertical-align: middle;">
        <div class="card-header d-flex align-items-center justify-content-between" style="background-color: rgb(1, 9, 15);">
            <div class="row h4 mb-0 text-gray-800">
                <div class="col">
                    <h6 class="font-weight-bold " style="color: white;">Download</h6>
                </div>

            </div>


        </div>
        <div class="card-body" style="height: 200px!important;overflow-y: scroll;background-color: rgb(245, 252, 252);">

            <h6>Please download the app when the CRM application is opened on any Android mobile device to download it. Once downloaded, install it even if it displays the message unauthorized application. All the permissions are required to access the app.
            </h6>
            <a (click)="downloadFile('../../../assets/crm.apk')" style="cursor: pointer;margin-top: 30px;">Download</a>
        </div>

        <div class="card-footer" style="scroll;background-color:rgb(1, 9, 15);">
            <button type="button" class="btn btn-primary block" (click)="closeNotesForm()">Close</button>
        </div>
    </div>
</body>