import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { LocalStorageService } from 'src/app/utility/localStorageService';
import { TblUserWiseTransaction } from '../../models/userwisetransactions';
import { CommonService } from '../../services/common.services';
import { HttpErrorResponse } from '@angular/common/http';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-activity-log-dialog',
  templateUrl: './activity-log-dialog.component.html',
  styleUrls: ['./activity-log-dialog.component.css'],
  providers: [DatePipe]
})
export class ActivityLogDialogComponent implements OnInit {
  userName:string=""
  tblUserWiseTransaction:TblUserWiseTransaction[]=[]
  fromDate:string;
  toDate:string;
  userType: string;
  constructor(public modalRef: BsModalRef,
    private localStorage:LocalStorageService,
    private commonService:CommonService,
    private datePipe:DatePipe) { }

  ngOnInit(): void {
    this.userType = this.localStorage.get("userType")
    this.userName=this.localStorage.get("userName")
    this.getUserWiseTransactionByUserIDAndDate();
    this.toDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss');
    this.fromDate=this.datePipe.transform(this.getDateBeforeDays(new Date(), 30), 'yyyy-MM-dd HH:mm:ss');
  }
  getUserWiseTransactionByUserIDAndDate()
  {
    this.tblUserWiseTransaction=[];
    setTimeout(() => {
     
      this.commonService.getUserWiseTransactionByUserIDAndDate(Number(this.localStorage.get("refNo")),this.fromDate,this.toDate)
      .subscribe(
        resp=>{
          this.tblUserWiseTransaction=resp;
          this.tblUserWiseTransaction.forEach(e=>
          {
            e.requestDateTime=this.datePipe.transform(e.requestDateTime, 'dd-MM-yyyy hh:mm:ss');
         
          }
          
          )
          //this.tblUserWiseTransaction.sort((b,a)=>a.refNo-b.refNo)
        },
        (error: HttpErrorResponse) => {
         
        });
    }, 500);
  }
  getDateBeforeDays(date: Date, days: number): Date {
    const result = new Date(date);
    result.setDate(result.getDate() - days);
    return result;
  }
  stopPropagation(event){
    event.stopPropagation();
    }
  searchTable() {
    var term, table;
    term=document.getElementById("myInput") as unknown as HTMLInputElement
    // get term to search
    term = term.value.toLowerCase();
    table =document.getElementById("myTable") as HTMLTableElement
    // get table rows, handle as array.
    table = Array.from(table.rows);

    // filter non-matching rows; these are set to display='none'
    table.filter(function(el) {
        return el.textContent.toLowerCase().indexOf(term) ==
            -1
    }).map(x => x.style.display = "none");

    // filter matching rows; these are set to display =''
    table.filter(function(el) {
        return el.textContent.toLowerCase().indexOf(term) > -1
    }).map(x => x.style.display = "");
}
}
