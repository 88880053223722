<div>
    <div class="card-header d-flex flex-row align-items-center justify-content-between">
        <h6 class="font-weight-bold text-primary">Send SMS</h6>
        <button type="button" class="btn btn-danger" style="float: right;" (click)="modalRef.hide()">X</button>
    </div>
    <div class="card-body">
        <div class="form-group row">
            <div class="col-sm-6">
                <mat-form-field>
                    <input matInput type="text" readonly class="field-full-width" name="toName" [(ngModel)]="sendSMSData.toName" placeholder="Name">
                </mat-form-field>
            </div>

            <div class="col-sm-6">
                <mat-form-field>
                    <input matInput type="text" readonly class="field-full-width" name="toNumber" [(ngModel)]="sendSMSData.toNumber" placeholder="Number">
                </mat-form-field>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-sm-12">
                <mat-form-field>
                    <mat-select class="field-full-width" placeholder="Template" [(ngModel)]="sendSMSData.templateId" (selectionChange)="onTemplateSelect($event.value)">
                        <mat-option *ngFor="let stat of tblSMSTemplate" [value]="stat.templateId">{{stat.templateName}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-sm-12">
                <mat-form-field>
                    <textarea matInput type="text" class="field-full-width" name="message" [(ngModel)]="sendSMSData.message" placeholder="Message" Rows="5">OK</textarea>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="card-footer">
        <button type="button" class="btn btn-primary block" (click)="sendSMS()">Send SMS</button>
    </div>
</div>