import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { DateRange, DefaultMatCalendarRangeStrategy, MatCalendar, MatRangeDateSelectionModel } from '@angular/material/datepicker';
import { UserIdAndName } from '../shared/models/useridandname';
import { UserServices } from '../shared/services/userservices';
import { HttpErrorResponse } from '@angular/common/http';
import { MatTableDataSource } from '@angular/material/table';
import { DatePipe } from '@angular/common';
import { TblLeadSources } from '../shared/models/leadsources';
import { TblLeadMedium } from '../shared/models/leadMedium';
import { TblLeadStatus } from '../shared/models/leadstatus';
import { TblLeadSubStatus } from '../shared/models/leadsubstatus';
import { TblCourses } from '../shared/models/courses';
import { LeadMediumService } from '../shared/services/leadMedium.service';
import { LeadSourceService } from '../shared/services/leadsource.services';
import { LeadStatusService } from '../shared/services/leadStatus.services';
import { LeadSubStatusService } from '../shared/services/leadSubStatusService';
import { CoursesService } from '../shared/services/courses.service';
import { NotificationService } from '../notification.service';
import { FirstLeadAnalysis } from '../shared/models/first-lead-analysis';
import { MatSort, Sort } from '@angular/material/sort';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { LeadService } from '../shared/services/lead.services';
import { LocalStorageService } from '../utility/localStorageService';
import * as moment from 'moment';
import { TblNote } from '../shared/models/note';
import { NotesService } from '../shared/services/calls.services';
import { DashboardAllData } from '../shared/models/DashboardAllData';
import { UserAndLeadWiseLeads } from '../shared/models/UserAndLeadWiseLeads';
import { from } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  providers: [DefaultMatCalendarRangeStrategy, MatRangeDateSelectionModel]
})
export class DashboardComponent implements OnInit {
  @ViewChild('filterForm') filterForm: ElementRef;
  @ViewChild('empTbSort') empTbSort = new MatSort();
  @ViewChild('calendar') calendar: MatCalendar<Date> | undefined;
  selectedDateRange: DateRange<Date> | undefined;
  left: number = 0;
  top: number = 0;
  filterFormCondition: boolean = false
  dateRangeCondition: boolean = false
  counsellorCondition: boolean = false

  maxDate: Date | undefined;
  minDate: Date | undefined;
  userType: string = ""
  userIdAndName: UserIdAndName[]
  selectedDate: string
  dropdownSettings: any = {};
  closeDropdownSelection = false;
  leadOwnerAnalysisData: UserAndLeadWiseLeads[]
  presets = [
    {
      label: 'Today',
      range: {
        start: new Date(),
        end: new Date(),
      },
    },
    {
      label: 'Yesterday',
      range: {
        start: (() => {
          const date = new Date();
          date.setDate(date.getDate() - 1);
          return date;
        })(),
        end: (() => {
          const date = new Date();
          date.setDate(date.getDate() - 1);
          return date;
        })(),
      },
    },
    {
      label: 'Last 7 days',
      range: {
        start: (() => {
          const date = new Date();
          date.setDate(date.getDate() - 7);
          return date;
        })(),
        end: new Date(),
      },
    },
    {
      label: 'Last 30 days',
      range: {
        start: (() => {
          const date = new Date();
          date.setDate(date.getDate() - 30);
          return date;
        })(),
        end: new Date(),
      },
    },
    {
      label: 'Last 90 days',
      range: {
        start: (() => {
          const date = new Date();
          date.setDate(date.getDate() - 90);
          return date;
        })(),
        end: new Date(),
      },
    },
    {
      label: 'Last 12 months',
      range: {
        start: (() => {
          const today = new Date();
          const start = new Date();
          start.setFullYear(today.getFullYear() - 1);
          start.setDate(start.getDate() + 1);
          return start;
        })(),
        end: new Date(),
      },
    },
    {
      label: 'Last year',
      range: {
        start: (() => {
          const today = new Date();
          const start = new Date(today.getFullYear() - 1, 0, 1);
          return start;
        })(),
        end: (() => {
          const today = new Date();
          const end = new Date(today.getFullYear() - 1, 11, 31);
          return end;
        })(),
      },
    },
    {
      label: 'This year',
      range: {
        start: (() => {
          const today = new Date();
          const start = new Date(today.getFullYear(), 0, 1);
          return start;
        })(),
        end: new Date(),
      },
    },
  ];


  totalOfflineApplication: number = 0
  totalApplications: number = 0
  totalRejectedApllication: number = 0;
  totalOnlineApplication: number = 0
  newLeadsForToday: number = 0
  eMailSent: number = 0
  lastDayActiveUsers: number = 0
  totalVerifiedLeads: number = 0
  totalReEnquiries: number = 0
  totalMissedFollowUps: number = 0
  totalWhatsAppConsumed: number = 0



  rangeOptionSelected = "Creation Date ";
  dashboarAllData: DashboardAllData[]

  leadChannel: TblLeadMedium[]
  leadSource: TblLeadSources[]
  leadStatus: TblLeadStatus[]
  // leadSubStatus: TblLeadSubStatus[]
  sourceType: any[]
  leadCourse: TblCourses[]
  followUpRequired: any[]
  chartLeadCountBySourceChart: any
  topPerformingLeadChannelChart: any
  totalLeadFunnelByStatusChart: any
  leadCountByCounsellorChart: any
  topReasonsForClosedLeadsChart: any

  statusWiseTotalLeadsChat: any

  dropdownList = [];
  selectedItems = [];
  leadTypeSelected: boolean = false;
  leadSubTypeSelected: boolean = false

  leadChannelDisp: boolean = false
  leadSourseDisp: boolean = false
  leadStatusDisp: boolean = false
  sourceTypeDisp: boolean = false
  leadCourseDisp: boolean = false
  followUpRequiredDisp: boolean = false
  stateDisp: boolean = false
  cityDisp: boolean = false
  city: string[]
  state: string[]
  subTypeStatus: { name: string; value: number; }[];
  filterType: { name: string; value: number; }[];
  leadSourceFilter: string = 'All';
  statusFilter: string = 'All';
  mediumFilter: string = 'All';
  sourceFilter: string = 'All';
  courseFilter: string = 'All';
  followUpRequiredFilter: string = 'All';
  stateFilter: string = 'All';
  cityFilter: string = 'All';
  counsellorFilter: string = 'All'
  fromDateFilter: string = 'All';
  toDateFilter: string = 'All'
  totalLeadFunnelByStatus = {
    animationEnabled: true,
    data: []
  }
  statusWiseTotalLeads = {
    animationEnabled: true,
    data: []
  }
  topPerformingLeadChannel = {
    animationEnabled: true,

    colorSet: "customColorSet",

    data: []
  }

  leadCountBySource = {
    animationEnabled: true,

    axisX: {
      labelAngle: -90
    },
    axisY: {
      title: "Leads"
    },
    toolTip: {
      shared: true
    },
    legend: {
      cursor: "pointer",
    },
    data: []
  }
  leadCountByCounsellor = {
    animationEnabled: true,

    axisX: {
      labelAngle: -90
    },
    axisY: {
      title: "Leads"
    },
    toolTip: {
      shared: true
    },
    legend: {
      cursor: "pointer",
    },
    data: []
  }
  topReasonsForClosedLeads = {
    animationEnabled: true,

    axisX: {
      labelAngle: -90
    },
    axisY: {
      title: "Leads"
    },
    toolTip: {
      shared: true
    },
    legend: {
      cursor: "pointer",
    },
    data: []
  }

  dataSource: MatTableDataSource<FirstLeadAnalysis>
  dataValue: FirstLeadAnalysis[] = [];
  counsellors: number = 0
  displayedColumns: string[] = ['leadOwner', 'total', 'unTouched', 'ror', 'followUp', 'closed'];
  datasAna: string[][] = []
  list: any[];
  smsSent: number = 0;
  whatsApp: number = 0;
  call: number = 0;
  constructor(
    private leadChannelService: LeadMediumService,
    private leadSourceService: LeadSourceService,
    private leadStatusService: LeadStatusService,
    private leadSubStatusService: LeadSubStatusService,
    private leadCourseService: CoursesService,
    private userService: UserServices,
    private readonly selectionModel: MatRangeDateSelectionModel<Date>,
    private readonly selectionStrategy: DefaultMatCalendarRangeStrategy<Date>,
    public datepipe: DatePipe,
    private notifyService: NotificationService,
    private _liveAnnouncer: LiveAnnouncer,
    private leadService: LeadService,
    private localStorage: LocalStorageService,
    private notesService: NotesService

  ) {

    this.dataSource = new MatTableDataSource;
    this.list =
      [
        { name: 'India', checked: false },
        { name: 'US', checked: false },
        { name: 'China', checked: false },
        { name: 'France', checked: false }
      ]
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.empTbSort;
  }
  sortData(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }


  getAllDashboardData(fromDate: string, toDate: string, userId: string) {

    this.leadService.getDashBoardAllDataByDateRangeAndUser(fromDate, toDate, userId).subscribe(resp => {
      this.dashboarAllData = resp
      console.log(this.dashboarAllData)

      this.leadSourceFilter = 'All';
      this.statusFilter = 'All';
      this.mediumFilter = 'All';
      this.sourceFilter = 'All';
      this.courseFilter = 'All';
      this.followUpRequiredFilter = 'All';
      this.stateFilter = 'All';
      this.cityFilter = 'All';

      if (this.localStorage.get("userType") == 'Admin')
        this.counsellorFilter = 'All'
      else
        this.counsellorFilter = this.localStorage.get("userName")
      this.fromDateFilter = fromDate;
      this.toDateFilter = toDate
      this.followUpRequired = ([...new Set(resp.map(item => item.followUpRequired))])
      this.sourceType = ([...new Set(resp.map(item => item.sourceType))])
      this.filterData(this.leadSourceFilter, this.statusFilter, this.mediumFilter, this.sourceFilter, this.courseFilter, this.followUpRequiredFilter, this.stateFilter, this.cityFilter, this.counsellorFilter, this.fromDateFilter, this.toDateFilter)
    });
  }

  ngOnInit(): void {
    this.userType = this.localStorage.get("userType")

    this.minDate = new Date(this.localStorage.get("showMeLeadsFromDate"));
    this.maxDate = this.addDays(new Date(), Number(this.localStorage.get("showMeUpcomingEventsOf")));
    this.getActiveCounsellors()

    //this.getFirstLeadOwnerAnalysis(this.localStorage.get("showMeLeadsFromDate"), moment(this.maxDate).format('YYYY-MM-DD'))

    this.getDashboardStatusCountPage(this.localStorage.get("showMeLeadsFromDate"))
    this.getAllDashboardData(this.localStorage.get("showMeLeadsFromDate"), moment(this.maxDate).format('YYYY-MM-DD'), '0');
    this.getAllFilterTypes()
    //this.getEMailSentCallsAndWhatsApps(this.localStorage.get("showMeLeadsFromDate"));
    // this.getLeadCountBySource(this.localStorage.get("showMeLeadsFromDate"), moment(this.maxDate).format('YYYY-MM-DD'), '0', 'Creation Date')
    // this.getTopPerformingLeadChannel(this.localStorage.get("showMeLeadsFromDate"))
    // this.getTotalLeadFunnelByStatus(this.localStorage.get("showMeLeadsFromDate"))
    //this.getStatusWiseTotalLeads(this.localStorage.get("showMeLeadsFromDate"), moment(this.maxDate).format('YYYY-MM-DD'), '0', 'Creation Date')
    // this.getTopReasonsForClosedLeads(this.localStorage.get("showMeLeadsFromDate"))
    // this.getCounsellorWiseTotalLeads(this.localStorage.get("showMeLeadsFromDate"))


    this.dataSource.data = [{ leadOwner: 'Alok', total: 10, unTouched: 7, ror: 2, followUp: 2, closed: 0 }, { leadOwner: 'Sagarik', total: 12, unTouched: 3, ror: 5, followUp: 5, closed: 1 }];

    this.subTypeStatus = [{ name: 'Lead Source', value: 0 }, { name: 'Status', value: 0 }, { name: 'Source Type', value: 0 }, { name: 'Course', value: 0 }, { name: 'FollowUp Required', value: 0 }, { name: 'State', value: 0 }, { name: 'City', value: 0 }];
    this.filterType = [{ name: 'Lead Source', value: 0 }, { name: 'Status', value: 0 }, { name: 'Source Type', value: 0 }, { name: 'Course', value: 0 }, { name: 'FollowUp Required', value: 0 }, { name: 'State', value: 0 }, { name: 'City', value: 0 }];
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'refNo',
      textField: 'userName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };


    this.selectedDateRange = new DateRange<Date>(
      new Date(),
      new Date()
    );
    this.selectedDate = this.rangeOptionSelected + this.datepipe.transform(this.selectedDateRange.start, 'MMM dd, yyy') + " - " + this.datepipe.transform(this.selectedDateRange.end, 'MMM dd, yyy')
  }
  addDays(date: Date, days: number): Date {
    date.setDate(date.getDate() + days);
    return date;
  }
  getEMailSentCallsAndWhatsApps(fromDate: string) {
    this.leadService.getEMailSentCallsAndWhatsApps(fromDate).subscribe(resp => {
      this.eMailSent = resp.mail;
      this.smsSent = resp.sms;
      this.whatsApp = resp.whatsApp;
      this.call = resp.calls

    });
  }
  getTotalLeadFunnelByStatus(fromDate: string) {
    let data = {
      type: "funnel",
      indexLabel: "{name}: {y}",
      valueRepresents: "area",
      dataPoints: [
        { y: 0, name: "All" }
      ]
    }
    this.leadService.getTotalLeadFunnelByStatus(fromDate).subscribe(resp => {
      if (resp.length > 0) {
        data.dataPoints = [];
        resp.forEach(e => {
          data.dataPoints.push({ y: e.count, name: e.statusName.toString() })
        }
        )
        this.totalLeadFunnelByStatus.data.push(data);
        this.totalLeadFunnelByStatusChart.render();
      }
    });
  }
  ChartInstanceForTotalLeadFunnelByStatus(chart: object) {
    this.totalLeadFunnelByStatusChart = chart
  }
  //////////////////////////////////
  getStatusWiseTotalLeads(fromDate: string, toDate: string, refNo: string, rangeType: string) {
    let data = {
      type: "funnel",
      indexLabel: "{name}: {y}",
      valueRepresents: "area",
      dataPoints: [
        { y: 0, name: "All" }
      ]
    }
    this.leadService.getStatusWiseTotalLeads(fromDate, toDate, refNo, rangeType).subscribe(resp => {
      if (resp.length > 0) {
        data.dataPoints = [];
        resp.forEach(e => {
          data.dataPoints.push({ y: e.count, name: e.statusName.toString() })
        }
        )
        this.statusWiseTotalLeads.data.push(data);
        this.statusWiseTotalLeadsChat.render();
      }
    });
  }
  ChartInstanceForStatusWiseTotalLeads(chart: object) {
    this.statusWiseTotalLeadsChat = chart
  }
  ///////////////////////////////////////////////////////////////
  getTopReasonsForClosedLeads(fromDate: string) {
    let data = {
      type: "bar",
      showInLegend: false,
      dataPoints: [
        { label: "No Reasons", y: 0 }

      ]
    }
    this.leadService.getTopReasonsForClosedLeads(fromDate).subscribe(resp => {
      if (resp.length > 0) {
        data.dataPoints = [];
        resp.forEach(e => {
          data.dataPoints.push({ label: e.statusName.toString(), y: e.count })
        }
        )
        this.topReasonsForClosedLeads.data.push(data);
        this.topReasonsForClosedLeadsChart.render();
      }
    });
  }

  ChartInstanceForTopReasonsForClosedLeads(chart: object) {
    this.topReasonsForClosedLeadsChart = chart;
  }


  getTopPerformingLeadChannel(fromDate: string) {

    let data = {
      type: "doughnut",
      indexLabel: "{name}: {y}",
      innerRadius: "90%",
      yValueFormatString: "#,##0.00'%'",
      dataPoints: [
        { y: 0, name: "All" }
      ]
    }

    this.leadService.getTopPerformingLeadCountChannel(fromDate).subscribe(resp => {
      if (resp.length > 0) {
        data.dataPoints = [];
        resp.forEach(e => {
          data.dataPoints.push({ y: e.count, name: e.channelName.toString() })
        }
        )
        this.topPerformingLeadChannel.data.push(data);
        this.topPerformingLeadChannelChart.render();
      }
    });
  }

  ChartInstanceForTopPerformingLeadChannel(chart: object) {
    this.topPerformingLeadChannelChart = chart;
  }
  /////////////////////////////////////////////////////////////////////////////////
  getLeadCountBySource(fromDate: string, toDate: string, refNo: string, rangeType: string) {
    let data = {
      type: "column",
      showInLegend: false,
      dataPoints: [
        { label: "All", y: 0 }
      ]
    }
    this.leadService.getLeadCountBySource(fromDate, toDate, refNo, rangeType).subscribe(resp => {
      if (resp.length > 0) {
        data.dataPoints = [];
        resp.forEach(e => {
          data.dataPoints.push({ label: e.leadName.toString(), y: e.count })
        }
        )
        this.leadCountBySource.data.push(data);
        this.chartLeadCountBySourceChart.render();
      }
    });
  }
  ChartInstanceForLeadCountBySource(chart: object) {
    this.chartLeadCountBySourceChart = chart;
  }


  //////////////////////////////////////////////////////////////////////////////
  getCounsellorWiseTotalLeads(fromDate: string) {
    let data = {
      type: "column",
      showInLegend: false,
      dataPoints: [
        { label: "All", y: 0 }
      ]
    }

    this.leadService.getCounsellorWiseTotalLeads(fromDate).subscribe(resp => {
      if (resp.length > 0) {
        data.dataPoints = [];
        resp.forEach(e => {
          data.dataPoints.push({ label: e.counsellorName, y: e.count })
        }
        )
        this.leadCountByCounsellor.data.push(data);
        this.leadCountByCounsellorChart.render();
      }
    });
  }
  ChartInstanceForLeadCountByCounsellor(chart: object) {
    this.leadCountByCounsellorChart = chart
  }

  ///////////////////////////////////////////////////////////////////
  getFirstLeadOwnerAnalysis(fromDate: string, toDate: string) {
    this.leadService.getFirstLeadOwnerAnalysis(fromDate, toDate).subscribe(resp => {
      this.leadOwnerAnalysisData = resp

    });
  }


  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  getDashboardStatusCountPage(date: string) {
    this.leadService.getDashboardStatusCountPage(date).subscribe(resp => {
      this.lastDayActiveUsers = resp.lastDayActiveUsers
      // this.totalApplications = resp.totalApplication
      // this.totalOnlineApplication = resp.totalApplication
      // this.newLeadsForToday = resp.leadsForToday
      // this.totalVerifiedLeads = resp.totalVerifiedLeads
      // this.totalReEnquiries = resp.totalApplication
      // this.totalMissedFollowUps = resp.totalMissedFollowups
    });
  }
  getAllFilterTypes() {
    this.leadChannelService.getAllLeadMedium().subscribe(resp => {
      if (resp.length > 0) {
        this.leadChannel = resp
        console.log(this.leadChannel)
      }
      else {
        this.notifyService.showInfo("No Records Available", "No Data")
        this.leadChannel = []
      }
    });

    this.leadSourceService.getAllLeadSource().subscribe(resp => {
      if (resp.length > 0) {
        this.leadSource = resp
      }
      else {
        this.notifyService.showInfo("No Records Available", "No Data")
        this.leadSource = []
      }
    });


    this.leadStatusService.getAllLeadStatus().subscribe(resp => {
      if (resp.length > 0) {
        this.leadStatus = resp
      }
      else {
        this.notifyService.showInfo("No Records Available", "No Data")
        this.leadStatus = []
      }
    });


    this.leadCourseService.getAllCourses().subscribe(resp => {
      if (resp.length > 0) {
        this.leadCourse = resp
      }
      else {
        this.notifyService.showInfo("No Records Available", "No Data")
        this.leadCourse = []
      }
    });


  }
  filterTypeClicked(data: any) {

    this.leadTypeSelected = true
    if (data == "Lead Channel") {
      this.leadChannelDisp = true
      this.leadSourseDisp = false
      this.leadStatusDisp = false
      this.sourceTypeDisp = false
      this.leadCourseDisp = false
      this.followUpRequiredDisp = false
      this.stateDisp = false
      this.cityDisp = false
    }
    if (data == "Lead Source") {
      this.leadChannelDisp = false
      this.leadSourseDisp = true
      this.leadStatusDisp = false
      this.sourceTypeDisp = false
      this.leadCourseDisp = false
      this.followUpRequiredDisp = false
      this.stateDisp = false
      this.cityDisp = false
    }
    if (data == "Status") {
      this.leadChannelDisp = false
      this.leadSourseDisp = false
      this.leadStatusDisp = true
      this.sourceTypeDisp = false
      this.leadCourseDisp = false
      this.followUpRequiredDisp = false
      this.stateDisp = false
      this.cityDisp = false
    }
    if (data == "Source Type") {
      this.leadChannelDisp = false
      this.leadSourseDisp = false
      this.leadStatusDisp = false
      this.sourceTypeDisp = true
      this.leadCourseDisp = false
      this.followUpRequiredDisp = false
      this.stateDisp = false
      this.cityDisp = false
    }
    if (data == "Course") {
      this.leadChannelDisp = false
      this.leadSourseDisp = false
      this.leadStatusDisp = false
      this.sourceTypeDisp = false
      this.leadCourseDisp = true
      this.followUpRequiredDisp = false
      this.stateDisp = false
      this.cityDisp = false
    }
    if (data == "FollowUp Required") {
      this.leadChannelDisp = false
      this.leadSourseDisp = false
      this.leadStatusDisp = false
      this.sourceTypeDisp = false
      this.leadCourseDisp = false
      this.followUpRequiredDisp = true
      this.stateDisp = false
      this.cityDisp = false
    }
    if (data == "State") {
      this.leadChannelDisp = false
      this.leadSourseDisp = false
      this.leadStatusDisp = false
      this.sourceTypeDisp = false
      this.leadCourseDisp = false
      this.followUpRequiredDisp = false
      this.stateDisp = true
      this.cityDisp = false
    }

    if (data == "City") {
      this.leadChannelDisp = false
      this.leadSourseDisp = false
      this.leadStatusDisp = false
      this.sourceTypeDisp = false
      this.leadCourseDisp = false
      this.followUpRequiredDisp = false
      this.stateDisp = false
      this.cityDisp = true
    }


  }
  selectedFilterRemove(data: any) {
    this.subTypeStatus.forEach(e => {
      if (e.name == data) {
        e.value = 0;
      }
    });

    this.filterType.forEach(e => {
      if (e.name == data) {

        e.value = 0;

      }
    })

    if (data == "Lead Channel") {
      document.getElementsByName("leadChannelDisp").forEach((e) => {
        const checkbox = e as HTMLInputElement | null;
        if (checkbox != null)
          checkbox.checked = false
      })
    }
    if (data == "Lead Source") {
      document.getElementsByName("leadSourseDisp").forEach((e) => {
        const checkbox = e as HTMLInputElement | null;
        if (checkbox != null)
          checkbox.checked = false
      })
    }
    if (data == "Status") {
      document.getElementsByName("leadStatusDisp").forEach((e) => {
        const checkbox = e as HTMLInputElement | null;
        if (checkbox != null)
          checkbox.checked = false
      })
    }
    if (data == "Source Type") {
      document.getElementsByName("sourceType").forEach((e) => {
        const checkbox = e as HTMLInputElement | null;
        if (checkbox != null)
          checkbox.checked = false
      })
    }
    if (data == "Course") {
      document.getElementsByName("leadCourseDisp").forEach((e) => {
        const checkbox = e as HTMLInputElement | null;
        if (checkbox != null)
          checkbox.checked = false
      })
    }
    if (data == "FollowUp Required") {
      document.getElementsByName("followUpRequiredDisp").forEach((e) => {
        const checkbox = e as HTMLInputElement | null;
        if (checkbox != null)
          checkbox.checked = false
      })
    }
    if (data == "State") {
      document.getElementsByName("stateDisp").forEach((e) => {
        const checkbox = e as HTMLInputElement | null;
        if (checkbox != null)
          checkbox.checked = false
      })
    }
    if (data == "City") {
      document.getElementsByName("cityDisp").forEach((e) => {
        const checkbox = e as HTMLInputElement | null;
        if (checkbox != null)
          checkbox.checked = false
      })
    }
  }
  filterSubTypeClicked(event: any, source: string, refData: string) {
    let selected: boolean = false
    let itemGrSelected = 0;
    this.subTypeStatus.forEach(e => {
      if (e.name == source) {
        if (event.target.checked) {
          e.value++;
        }
        else {
          e.value--;
        }
      }
      if (e.value > 0) {
        itemGrSelected++;
        selected = true
      }
      this.filterType.forEach(f => {
        if (e.name == f.name) {
          if (e.value > 0)
            f.value = 1
          else
            f.value = 0
        }
      });

    })
    if (source == "Lead Source") {
      if (event.target.checked) {
        if (this.leadSourceFilter == 'All')
          this.leadSourceFilter = refData
        else
          this.leadSourceFilter = this.leadSourceFilter + "," + refData
      }
      else {
        if (this.leadSourceFilter != 'All') {
          if (this.leadSourceFilter.includes(refData + ","))
            this.leadSourceFilter = this.leadSourceFilter.replace(refData + ",", "")
          if (this.leadSourceFilter.includes(refData))
            this.leadSourceFilter = this.leadSourceFilter.replace(refData, "")
        }
      }
    }
    if (this.leadSourceFilter.length == 0)
      this.leadSourceFilter = 'All'
    else {
      if (this.leadSourceFilter.charAt(this.leadSourceFilter.length - 1) == ",")
        this.leadSourceFilter = this.leadSourceFilter.substring(0, this.leadSourceFilter.length - 1)
    }

    if (source == "Status") {
      if (event.target.checked) {
        if (this.statusFilter == 'All')
          this.statusFilter = refData
        else
          this.statusFilter = this.statusFilter + "," + refData
      }
      else {
        if (this.statusFilter != 'All') {
          if (this.statusFilter.includes(refData + ","))
            this.statusFilter = this.statusFilter.replace(refData + ",", "")
          if (this.statusFilter.includes(refData))
            this.statusFilter = this.statusFilter.replace(refData, "")
        }
      }
    }
    if (this.statusFilter.length == 0)
      this.statusFilter = 'All'
    else {
      if (this.statusFilter.charAt(this.statusFilter.length - 1) == ",")
        this.statusFilter = this.statusFilter.substring(0, this.statusFilter.length - 1)
    }


    if (source == "Source Type") {
      if (event.target.checked) {
        if (this.mediumFilter == 'All')
          this.mediumFilter = refData
        else
          this.mediumFilter = this.mediumFilter + "," + refData
      }
      else {
        if (this.mediumFilter != 'All') {
          if (this.mediumFilter.includes(refData + ","))
            this.mediumFilter = this.mediumFilter.replace(refData + ",", "")
          if (this.mediumFilter.includes(refData))
            this.mediumFilter = this.mediumFilter.replace(refData, "")
        }
      }
    }
    if (this.mediumFilter.length == 0)
      this.mediumFilter = 'All'
    else {
      if (this.mediumFilter.charAt(this.mediumFilter.length - 1) == ",")
        this.mediumFilter = this.mediumFilter.substring(0, this.mediumFilter.length - 1)
    }

    if (source == "Course") {
      if (event.target.checked) {
        if (this.courseFilter == 'All')
          this.courseFilter = refData
        else
          this.courseFilter = this.courseFilter + "," + refData
      }
      else {
        if (this.courseFilter != 'All') {
          if (this.courseFilter.includes(refData + ","))
            this.courseFilter = this.courseFilter.replace(refData + ",", "")
          if (this.courseFilter.includes(refData))
            this.courseFilter = this.courseFilter.replace(refData, "")
        }
      }
    }
    if (this.courseFilter.length == 0)
      this.courseFilter = 'All'
    else {
      if (this.courseFilter.charAt(this.courseFilter.length - 1) == ",")
        this.courseFilter = this.courseFilter.substring(0, this.courseFilter.length - 1)
    }

    if (source == "FollowUp Required") {
      if (event.target.checked) {
        if (this.followUpRequiredFilter == 'All')
          this.followUpRequiredFilter = refData
        else
          this.followUpRequiredFilter = this.followUpRequiredFilter + "," + refData
      }
      else {
        if (this.followUpRequiredFilter != 'All') {
          if (this.followUpRequiredFilter.includes(refData + ","))
            this.followUpRequiredFilter = this.followUpRequiredFilter.replace(refData + ",", "")
          if (this.followUpRequiredFilter.includes(refData))
            this.followUpRequiredFilter = this.followUpRequiredFilter.replace(refData, "")
        }
      }
    }
    if (this.followUpRequiredFilter.length == 0)
      this.followUpRequiredFilter = 'All'
    else {
      if (this.followUpRequiredFilter.charAt(this.followUpRequiredFilter.length - 1) == ",")
        this.followUpRequiredFilter = this.followUpRequiredFilter.substring(0, this.followUpRequiredFilter.length - 1)
    }

    if (source == "State") {
      if (event.target.checked) {
        if (this.stateFilter == 'All')
          this.stateFilter = refData
        else
          this.stateFilter = this.stateFilter + "," + refData
      }
      else {
        if (this.stateFilter != 'All') {
          if (this.stateFilter.includes(refData + ","))
            this.stateFilter = this.stateFilter.replace(refData + ",", "")
          if (this.stateFilter.includes(refData))
            this.stateFilter = this.stateFilter.replace(refData, "")
        }
      }
    }
    if (this.stateFilter.length == 0)
      this.stateFilter = 'All'
    else {
      if (this.stateFilter.charAt(this.stateFilter.length - 1) == ",")
        this.stateFilter = this.stateFilter.substring(0, this.stateFilter.length - 1)
    }

    if (source == "City") {
      if (event.target.checked) {
        if (this.cityFilter == 'All')
          this.cityFilter = refData
        else
          this.cityFilter = this.cityFilter + "," + refData
      }
      else {
        if (this.cityFilter != 'All') {
          if (this.cityFilter.includes(refData + ","))
            this.cityFilter = this.cityFilter.replace(refData + ",", "")
          if (this.cityFilter.includes(refData))
            this.cityFilter = this.cityFilter.replace(refData, "")
        }
      }
    }
    if (this.cityFilter.length == 0)
      this.cityFilter = 'All'
    else {
      if (this.cityFilter.charAt(this.cityFilter.length - 1) == ",")
        this.cityFilter = this.cityFilter.substring(0, this.cityFilter.length - 1)
    }

  }


  rangeTypeClicked(id: number) {
    const r1 = document.getElementById("creationDate") as HTMLInputElement

    const r2 = document.getElementById("followUpDate") as HTMLInputElement
    // const r3 = document.getElementById("notificationDate") as HTMLInputElement
    // const r4 = document.getElementById("reEnquireDate") as HTMLInputElement
    if (id == 1 && r1?.checked) {
      r2.checked = false;
      // r3.checked = false;
      // r4.checked = false;
      this.rangeOptionSelected = "Creation Date ";
      this.selectedDate = this.rangeOptionSelected + this.datepipe.transform(this.selectedDateRange.start, 'MMM dd, yyy') + " - " + this.datepipe.transform(this.selectedDateRange.end, 'MMM dd, yyy')

    }
    if (id == 2 && r2?.checked) {
      r1.checked = false;
      // r3.checked = false;
      // r4.checked = false;
      this.rangeOptionSelected = "Next Follow-up Date ";
      this.selectedDate = this.rangeOptionSelected + this.datepipe.transform(this.selectedDateRange.start, 'MMM dd, yyy') + " - " + this.datepipe.transform(this.selectedDateRange.end, 'MMM dd, yyy')

    }
    // if (id == 3 && r3?.checked) {
    //   r1.checked = false;
    //   r2.checked = false;
    //   r4.checked = false;
    //   this.rangeOptionSelected = "Next Action Date ";
    //   this.selectedDate = this.rangeOptionSelected + this.datepipe.transform(this.selectedDateRange.start, 'MMM dd, yyy') + " - " + this.datepipe.transform(this.selectedDateRange.end, 'MMM dd, yyy')

    // }
    // if (id == 4 && r4?.checked) {
    //   r1.checked = false;
    //   r2.checked = false;
    //   r3.checked = false;
    //   this.rangeOptionSelected = "Re Enquire Date ";
    //   this.selectedDate = this.rangeOptionSelected + this.datepipe.transform(this.selectedDateRange.start, 'MMM dd, yyy') + " - " + this.datepipe.transform(this.selectedDateRange.end, 'MMM dd, yyy')

    // }
  }

  rangeChanged(selectedDate: Date) {
    const selection = this.selectionModel.selection,
      newSelection = this.selectionStrategy.selectionFinished(
        selectedDate,
        selection
      );

    this.selectionModel.updateSelection(newSelection, this);
    this.selectedDateRange = new DateRange<Date>(
      newSelection.start,
      newSelection.end
    );
  }

  // Selects a preset date range.
  selectPreset(presetDateRange: { start: Date; end: Date }) {
    this.selectedDateRange = new DateRange<Date>(
      presetDateRange.start,
      presetDateRange.end
    );

    // Jump into month of presetDateRange.start
    if (presetDateRange.start && this.calendar)
      this.calendar._goToDateInView(presetDateRange.start, 'month');

    this.selectedDate = this.rangeOptionSelected + this.datepipe.transform(this.selectedDateRange.start, 'MMM dd, yyy') + " - " + this.datepipe.transform(this.selectedDateRange.end, 'MMM dd, yyy')

  }
  filterApplyClick() {
    this.filterData(this.leadSourceFilter, this.statusFilter, this.mediumFilter, this.sourceFilter, this.courseFilter, this.followUpRequiredFilter, this.stateFilter, this.cityFilter, this.counsellorFilter, this.fromDateFilter, this.toDateFilter)

    // this.subTypeStatus.forEach(e => {
    //   if (e.value > 0) {
    //     if (e.name == "Lead Source") {
    //       document.getElementsByName("leadSourseDisp").forEach((e) => {
    //         const checkbox = e as HTMLInputElement | null;
    //         //if (checkbox != null)
    //         // if (checkbox.checked)
    //         //   alert(checkbox.value + "  == " + checkbox.checked)
    //       })
    //     }
    //     if (e.name == "Status") {
    //       document.getElementsByName("leadStatusDisp").forEach((e) => {
    //         const checkbox = e as HTMLInputElement | null;
    //         //if (checkbox != null)
    //         // if (checkbox.checked)
    //         //   alert(checkbox.value + "  == " + checkbox.checked)
    //       })
    //     }
    //     if (e.name == "Source Type") {
    //       document.getElementsByName("sourceType").forEach((e) => {
    //         const checkbox = e as HTMLInputElement | null;
    //         // if (checkbox != null)
    //         // if (checkbox.checked)
    //         //   alert(checkbox.value + "  == " + checkbox.checked)
    //       })
    //     }
    //     if (e.name == "Course") {
    //       document.getElementsByName("leadCourseDisp").forEach((e) => {
    //         const checkbox = e as HTMLInputElement | null;
    //         //if (checkbox != null)
    //         // if (checkbox.checked)
    //         //   alert(checkbox.value + "  == " + checkbox.checked)
    //       })
    //     }
    //     if (e.name == "Lead Category") {
    //       document.getElementsByName("followUpRequiredDisp").forEach((e) => {
    //         const checkbox = e as HTMLInputElement | null;
    //         //if (checkbox != null)
    //         // if (checkbox.checked)
    //         //   alert(checkbox.value + "  == " + checkbox.checked)
    //       })
    //     }
    //     if (e.name == "State") {
    //       document.getElementsByName("stateDisp").forEach((e) => {
    //         const checkbox = e as HTMLInputElement | null;
    //         //if (checkbox != null)
    //         // if (checkbox.checked)
    //         //   alert(checkbox.value + "  == " + checkbox.checked)
    //       })
    //     }
    //     if (e.name == "City") {
    //       document.getElementsByName("cityDisp").forEach((e) => {
    //         const checkbox = e as HTMLInputElement | null;
    //         //if (checkbox != null)
    //         // if (checkbox.checked)
    //         //   alert(checkbox.value + "  == " + checkbox.checked)
    //       })
    //     }

    //   }

    // })

  }

  filterResetClick() {

  }



  onDropDownClose() {
    console.log('dropdown closed');
  }
  onItemDeSelect(item: any) {
    console.log('onItem DeSelect', item);
    //console.log('form model', this.myForm.get('city').value);
  }
  onItemSelect(item: any) {
    console.log('onItemSelect', item);
  }

  onSelectAll(items: any) {
    console.log('onSelectAll', items);
  }
  onDeSelectAll(items: any) {
    console.log('onDeSelectAll', items);
  }
  toggleCloseDropdownSelection() {
    this.closeDropdownSelection = !this.closeDropdownSelection;
    this.dropdownSettings = Object.assign({}, this.dropdownSettings, { closeDropDownOnSelection: this.closeDropdownSelection });
  }








  getActiveCounsellors() {
    this.userService.getUsersActive()
      .subscribe(
        resp => {
          if (this.localStorage.get("userType") == "Admin")
            this.userIdAndName = resp;
          else
            this.userIdAndName = resp.filter((data) => data.userName == this.localStorage.get("userName"))
        },
        (error: HttpErrorResponse) => {
        });
  }

  openFilterForm() {
    this.filterFormCondition = true
  }

  closeFilterForm() {
    this.filterFormCondition = false
  }
  openCounsellorForm() {

    this.counsellorCondition = true
  }
  closeCounsellorForm() {
    this.counsellorCondition = false
  }
  openRangeForm() {
    this.dateRangeCondition = true
  }

  closeRangeForm() {
    this.dateRangeCondition = false
  }
  getPosition(event) {
    let offsetLeft = 0;
    let offsetTop = 0;

    let el = event.srcElement;

    while (el) {
      offsetLeft += el.offsetLeft;
      offsetTop += el.offsetTop;
      el = el.parentElement;
    }
    this.top = offsetTop;
    this.left = offsetLeft
  }

  stopPropagation(event) {
    event.stopPropagation();
  }
  searchTable() {
    var term, table;
    term = document.getElementById("myInput") as unknown as HTMLInputElement
    // get term to search
    term = term.value.toLowerCase();
    table = document.getElementById("myTable") as HTMLTableElement
    // get table rows, handle as array.
    table = Array.from(table.rows);

    // filter non-matching rows; these are set to display='none'
    table.filter(function (el) {
      return el.textContent.toLowerCase().indexOf(term) ==
        -1
    }).map(x => x.style.display = "none");

    // filter matching rows; these are set to display =''
    table.filter(function (el) {
      return el.textContent.toLowerCase().indexOf(term) > -1
    }).map(x => x.style.display = "");
  }







  filterData(leadSource: string, status: string, sourceType: string, source: string, course: string, followUpRequired: string, state: string, city: string, counsellor: string, fromdate: string, toDate: string) {

    let AllData = this.dashboarAllData
    if (leadSource != 'All') {
      let ls = leadSource.split(",")
      for (let i = 0; i < ls.length; i++) {
        AllData = AllData.filter((dd) => dd.source === ls[i])
      }
    }

    if (status != 'All') {
      let ls = status.split(",")
      for (let i = 0; i < ls.length; i++) {
        AllData = AllData.filter((dd) => dd.status === ls[i])
      }
    }

    if (sourceType != 'All') {
      let ls = sourceType.split(",")
      for (let i = 0; i < ls.length; i++) {
        AllData = AllData.filter((dd) => dd.sourceType === ls[i])
      }
    }

    if (course != 'All') {
      let ls = course.split(",")
      for (let i = 0; i < ls.length; i++) {
        AllData = AllData.filter((dd) => dd.courseName === ls[i])
      }
    }
    if (followUpRequired != 'All') {
      let ls = followUpRequired.split(",")
      for (let i = 0; i < ls.length; i++) {
        AllData = AllData.filter((dd) => dd.followUpRequired === ls[i])
      }
    }
    if (state != 'All') {
      let ls = state.split(",")
      for (let i = 0; i < ls.length; i++) {
        AllData = AllData.filter((dd) => dd.state === ls[i])
      }
    }
    if (city != 'All') {
      let ls = city.split(",")
      for (let i = 0; i < ls.length; i++) {
        AllData = AllData.filter((dd) => dd.city === ls[i])
      }
    }
    if (this.rangeOptionSelected.includes("Creation Date")) {
      AllData = AllData.filter((dd) => moment(dd.onDate).format("YYYY-MM-DD") >= moment(dd.onDate).format("YYYY-MM-DD")  && moment(fromdate).format("YYYY-MM-DD")  <= moment(toDate).format("YYYY-MM-DD") )
    }
    this.totalApplications = 0

    let data = {
      type: "funnel",
      indexLabel: "{name}: {y}",
      valueRepresents: "area",
      dataPoints: [
        { y: 0, name: "All" }
      ]
    }
    //Status Wise Total Applications including rejected

    data.dataPoints = [];
    this.totalLeadFunnelByStatus.data = []
    let statusMatchedData = ([...new Set(AllData.map(item => item.status))])
    if (statusMatchedData.length > 0) {
      statusMatchedData.forEach(e => {
        let countData = ([...new Set(AllData.filter((data) => data.status == e).map(item => item.refNo))]).length
        data.dataPoints.push({ y: countData, name: e })
      })

    }
    this.totalLeadFunnelByStatus.data.push(data);
    this.totalLeadFunnelByStatusChart.render();


    this.totalApplications = ([...new Set(AllData.map(item => item.refNo))]).length;

    //////////////////////////////////////////////////////////////////////////////////////////////////
    //Remaining data ecluding rejected
    AllData = AllData.filter((data) => data.status != 'Rejected')
    if (counsellor != 'All') {
      let cns = counsellor.split(",")
      for (let i = 0; i < cns.length; i++) {
        AllData = AllData.filter((data) => data.counsellorName == cns[i])
      }
    }


    //leadOwnerAnalysisData

    let couns = ([...new Set(AllData.map(item => item.counsellorName))])
    let stat = ([...new Set(AllData.map(item => item.status))])
    this.leadOwnerAnalysisData = []
    couns.forEach(e => {
      stat.forEach(f => {
        let dum = ([...new Set(AllData.filter((data) => data.counsellorName == e && data.status == f).map(item => item.refNo))]).length
        let leadcnt = new UserAndLeadWiseLeads()
        leadcnt.count = dum
        leadcnt.userName = e
        leadcnt.status = f
        this.leadOwnerAnalysisData.push(leadcnt)
      })
    })


    this.state = ([...new Set(AllData.map(item => item.state))])
    this.city = ([...new Set(AllData.map(item => item.city))])

    this.totalOnlineApplication = ([...new Set(AllData.filter((data) => data.sourceType == 'API').map(item => item.refNo))]).length
    this.totalOfflineApplication = ([...new Set(AllData.filter((data) => data.sourceType != 'API').map(item => item.refNo))]).length
    this.totalRejectedApllication = this.totalApplications - this.totalOnlineApplication - this.totalOfflineApplication

    this.newLeadsForToday = ([...new Set(AllData.filter((data) => data.followUpDate == moment(new Date).format('YYYY-MM-DD')).map(item => item.refNo))]).length

    this.totalVerifiedLeads = ([...new Set(AllData.map(item => item.refNo))]).length;


    this.call = AllData.filter((data) => data.action == 'Call').length
    this.whatsApp = AllData.filter((data) => data.action == 'WhatsApp').length
    this.smsSent = AllData.filter((data) => data.action == 'SMS').length
    this.eMailSent = AllData.filter((data) => data.action == 'E-Mail').length



    //Status Wise Total Leads
    data.dataPoints = [];
    this.statusWiseTotalLeads.data = []
    statusMatchedData = ([...new Set(AllData.map(item => item.status))])
    if (statusMatchedData.length > 0) {
      statusMatchedData.forEach(e => {
        if (e == 'Rejected') {
          return;
        }
        let countData = ([...new Set(AllData.filter((data) => data.status == e).map(item => item.refNo))]).length
        data.dataPoints.push({ y: countData, name: e })
      })
    }
    this.statusWiseTotalLeads.data.push(data);
    this.statusWiseTotalLeadsChat.render();
    //Top Performing Lead Channel
    let data3 = {
      type: "doughnut",
      indexLabel: "{name}: {y}",
      innerRadius: "90%",
      yValueFormatString: "#,##0.00'%'",
      dataPoints: [
        { y: 0, name: "All" }
      ]
    }
    data3.dataPoints = [];
    this.topPerformingLeadChannel.data = []
    statusMatchedData = ([...new Set(AllData.map(item => item.channelName))])
    if (statusMatchedData.length > 0) {

      statusMatchedData.forEach(e => {

        let countData = ([...new Set(AllData.filter((data) => data.channelName == e).map(item => item.refNo))]).length
        data3.dataPoints.push({ y: countData, name: e })
      })
    }
    this.topPerformingLeadChannel.data.push(data3);
    this.topPerformingLeadChannelChart.render();
    //Lead Count By Source
    let data1 = {
      type: "column",
      showInLegend: false,
      dataPoints: [
        { label: "Initiated", y: 13 }
      ]
    }
    data1.dataPoints = [];
    this.leadCountBySource.data = []
    statusMatchedData = ([...new Set(AllData.map(item => item.source))])
    if (statusMatchedData.length > 0) {

      statusMatchedData.forEach(e => {
        let countData = ([...new Set(AllData.filter((data) => data.source == e).map(item => item.refNo))]).length
        data1.dataPoints.push({ label: e.toString(), y: countData })
      })
    }
    this.leadCountBySource.data.push(data1);
    this.chartLeadCountBySourceChart.render();
    //Top Reason For Closed Leads
    let data4 = {
      type: "bar",

      showInLegend: false,
      dataPoints: [
        { label: "Not Folloups", y: 262 },
        { label: "No Appropriate Cours", y: 211 }

      ]
    }
    data4.dataPoints = [];
    this.topReasonsForClosedLeads.data = []
    statusMatchedData = ([...new Set(AllData.map(item => item.followUpRequired))])
    if (statusMatchedData.length > 0) {

      statusMatchedData.forEach(e => {
        let countData = ([...new Set(AllData.filter((data) => data.followUpRequired == e).map(item => item.refNo))]).length
        data4.dataPoints.push({ label: e, y: countData })
      })
    }
    this.topReasonsForClosedLeads.data.push(data4);
    this.topReasonsForClosedLeadsChart.render();
    //Lead Count By Counsellor
    data1 = {
      type: "column",
      showInLegend: false,
      dataPoints: [
        { label: "Initiated", y: 13 }
      ]
    }
    data1.dataPoints = [];
    this.leadCountByCounsellor.data = []
    statusMatchedData = ([...new Set(AllData.map(item => item.counsellorName))])
    if (statusMatchedData.length > 0) {

      statusMatchedData.forEach(e => {
        let countData = ([...new Set(AllData.filter((data) => data.counsellorName == e).map(item => item.refNo))]).length
        data1.dataPoints.push({ label: e, y: countData })
      })

    }
    this.leadCountByCounsellor.data.push(data1);
    this.leadCountByCounsellorChart.render();


    //Filter leadOwner Data
    let heading: string[] = [];
    let left: string[] = [];
    this.datasAna = []
    this.counsellors = 0
    let resp = this.leadOwnerAnalysisData

    if (resp.length) {
      heading.push('Lead Owner')
      heading.push('Total')
      resp.forEach(e => {
        let found = false
        for (let i = 0; i < heading.length; i++) {
          if (heading[i] == e.status)
            found = true
        }
        if (!found) {
          heading.push(e.status)
        }

        found = false
        for (let i = 0; i < left.length; i++) {
          if (left[i] == e.userName)
            found = true
        }
        if (!found)
          left.push(e.userName)
      })
      this.datasAna.push(heading)
      this.counsellors = left.length
      for (let i = 0; i < left.length; i++) {
        let temp: string[] = []
        temp.push(left[i])
        temp.push('0')

        let cnt = 0;
        for (let j = 2; j < heading.length; j++) {
          let found = false;

          resp.forEach(e => {
            if (left[i] == e.userName) {
              if (heading[j] == e.status) {
                temp.push(e.count.toString())
                found = true
                cnt += e.count;
              }
            }
          });
          if (!found)
            temp.push('0')
        }
        temp[1] = cnt.toString()
        this.datasAna.push(temp);
      }
      console.log(this.datasAna)

    }

  }


  dateFilterResetClick() {
    this.fromDateFilter = 'All'
    this.toDateFilter = 'All'
    this.filterData(this.leadSourceFilter, this.statusFilter, this.mediumFilter, this.sourceFilter, this.courseFilter, this.followUpRequiredFilter, this.stateFilter, this.cityFilter, this.counsellorFilter, this.fromDateFilter, this.toDateFilter)

  }
  dateFilterApplyClick() {

    this.fromDateFilter = moment(this.selectedDateRange.start).format("DD-MM-YYYY")
    this.toDateFilter = moment(this.selectedDateRange.end).format("DD-MM-YYYY")
    this.filterData(this.leadSourceFilter, this.statusFilter, this.mediumFilter, this.sourceFilter, this.courseFilter, this.followUpRequiredFilter, this.stateFilter, this.cityFilter, this.counsellorFilter, this.fromDateFilter, this.toDateFilter)

  }
  counsellorfilterResetClick() {

    if (this.localStorage.get("userType") == 'Admin')
      this.counsellorFilter = 'All'
    else
      this.counsellorFilter = this.localStorage.get("userName")
    this.filterData(this.leadSourceFilter, this.statusFilter, this.mediumFilter, this.sourceFilter, this.courseFilter, this.followUpRequiredFilter, this.stateFilter, this.cityFilter, this.counsellorFilter, this.fromDateFilter, this.toDateFilter)
  }
  counsellorFilterApplyClick() {
    let css = ""
    document.getElementsByName("counsellor").forEach((e) => {
      const checkbox = e as HTMLInputElement | null;
      if (checkbox != null)
        if (checkbox.checked) {
          if (css.length == 0)
            css = checkbox.value
          else
            css = css + "," + checkbox.value
        }
    })
    if (css.length == 0) {
      if (this.localStorage.get("userType") == 'Admin')
        this.counsellorFilter = 'All'
      else
        this.counsellorFilter = this.localStorage.get("userName")
    }
    else {
      this.counsellorFilter = css
    }
    this.filterData(this.leadSourceFilter, this.statusFilter, this.mediumFilter, this.sourceFilter, this.courseFilter, this.followUpRequiredFilter, this.stateFilter, this.cityFilter, this.counsellorFilter, this.fromDateFilter, this.toDateFilter)

  }
}
