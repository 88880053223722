<div class="container-fluid" id="container-wrapper" *ngIf="userType=='Admin'">
    <div class="d-sm-flex align-items-center justify-content-between mb-4" style="border-bottom:2px solid grey;">
        <h1 class="h3 mb-0 text-gray-800">Lead Sub-Status</h1>
    </div>
    <div class="row" id="dataArea" #dataArea style="margin-top: 10px!important;overflow-y: scroll !important;overflow-x: hidden;height: 570px;">
        <div class="div-viewport">
            <div [ngClass]="sidePanelMode == true ? 'grid9' : 'grid12'">
                <table class="table table-sm table-hover " style="padding-right: 20px!important;">
                    <tr style="background-color: blue;color: white;">
                        <th>#</th>
                        <th>Lead Status</th>
                        <th>Sub Lead Status</th>
                        <th>Color Scheme</th>
                        <th>Followup</th>

                        <th></th>
                    </tr>
                    <tbody id="myTable">
                        <tr *ngFor="let item of subleadStatus | async;let counter=index;">
                            <td>{{counter+1}}</td>
                            <td>{{item.statusName}}</td>
                            <td>{{item.subStatusName}}</td>
                            <td>{{item.colorScheme}}</td>
                            <td>{{item.followupRequired}}</td>
                            <th style="max-width: 20px!important;">
                                <li class="nav-item dropdown no-arrow mx-1" style="right: 12px;">
                                    <a class="nav-link" href="#" id="alertsDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-right shadow " aria-labelledby="alertsDropdown">
                                        <a class="dropdown-item d-flex align-items-center" (click)="sidePanel(item.refNo,true)">
                                            <div class="mr-3">
                                                <div class="icon-circle bg-success">
                                                    <i class="fas fa-edit text-white"></i>

                                                </div>
                                            </div>
                                            <div>
                                                Edit Lead Sub-Status
                                            </div>
                                        </a>
                                        <a class="dropdown-item d-flex align-items-center" (click)="deleteClick(item.refNo)">
                                            <div class="mr-3">
                                                <div class="icon-circle bg-warning">
                                                    <i class="fas fa-male text-white"></i>
                                                </div>
                                            </div>
                                            <div>
                                                Delete Lead SUb-Status
                                            </div>
                                        </a>
                                    </div>
                                </li>
                            </th>
                        </tr>
                        <tr>
                            <th colspan="4" *ngIf="(subleadStatus | async)?.length==0">No data found !</th>
                        </tr>
                    </tbody>
                </table>
            </div>


            <div [ngClass]="sidePanelMode ==true ? 'grid3' : 'grid0'">
                <h3 style="text-align: center;background-color: blue;color: white;margin-top: 0px;">{{boxTitle}}<span style="float: inline-end;padding-bottom: 10px!important;cursor: pointer;"><a (click)="close_clicked();"><i class="fas fa-times"></i></a></span></h3>
                <table>
                    <tr>
                        <th style="padding-left: 10px !important;">Lead Sub Status Name : </th>
                        <th>
                            <input type="text" [(ngModel)]="TblLeadSubStatus.subStatusName" name="subStatusName" />
                        </th>
                    </tr>
                    <tr>
                        <th style="padding-left: 10px !important;">Lead Status Name : </th>
                        <th>
                            <select [(ngModel)]="TblLeadSubStatus.statusRefNo" name="statusRefNo">
                <option *ngFor="let stat of leadStatus| async" [ngValue]="stat.refNo">{{stat.statusName}}</option>
               </select>
                        </th>
                    </tr>
                    <tr>
                        <th style="padding-left: 10px !important;">Color Scheme : </th>
                        <th>
                            <select [(ngModel)]="TblLeadSubStatus.colorScheme" name="colorScheme">
                <option value="0">Select</option>
                <option value="Red">Red</option>
                <option value="Blue">Blue</option>
                <option value="Black">Black</option>
                <option value="Yellow">Yellow</option>

              </select>
                        </th>
                    </tr>
                    <tr>
                        <th style="padding-left: 10px !important;">Followup Required: </th>
                        <th>
                            <select [(ngModel)]="TblLeadSubStatus.followupRequired" name="followupRequired">
                    <option value="0">Select</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                </select>
                        </th>
                    </tr>
                </table>
                <button class="btn btn-sm btn-primary" (click)="create_edit();" style="float: inline-end;">
                <i class="fas fa-plus"></i> Save
              </button>
            </div>
        </div>
    </div>
    <button class="float"><i class="fas fa-plus" (click)="sidePanel(0,false)"></i></button>
</div>